<mat-sidenav-container class="sidenav-container">

    <mat-sidenav #drawer class="sidenav" fixedInViewport="true" [attr.role]="isHandset ? 'dialog' : 'navigation'"
        [mode]="(isHandset | async)!.matches ? 'over' : 'push'" [opened]="false">

        <mat-toolbar color="primary">
            <img src="assets/images/new-logo-2.png" style="height: 45px;" alt="{{title | translate}}">
        </mat-toolbar>
        
        <app-sidebar></app-sidebar>


    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary" class="fix-nav">
            <button *ngIf="!isUser" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <div class="nav-brand">
                <!-- {{title}} -->
                <a href="/#/reseller/dashboard" *ngIf="isReseller">
                    <img src="assets/images/new-logo-2.png" style="height: 45px;"
                        class="circle z-depth-2 responsive-img activator" alt="{{title | translate}}">
                </a>
                <a href="/#/dashboard" *ngIf="!isReseller">
                    <img src="assets/images/new-logo-2.png" style="height: 45px;"
                        class="circle z-depth-2 responsive-img activator" alt="{{title | translate}}">
                </a>
                <!-- <span style="letter-spacing: 2px;" class="hidden-sm">{{title}}</span> -->
            </div>
            <span class="nav-spacer"></span>
            <!--
            <mat-icon class="nav-icon">person</mat-icon>
            <span class="nav-spacer"></span>
            <mat-icon class="nav-icon">notifications</mat-icon>
            <mat-icon class="nav-icon">apps</mat-icon>
            <mat-icon class="nav-icon">fullscreen</mat-icon>
            <mat-icon class="nav-icon">flag</mat-icon>
            <mat-icon class="nav-icon">search</mat-icon>
            <span class="nav-spacer"></span>-->
            <span class="user-menu" *ngIf="!requestService.isNBCA">
                <mat-icon *ngIf="isReseller || isSuperAdmin" class="nav-icon" (click)="onCopy()"
                    matTooltip="Share this sign up link with your clients so that you start getting commission for their subscriptions.">share</mat-icon>
                <div class="preview">
                    <img [src]="currentImgSrc" alt="" (click)="toProfile()">
                </div>
                <span>
                    <div class="menu-preview" mat-button [matMenuTriggerFor]="menu" #menuTrigger>
                        <img [src]="currentImgSrc" alt="">
                    </div>
                    <span mat-button class="menu-toggle" [matMenuTriggerFor]="menu" #menuTrigger>My Account</span>
                    <mat-menu #menu="matMenu" class="menu-items">
                        <button mat-menu-item (click)="toProfile()">Profile</button>
                        <button mat-menu-item (click)="toStripeInvoices()" *ngIf="!requestService.isNBCA">Payment History</button>
                        <button mat-menu-item (click)="toSupport()">Support</button>
                    </mat-menu>
                </span>

                <a [href]="'/#/reseller/dashboard'" *ngIf="isReseller || isSuperAdmin" style="line-height: initial;">
                    <mat-icon class="nav-icon home">house</mat-icon>
                </a>
                <a [href]="'/#/dashboard'" *ngIf="!isReseller && ! isSuperAdmin" style="line-height: initial;">
                    <mat-icon class="nav-icon home">house</mat-icon>
                </a>

                <a class="topnav-icon" href="#" role="button" (click)="logout()">
                    <mat-icon class="nav-icon">exit_to_app</mat-icon>
                </a>
            </span>
            <span class="user-menu" *ngIf="requestService.isNBCA">
                <a class="topnav-icon" href="#" role="button" (click)="logout()">
                    <mat-icon class="nav-icon">exit_to_app</mat-icon>
                </a>
            </span>

        </mat-toolbar>
        <ng-content></ng-content>

    </mat-sidenav-content>
</mat-sidenav-container>