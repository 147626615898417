<mat-form-field class="example-full-width">
  <input #inputName [value]="dataText" matInput type="text" placeholder="{{placeholder}}" aria-label="Number" [matAutocomplete]="auto" (input)="termSearch($event.target.value)" class="truncateInput" (focusout)="onBlurMethod($event.target.value)" title="{{dataText}}">
  <mat-autocomplete class="autocomplete" #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="setAttribute($event, $event.option.value)" >
      <mat-option *ngIf="addAll" [value]="{_id: '-1', text: 'Select All'}" title="{{'Select All' | translate}}">{{'Select All' | translate}}
      </mat-option>
      <mat-option *ngFor="let option of filteredData | async" [value]="option" title="{{option.text | translate}}">{{option.text | translate}} <i *ngIf="canDelete" class="material-icons deleteItem" (click)="deleteItem($event, option)">{{'Close' | translate}}</i>
      </mat-option>
    </mat-autocomplete>
    <mat-icon *ngIf="hasEditBtn && value" (click)="sendSignal($event, 'edit')" class="editIcon" matTooltip="{{'Edit' | translate}} {{dataTypeDisplay | translate}}" matTooltipClass="tooltip-red">edit_box</mat-icon>
    <mat-icon *ngIf="hasAddBtn" (click)="sendSignal($event, 'add')" class="addIcon" matTooltip="{{'Add' | translate}} {{dataTypeDisplay}}" matTooltipClass="tooltip-red">add_box</mat-icon>
</mat-form-field>
