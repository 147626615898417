<div class='ili-panel start_wrapper_panel'>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        <mat-form-field class="example-full-width">
          <input matInput [(ngModel)]="block.data.text" placeholder="Submit Text" (change)="onChange($event)">
          <mat-hint align="start"><strong>End Wrapping Form Widgets in one form</strong> </mat-hint>
          <!-- <mat-hint align="end">End Wrapping Form Widgets in one form</mat-hint> -->
        </mat-form-field>
      </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        <mat-checkbox value="true" [checked]="block.data.submitConfirmation" [(ngModel)]="block.data.submitConfirmation" (change)="onChange($event)" title="Disable confirmation popup after submit">Disable pop-up</mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox value="true" [checked]="block.data.hideSubmit" [(ngModel)]="block.data.hideSubmit" (change)="onChange($event)" title="This checkbox will hide the submit button from the Form, and thus the app user will not be able to submit the Form.">Hide Submit</mat-checkbox>
      </div>
  </div>
</div>
