import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { LayoutComponents, DefaultSettings } from '../';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TileSelectDialogComponent } from '../../../../shared/components/tile-select-dialog/tile-select-dialog.component';
import { BillboardSelectDialogComponent } from '../../../../shared/components/billboard-select-dialog/billboard-select-dialog.component';
import { ConfirmSelectEntityDialogComponent } from '../../../../shared/components/modals';
import { ModalAdvancedGalleryDialogComponent } from '../../../../shared/components/custom-advanced-gallery-dialog/custom-advanced-gallery-dialog.component';
import { PictureDialogComponent } from '../picture-dialog/picture-dialog.component';
import { roundDecimal } from '../../../../shared/helpers';
import { ModalGalleryDialogComponent } from '../../../../shared/components/custom-gallery-dialog/custom-gallery-dialog.component';
import { MatDialog } from '@angular/material/dialog';
declare var CKEDITOR;

@Component({
    selector: 'app-screen-layout',
    templateUrl: './screen-layout.component.html',
    styleUrls: ['./screen-layout.component.scss']
})
export class ScreenLayoutComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public defaultSetting = DefaultSettings.defaultSetting;
  public enableEditor: boolean = false;
  public enableGrid: boolean = false;
  public enableAudienceGrid: boolean = false;
  public dragPolyEntered: boolean = false;
  public weareresizing: boolean = false;

  public componentsType: any = DefaultSettings.componentsType;
  public cameraComponents: string[] = DefaultSettings.cameraComponents;
  public tileComponents: string[] = DefaultSettings.tileComponents;
  public componentsName: any = LayoutComponents.componentsName;
  public componentsNameKeys: any = LayoutComponents.componentsNameKeys;
  public videoComponentTypes: any[] = DefaultSettings.videoComponentTypes;


  public selectedColumn = undefined;
  public selectedComponent = undefined;
  public liveXY: any = {name : '', x: 0, y: 0, c: 0, m: 0, w: 0, h: 0, z: 1, bgcolor: ''};

  public _settingObject: any = undefined;

  editorConfig: any = {
      toolbar: [
          { name: 'document', items: ['Source', '-', 'Undo', 'Redo', '-','Bold', 'Italic', 'Underline', 'Strike', '-', 'Link', 'Unlink','TextColor', 'BGColor', '-', 'Styles', 'Format', 'Font', 'FontSize'] }
      ],
      height: '33vh',
      shiftEnterMode: CKEDITOR.ENTER_DIV,
      enterMode: CKEDITOR.ENTER_DIV,
      removePlugins: 'magicline',
      linkShowAdvancedTab: false,
      linkShowTargetTab: false,
      resize_enabled: false
  };


  @Input()
  set settingObject(settingObject: any) {
    if(settingObject){
      if(this._settingObject){
        this.updateData(settingObject);
        // this._settingObject = {...this._settingObject, ...settingObject};
      }else{
        this._settingObject = settingObject;
      }
    }else{
      this._settingObject = settingObject;
    }
  }
  get settingObject(): any {
      return this._settingObject;
  }

  @Input() type: string = '';
  @Input() data: any = undefined;
  @Input() guestsTotal: number = 0;
  @Output() setSelectComponent = new EventEmitter<any>();
  @Output() setUpdateLayout = new EventEmitter<any>();
  constructor(private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, private translate: TranslateService, public renderer: Renderer2,
  public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, private loaderService: LoaderService) {}

  ngOnInit() {
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  // dragDropped($event, target) {
  //   let position = $event.source.getFreeDragPosition();
  //   console.log('dragDropped $event', $event);
  //   console.log('dragDroppedposition', position);
  // }
  hasComponent(type){
    for(let cmp of this.settingObject['columns'][0]['components']){
      if(cmp.name === type){
        return true
      }
    }
    return false;
  }
  updateData(settingObject){
    // console.log('settingObject', settingObject);
    let idx = 0;
    for(let cmp of settingObject.columns[0]['components']){
      if(this._settingObject.columns[0]['components'][idx]){
        let settingObjectKeys = Object.keys(cmp);
        for(let key of settingObjectKeys){
          if(this._settingObject.columns[0]['components'][idx][key] !== cmp[key]){
            this._settingObject.columns[0]['components'][idx][key] = cmp[key];
          }
        }
      }else{
        this._settingObject.columns[0]['components'].push(cmp);
      }
      idx++;
    }
    if(settingObject.hasOwnProperty('optionSettings')){
      this._settingObject.optionSettings = settingObject.optionSettings;
    }
  }
  dragMoved($event, mainBoundary, target) {
    // console.log('dragMoved. ',$event);
    let position = $event.source.getFreeDragPosition();
    this.liveXY['x'] = roundDecimal((position.x * 100) / mainBoundary.offsetWidth, 2);
    this.liveXY['y'] = roundDecimal((position.y * 100) / mainBoundary.offsetWidth, 2);
    this.liveXY['c'] = roundDecimal(((position.x * 100) / mainBoundary.offsetWidth) + (target.w/2), 2);
    this.liveXY['m'] = roundDecimal(((position.y * 100) / mainBoundary.offsetWidth) + (target.h/2), 2);
    this.setSelectComponent.emit({selectedColumn: this.selectedColumn, selectedComponent: this.selectedComponent, liveXY: this.liveXY});
  }
  applyResizing($event, mainBoundary, target) {
    this.weareresizing = true;
    this.liveXY['w'] = roundDecimal(($event.rectangle.width * 100) / mainBoundary.offsetWidth, 2);
    this.liveXY['h'] = roundDecimal(($event.rectangle.height * 100) / mainBoundary.offsetWidth, 2);
    this.setSelectComponent.emit({selectedColumn: this.selectedColumn, selectedComponent: this.selectedComponent, liveXY: this.liveXY});
  }
  dragStarted($event, index, subIndex) {

    this.dragPolyEntered = true;
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject['columns'][index]['components'][subIndex]));
    this.selectedColumn = index;
    this.selectedComponent = subIndex;
    this.liveXY = {name : currentSettings['name'], x: Number(currentSettings['x']), y: Number(currentSettings['y']), c: roundDecimal(Number(currentSettings['x']) + (Number(currentSettings['w'])/2), 2), m: roundDecimal(Number(currentSettings['y']) + (Number(currentSettings['h'])/2), 2), w: Number(currentSettings['w']), h: Number(currentSettings['h']), z: Number(currentSettings['z']), bgcolor: currentSettings['bgcolor']};
    this.setSelectComponent.emit({selectedColumn: index, selectedComponent: subIndex, liveXY: this.liveXY});
  }
  dragEntered($event, index, subIndex) {
    this.selectedColumn = index;
    this.selectedComponent = subIndex;
  }
  selectComponent($event, index, subIndex) {
    if(subIndex !== this.selectedComponent){
      this.enableEditor = false;
      this.enableGrid = false;
      this.enableAudienceGrid = false;
    }
    if(subIndex !== undefined){
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject['columns'][index]['components'][subIndex]));
      if(!(currentSettings['name'] === 'empty-click' && this.enableEditor)){
        if($event){
          $event.preventDefault();
          $event.stopPropagation();
        }
        this.selectedColumn = index;
        this.selectedComponent = subIndex;
        this.liveXY = {name : currentSettings['name'], x: Number(currentSettings['x']), y: Number(currentSettings['y']), c: roundDecimal(Number(currentSettings['x']) + (Number(currentSettings['w'])/2), 2), m: roundDecimal(Number(currentSettings['y']) + (Number(currentSettings['h'])/2), 2), w: Number(currentSettings['w']), h: Number(currentSettings['h']), z: Number(currentSettings['z']), bgcolor: currentSettings['bgcolor']};
        this.setSelectComponent.emit({selectedColumn: index, selectedComponent: subIndex, liveXY: this.liveXY});
      }
    }else{
      this.selectedColumn = index;
      this.selectedComponent = subIndex;
      this.liveXY = {name : '', x: 0, y: 0, c: 0, m: 0, w: 0, h: 0, z: 1, bgcolor: ''};
      this.setSelectComponent.emit({selectedColumn: index, selectedComponent: subIndex, liveXY: this.liveXY});
    }
  }
  dragEnd($event, mainBoundary, target, index, subIndex) {
      let position = $event.source.getFreeDragPosition();
      target['x'] = roundDecimal((position.x * 100) / mainBoundary.offsetWidth, 2);
      target['y'] = roundDecimal((position.y * 100) / mainBoundary.offsetWidth, 2);
      // this.selectedColumn = undefined;
      // this.selectedComponent = undefined;
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
      currentSettings['columns'][index]['components'][subIndex]['x'] = Number(target['x']);
      currentSettings['columns'][index]['components'][subIndex]['y'] = Number(target['y']);
      this.setUpdateLayout.emit({currentSettings: currentSettings});
  }
  onResizeEnd($event, mainBoundary, target, index, subIndex) {
    let oldW = target.w;
    let oldH = target.h;
    let newW = roundDecimal(($event.rectangle.width * 100) / mainBoundary.offsetWidth, 2);
    let newH = roundDecimal(($event.rectangle.height  * 100) / mainBoundary.offsetWidth, 2);
    if(this.weareresizing){
      this.weareresizing = false;
      target.w = newW;
      target.h = newH;
      this.liveXY['w'] = Number(target.w);
      this.liveXY['h'] = Number(target.h);
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
      currentSettings['columns'][index]['components'][subIndex]['w'] = Number(target['w']);
      currentSettings['columns'][index]['components'][subIndex]['h'] = Number(target['h']);
      this.setUpdateLayout.emit({currentSettings: currentSettings});
    }
  }
  getHeight(h, w, ratioSize) {
    if(ratioSize){
      return roundDecimal((Number(w) * ratioSize.h) / ratioSize.w, 2) + 'vw';
    }else{
      return h + 'vw';
    }
  }
  getZ(z, index, subIndex) {
    if(this.selectedColumn === index && this.selectedComponent === subIndex){
      return 800;
    }else{
      return z;
    }
  }
  getX(x, mainBoundary) {
    return Math.round((x * mainBoundary.offsetWidth)/100);
  }
  getY(y, mainBoundary) {
    return Math.round((y * mainBoundary.offsetWidth)/100);
  }
  getGridBorder(total, idx) {
    return total%idx ==0 ? '0px' : '5px';
  }
  getTileLink(id) {
    return this.requestService.getTileLink(undefined, id, true);
  }
  changeRowAllowAllSpeak($event, value, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex]['enableAllowAllSpeak'] = value;
    this.setUpdateLayout.emit({currentSettings: currentSettings});
  }
  removeCmp($event, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.settingObject['columns'][index]['components'].splice(subIndex, 1);
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    // currentSettings['columns'][index]['components'].splice(subIndex, 1);
    this.setUpdateLayout.emit({currentSettings: currentSettings});
  }

  editTile($event, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    const dialogRef = this.dialog.open(TileSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Tile'),
        data: {},
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['title'];
        } else {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = '';
        }
        this.setUpdateLayout.emit({currentSettings: currentSettings});
      }
    });
  }
  editBillboard($event, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(BillboardSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Billboard'),
        data: currentSettings['columns'][index]['components'][subIndex],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
          if (result.hasOwnProperty('text')) {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['text'];
          } else {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['title'];
          }
        } else {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = '';
        }
        this.setUpdateLayout.emit({currentSettings: currentSettings});
      }
    });
  }
  editEmptySetting($event, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(PictureDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        hasShape: true,
        title: this.translate.instant('Edit Clickable Doorways Settings'),
        data: { type: currentSettings['columns'][index]['components'][subIndex]['metadata']['type'], link: currentSettings['columns'][index]['components'][subIndex]['metadata']['link'], linkDisplay: currentSettings['columns'][index]['components'][subIndex]['metadata']['linkDisplay'], title: currentSettings['columns'][index]['components'][subIndex]['title'], shape: currentSettings['columns'][index]['components'][subIndex]['shape'] },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['type'] = result.type;
          currentSettings['columns'][index]['components'][subIndex]['metadata']['link'] = result.link;
          currentSettings['columns'][index]['components'][subIndex]['metadata']['linkDisplay'] = result.linkDisplay;
          currentSettings['columns'][index]['components'][subIndex]['title'] = result.title;
          if(currentSettings['columns'][index]['components'][subIndex]['shape'] !== result.shape){
            currentSettings['columns'][index]['components'][subIndex]['svg'] = [];
            if(result.shape === 'poly'){
        			currentSettings['columns'][index]['components'][subIndex]['svg'] =  [{x: 1.46, y: 0.99}, {x: 0.63, y: 3.18}, {x: 3.07, y: 4.06}, {x: 4.48, y: 1.98}];
        		}
          }
          currentSettings['columns'][index]['components'][subIndex]['shape'] = result.shape;
        this.setUpdateLayout.emit({currentSettings: currentSettings});
      }
    });
  }
  editPictureSetting($event, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(PictureDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Edit') + ' Picture ' + this.translate.instant('Settings'),
        data: { type: currentSettings['columns'][index]['components'][subIndex]['metadata']['type'], link: currentSettings['columns'][index]['components'][subIndex]['metadata']['link'], linkDisplay: currentSettings['columns'][index]['components'][subIndex]['metadata']['linkDisplay'], title: currentSettings['columns'][index]['components'][subIndex]['title'] },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        currentSettings['columns'][index]['components'][subIndex]['metadata']['type'] = result.type;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['link'] = result.link;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['linkDisplay'] = result.linkDisplay;
        currentSettings['columns'][index]['components'][subIndex]['title'] = result.title;
        this.setUpdateLayout.emit({currentSettings: currentSettings});
      }
    });
  }
  editImage($event, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(ModalAdvancedGalleryDialogComponent, {
      width: '100vw',
      disableClose: false,
      autoFocus: false,
      data: {
        handleBgColor: false,
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Image'),
        data: { bgBackgroundLink: currentSettings['columns'][index]['components'][subIndex]['image'] },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        currentSettings['columns'][index]['components'][subIndex]['image'] = result.bgBackgroundLink;
        this.setUpdateLayout.emit({currentSettings: currentSettings});
      }
    });
  }
  editPictureWallImages($event) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    const dialogRef = this.dialog.open(ModalGalleryDialogComponent, {
      width: '1600px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Edit Picture Images Uploaded'),
        galleryType: 'picturewallart',
        targetId: this.data.data._id,
        noSelection: true,
        data: [],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do nothing
      }
    });
  }

  getSelectedUsers(cameraType) {
    let alreadySelected = [];
    let settingObject = JSON.parse(JSON.stringify(this.settingObject));
    for (let col of settingObject['columns']) {
      for (let comp of col['components']) {
        if (comp.name === cameraType && comp.metadata.hasOwnProperty('id') && comp.metadata.id !== '') {
          alreadySelected.push(comp.metadata.id);
        }
      }
    }
    return alreadySelected;
  }
  /**
  * Edit Guest function
  */
  editGuest($event, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    let alreadySelected = this.getSelectedUsers('guest-camera' );
    let guestsList = [];
    if (this.data.data.hasOwnProperty('users')) {
      for (let dt of this.data.data.users) {
        if (dt.type === 'guest' && (!alreadySelected.includes(dt._id) || currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] === dt._id)) {
          guestsList.push({ _id: dt._id, text: dt.name });
        }
      }
    }

    // console.log('guestsList', guestsList);
    const dialogRef = this.dialog.open(ConfirmSelectEntityDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Guest'),
        data: currentSettings['columns'][index]['components'][subIndex]['metadata']['id'],
        dataList: guestsList,
        placeholder: 'Select ' + this.translate.instant('Guest'),
        cancelbtn: 'Cancel',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
          if (result.hasOwnProperty('text')) {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['text'];
          } else {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['title'];
          }
        } else {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = '';
        }
        this.setUpdateLayout.emit({currentSettings: currentSettings});
      }
    });
  }
  /**
  * Active / deActivate function
  */
  editActive($event, index, subIndex, value) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex]['active'] = value;
    this.setUpdateLayout.emit({currentSettings: currentSettings});
  }
  /**
  * getShapePoint function
  */
  getShapePoint(mainBoundary, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    let points = '';
    if(currentSettings['columns'][index]['components'][subIndex].hasOwnProperty('svg')){
      for(let pnt of currentSettings['columns'][index]['components'][subIndex]['svg']){
        points = points + this.getPolyX(pnt.x, mainBoundary, index, subIndex) + ',' + this.getPolyY(pnt.y, mainBoundary, index, subIndex) + ' ';
      }
    }
    return points;
  }
  /**
  * getCircleR function
  */
  getPositiveValue(x1, x2) {
    let r = 0;
    if(x1 > x2){
      r = x1 - x2;
    }else if (x1 > x2){
      r = x2 - x1;
    }
    return r;
  }
  getPolyX(x, mainBoundary, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    // return Math.round((x * ((currentSettings['columns'][index]['components'][subIndex].w* mainBoundary.offsetWidth)/100))/100);
    return Math.round((x * mainBoundary.offsetWidth)/100);
  }
  getPolyY(y, mainBoundary, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    // return Math.round((y * ((currentSettings['columns'][index]['components'][subIndex].w* mainBoundary.offsetWidth)/100))/100);
    return Math.round((y * mainBoundary.offsetWidth)/100);
  }
  /**
  * editEmptyShape function
  */
  editEmptyShape($event, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.enableEditor = !this.enableEditor;
  }
  /**
  * addPoint function
  */
  addPoint($event, mainBoundary, index, subIndex) {
    if(this.enableEditor){
      if($event){
        $event.preventDefault();
        $event.stopPropagation();
      }
      if(!this.dragPolyEntered ){
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
        if(!currentSettings['columns'][index]['components'][subIndex].hasOwnProperty('svg')){
          currentSettings['columns'][index]['components'][subIndex]['svg'] = [];
        }
        let newX = roundDecimal(($event.offsetX * 100) / mainBoundary.offsetWidth, 2);
        let newY = roundDecimal(($event.offsetY * 100) / mainBoundary.offsetWidth, 2);

        currentSettings['columns'][index]['components'][subIndex]['svg'].push({x: newX, y: newY});
        this.setUpdateLayout.emit({currentSettings: currentSettings});
      }
      this.dragPolyEntered = false;
    }
  }
  /**
  * onCircleClick function
  */
  onCircleClick($event, index, subIndex, circleindex) {
    if(this.enableEditor){
      if($event){
        $event.preventDefault();
        $event.stopPropagation();
      }
      if(this.settingObject['columns'][index]['components'][subIndex].hasOwnProperty('svg')){
        this.settingObject['columns'][index]['components'][subIndex]['svg'].splice(circleindex, 1);
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
        this.setUpdateLayout.emit({currentSettings: currentSettings});
      }
    }
  }
  /**
  * editAttribute function
  */
  editAttribute($event, index, subIndex, id, value) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex][id] = value;
    this.setUpdateLayout.emit({currentSettings: currentSettings});
  }
  /**
  * editNumericAttribute function
  */
  editNumericAttribute($event, index, subIndex, id, value, min, max) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let newVal = Number(value);
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    if(min !== undefined && min > newVal){
      newVal = min;
    }
    if(max !== undefined && max < newVal){
      newVal = max;
    }
    currentSettings['columns'][index]['components'][subIndex][id] = newVal + '';
    this.setUpdateLayout.emit({currentSettings: currentSettings});
    this.changeDetectorRef.detectChanges();
  }
  /**
  * editGridAttribute function
  */
  editGridAttribute($event, index, subIndex, id, value, min, max, gridSizeCouple) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let newVal = Number(value);
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    if(min !== undefined && min > newVal){
      newVal = min;
    }
    if(max !== undefined && max < newVal){
      newVal = max;
    }
    if(newVal * gridSizeCouple > max){
      newVal = 1;
    }
    currentSettings['columns'][index]['components'][subIndex][id] = newVal + '';
    this.setUpdateLayout.emit({currentSettings: currentSettings});
    this.changeDetectorRef.detectChanges();
  }

  /**
  * editEditor function
  */
  editEditor(index, subIndex, id, value) {
    console.log(value);
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex][id] = value;
    console.log(currentSettings['columns'][index]['components'][subIndex]);
    // this.settingObject['columns'][index]['components'][subIndex]['active'] = value;
    this.setUpdateLayout.emit({currentSettings: currentSettings});
  }
  cancelThru($event) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
  }
  numberReturn(length){
    return new Array(length);
  }
  dragCircleSvg($event, index, subIndex, circleindex) {
    //console.log($event);
  }
  leaveCircleSvg($event, index, subIndex, circleindex) {
    //console.log($event);
  }
  grabCircleSvg($event, index, subIndex, circleindex) {
    console.log($event);
  }
  dropCircleSvg($event, index, subIndex, circleindex) {
    console.log($event);
  }
  dragPolyStarted($event, mainBoundary, index, subIndex, circleindex) {
    // console.log('dragPolyStarted', $event);
    // let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    // console.log('dragPolyStarted',currentSettings['columns'][index]['components'][subIndex]['svg'][circleindex]);
    // if(this.enableEditor){
    //   if($event){
    //     $event.preventDefault();
    //     $event.stopPropagation();
    //   }
    //   let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    //   let newX = roundDecimal(($event.offsetX * 100) / mainBoundary.offsetWidth, 2);
    //   let newY = roundDecimal(($event.offsetY * 100) / mainBoundary.offsetWidth, 2);
    //
    //   currentSettings['columns'][index]['components'][subIndex]['svg'][circleindex]['x'] = newX;
    //   currentSettings['columns'][index]['components'][subIndex]['svg'][circleindex]['y'] = newY;
    //   this.setUpdateLayout.emit({currentSettings: currentSettings});
    // }
  }
  dragPolyEnd($event, mainBoundary, target, index, subIndex, circleindex) {
      let position = $event.source.getFreeDragPosition();
      target['svg'][circleindex]['x'] = target['svg'][circleindex]['x'] + roundDecimal((position.x * 100) / mainBoundary.offsetWidth, 2);
      target['svg'][circleindex]['y'] = target['svg'][circleindex]['y'] + roundDecimal((position.y * 100) / mainBoundary.offsetWidth, 2);
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
      currentSettings['columns'][index]['components'][subIndex]['svg'][circleindex]['x'] = Number(target['svg'][circleindex]['x']);
      currentSettings['columns'][index]['components'][subIndex]['svg'][circleindex]['y'] = Number(target['svg'][circleindex]['y']);
      this.setUpdateLayout.emit({currentSettings: currentSettings});
  }
  dragPolyMoved($event, mainBoundary, target, index, subIndex, circleindex) {
    // console.log('dragMoved. ',$event);
    // let position = $event.source.getFreeDragPosition();
    // console.log('dragPolyMoved ',position);
    // this.liveXY['x'] = roundDecimal((position.x * 100) / mainBoundary.offsetWidth, 2);
    // this.liveXY['y'] = roundDecimal((position.y * 100) / mainBoundary.offsetWidth, 2);
    // this.liveXY['c'] = roundDecimal(((position.x * 100) / mainBoundary.offsetWidth) + (target.w/2), 2);
    // this.liveXY['m'] = roundDecimal(((position.y * 100) / mainBoundary.offsetWidth) + (target.h/2), 2);
    // this.setSelectComponent.emit({selectedColumn: this.selectedColumn, selectedComponent: this.selectedComponent, liveXY: this.liveXY});
  }
}
