<form #personForm="ngForm" *ngIf="metaFieldSetting && data" class="layoutDialog">
	<div class="col-xl-12" *ngIf="data.data">
			<div *ngIf="data.modalSetting">
	      <div *ngIf="data.modalSetting.fields">
						<div class="rightmenu" >
							<ng-container *ngIf="selectedColumn !== undefined && selectedComponent !== undefined">
								<mat-card class="rightmenuBox" [hidden]="openedRightMenu !== 'componentSettings'" >
								<mat-card-title class="headIcon">
									<mat-icon class="actions" *ngIf="rightComponentMenu" (click)="rightComponentMenu = !rightComponentMenu">horizontal_rule</mat-icon>
									<mat-icon class="actions" *ngIf="!rightComponentMenu" (click)="rightComponentMenu = !rightComponentMenu">web_asset</mat-icon>
								</mat-card-title>
								<div fxLayout="row" fxLayout.lt-md="column" fxFlex >
									<div fxFlex>
										<div class="rootcomp-toolbar">
											<div class="header-seperator"></div>
											<div [hidden]="!rightComponentMenu">
												<div>
													<!-- <div class="inner button-grid">
														<span class="button">
															<svg width="13" height="15" viewBox="0 0 13 15" class="symbol symbol-toolbarCopy">
																<path fill-rule="evenodd" d="M11.08 12h-6C3.97 12 3 11.1 3 9.99v-8C3 .89 3.97 0 5.08 0h6C12.18 0 13 .89 13 1.99v8c0 1.1-.82 2.01-1.92 2.01zM11 2H5v1h6V2zm0 2H5v1h6V4zm-1 9v2H3.08C1.42 15 0 13.65 0 11.99V4h2v7.99c0 .55.53 1.01 1.08 1.01H10z"></path>
															</svg>
														</span>
														<span class="button">
																<svg width="14" height="15" viewBox="0 0 14 15" class="symbol symbol-toolbarPaste">
																<path fill-rule="evenodd" d="M11.58 15h-5c-1.11 0-2.08-.9-2.08-2.01v-3C4.5 8.89 5.47 8 6.58 8h5c1.1 0 1.92.89 1.92 1.99v3c0 1.11-.82 2.01-1.92 2.01zm-.08-5h-5v1h5v-1zm0 2h-5v1h5v-1zm-8-3.01V14H1.58C.47 14-.5 13.1-.5 11.99v-9C-.5 1.89.48 1 1.58 1h.92V.49c0-.27.3-.49.58-.49h4c.27 0 .42.22.42.49V1h1.08c1.1 0 1.92.89 1.92 1.99V7H5.58C4.47 7 3.5 7.89 3.5 8.99zM6.5 1h-3v1h3V1z"></path>
															</svg>
														</span>
														<span class="button">
															<svg width="13" height="15" viewBox="0 0 13 15" class="symbol symbol-toolbarDuplicate">
																<path fill-rule="evenodd" d="M11 12H5c-1.1 0-2-.9-2-2V2c0-1.1.9-2 2-2h4l4 4v6c0 1.1-.9 2-2 2zm0-7H8V2H5v8h6V5zm-1 8v2H3c-1.65 0-3-1.35-3-3V4h2v8c0 .55.45 1 1 1h7z"></path>
															</svg>
														</span>
														<span class="button">
															<svg width="13" height="15" viewBox="0 0 13 15" class="symbol symbol-toolbarDelete"><path fill-rule="evenodd" d="M12.02 2H8.98V0h-5v2H1.02C.47 2 0 2.44 0 3c0 .55.98.99.98.99v8C.98 13.65 2.36 15 4.02 15h5c1.66 0 2.96-1.35 2.96-3.01v-8c1 0 1.02-.44 1.02-.99 0-.56-.43-1-.98-1zM4.98 12h-1V5h1v7zm4 0h-1V5h1v7zm-4-11h3v1h-3V1z"></path>
															</svg>
														</span>
													</div>
													<div class="seperator"></div>
													<div class="inner button-grid">
														<div class="tool-selector" style="position: relative;">
															<span class="button">
																<svg width="14" height="14" viewBox="0 0 14 14" class="symbol symbol-toolbarMoveForward"><path fill-rule="evenodd" d="M11.58 2.5H8.5V1.49C8.5.39 7.68-.5 6.58-.5h-5C.47-.5-.5.39-.5 1.49v5C-.5 7.6.47 8.5 1.58 8.5h.92v2.99c0 1.11.97 2.01 2.08 2.01h7c1.1 0 1.92-.9 1.92-2.01v-7c0-1.1-.82-1.99-1.92-1.99zm-7 0c-1.11 0-2.08.89-2.08 1.99V6.5h-1v-5h5v1H4.58z"></path>
																</svg>
															</span>
															<div class="small-indicator"></div>
														</div> -->
														<!-- <div class="tool-selector" style="position: relative;">
															<span class="button">
																<svg width="15" height="15" viewBox="0 0 15 15" class="symbol symbol-toolbarAlignRight"><path fill-rule="evenodd" d="M13 15V0h2v15h-2zm-3.01-1H2.15C1 14 0 13.19 0 12.19V9.81C0 8.81 1 8 2.15 8h7.84C11.14 8 12 8.81 12 9.81v2.38c0 1-.86 1.81-2.01 1.81zM10 7H5c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h5c1.1 0 2 .9 2 2v2c0 1.1-.9 2-2 2z"></path>
																</svg>
															</span>
															<div class="small-indicator"></div>
														</div>
														<div class="tool-selector disabled" style="position: relative;">
															<span class="button disabled">
																<svg width="34" height="34" viewBox="0 0 34 34" class="symbol symbol-distribute-vertical-btn-bld"><path d="M20 20h-5a2 2 0 0 0 0 4h5a2 2 0 0 0 0-4zm-5-6h5a2 2 0 0 0 0-4h-5a2 2 0 0 0 0 4zm9 1H11a2 2 0 0 0 0 4h13a2 2 0 0 0 0-4z" class="st1"></path>
																</svg>
															</span>
															<div class="small-indicator"></div>
														</div> -->
														<!-- <div class="tool-selector disabled" style="position: relative;">
															<span class="button disabled">
																<svg width="34" height="34" viewBox="0 0 34 34" class="symbol symbol-mech-to-height-btn-bld"><path d="M15 24h-2a2 2 0 0 1-2-2V12a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zm3-14h6v2h-6v-2zm0 12h6v2h-6v-2z" class="st1"></path><path d="M24 19a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v4" class="st2"></path>
																</svg>
															</span>
															<div class="small-indicator"></div>
														</div> -->
													<!-- </div> -->
												</div>
												<!-- <div class="seperator"></div>
												<div class="inner form">
													<label data-input-title="input_rotation" class="row">
														<span class="disabled">
															<svg width="14" height="12" viewBox="0 0 14 12" class="symbol symbol-toolbarRotation">
																<path fill-rule="evenodd" d="M9.59 11.64c.18-1.37-.26-3.05-1-4.22-.63-1-1.52-1.76-2.55-2.24L9 .93 8.18.36-.37 12.64h13.74v-1H9.59zm-4-6c.92.4 1.6 1.43 2.15 2.31.62.98.88 2.15.73 3.3-.01.06-.06.23-.1.39H1.54l4.05-6z"></path>
															</svg>
														</span>
														<div class="input-stepper disabled has-units small degrees">
															<input type="text" readonly="" maxlength="8" step="1" class="input" value="0" disabled="">
															<span class="units-container">
																<span class="units">°</span>
															</span>
														</div>
													</label>
												</div>
												<div class="inner button-grid">
													<span class="button disabled">
														<svg width="33" height="33" viewBox="0 0 33 33" class="symbol symbol-flip-horizontal symbol symbol-symbol-flip-horizontal"><path d="M16 23V9h2v14h-2zm-5.993-12c.246 0 .497.098.7.316L15 16l-4.293 4.684a.95.95 0 0 1-.7.316C9.493 21 9 20.571 9 19.924v-7.848C9 11.429 9.493 11 10.007 11zm.493 2.311v5.378L12.965 16 10.5 13.311zM19 16l3.823-4.483c.679-.973 2.177-.481 2.177.714v7.538c0 1.195-1.498 1.687-2.177.714L19 16z" class="st1"></path>
														</svg>
													</span>
													<span class="button disabled">
														<svg width="33" height="33" viewBox="0 0 33 33" class="symbol symbol-flip-vertical symbol symbol-symbol-flip-vertical"><path d="M10 17v-2h14v2H10zm7 1l4.684 4.293c.675.63.197 1.707-.76 1.707h-7.848c-.957 0-1.435-1.077-.76-1.707L17 18zm0 2.035L14.311 22.5h5.378L17 20.035zM17 14l-4.483-3.823C11.544 9.498 12.036 8 13.231 8h7.538c1.195 0 1.687 1.498.714 2.177L17 14z" class="st1"></path>
														</svg>
													</span>
												</div> -->
												<div class="seperator"></div>
												<div class="inner form">
													<span class="layout-title">Size (V)</span>
													<label class="row" matTooltip="Width"  matTooltipPosition="left" matTooltipClass="tooltip-red">
														<span class="label" style="padding-right: 4px;">W:</span>
														<div class="input-stepper small" [class.disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)">
															<input type="text" [disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)" maxlength="5" data-aid="width-stepper" step="1" class="input" [value]="liveXY['w']" (input)="selectedComponentChange('w', $event.target.value)">
														</div>
													</label>
													<label class="row" matTooltip="Height"  matTooltipPosition="left" matTooltipClass="tooltip-red">
														<span class="label">H:</span>
														<div class="input-stepper small" [class.disabled]="(liveXY.name !== '' && componentsName[liveXY.name].rules.ratioSize) || !(selectedColumn !== undefined && selectedComponent !== undefined)">
															<input type="text" [disabled]="(liveXY.name !== '' && componentsName[liveXY.name].rules.ratioSize) || !(selectedColumn !== undefined && selectedComponent !== undefined)" maxlength="5" data-aid="height-stepper" step="1" class="input" [value]="liveXY['h']" (input)="selectedComponentChange('h', $event.target.value)">
														</div>
													</label>
												</div>
												<div class="seperator"></div>
												<div class="inner form">
													<span class="layout-title">Position</span>
													<label data-input-title="input_x" class="row input_x" matTooltip="X Position"  matTooltipPosition="left" matTooltipClass="tooltip-red">
														<span class="input_x_label label">X:</span>
														<div class="input-stepper small" [class.disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)">
															<input type="text" maxlength="5" step="1" class="input" value="0" [disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)" [value]="liveXY['x']" (input)="selectedComponentChange('x', $event.target.value)">
														</div>
													</label>
													<label data-input-title="input_y" class="row" matTooltip="Y Position"  matTooltipPosition="left" matTooltipClass="tooltip-red">
														<span class="label">Y:</span>
														<div class="input-stepper small" [class.disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)">
															<input type="text" [disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)" maxlength="5" step="1" class="input" [value]="liveXY['y']" (input)="selectedComponentChange('y', $event.target.value)">
														</div>
													</label>
													<label data-input-title="input_z" class="row" matTooltip="Z Position"  matTooltipPosition="left" matTooltipClass="tooltip-red">
														<span class="label">Z:</span>
														<div class="input-stepper small" [class.disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)">
															<input type="text" [disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)" maxlength="2" step="1" class="input" [value]="liveXY['z']" (input)="selectedComponentChange('z', $event.target.value)">
														</div>
													</label>
													<label data-input-title="input_c" class="row" matTooltip="Center Position"  matTooltipPosition="left" matTooltipClass="tooltip-red">
														<span class="label">C:</span>
														<div class="input-stepper small" [class.disabled]="true">
															<input type="text" [disabled]="true" maxlength="5" step="1" class="input" [value]="liveXY['c']">
														</div>
													</label>
													<!-- <label data-input-title="input_m" class="row" matTooltip="Middle Position"  matTooltipPosition="left" matTooltipClass="tooltip-red">
														<span class="label">M:</span>
														<div class="input-stepper small" [class.disabled]="true">
															<input type="text" [disabled]="true" maxlength="2" step="1" class="input" [value]="liveXY['m']">
														</div>
													</label> -->
												</div>
												<div class="seperator"></div>
												<div class="inner form" *ngIf="liveXY.name === 'editor'">
													<!-- <span class="layout-title">BG Color</span> -->
													<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="3px" >
													<div fxFlex style="text-align: left;">
															<mat-icon *ngIf="liveXY['bgcolor']" (click)="selectedComponentChange('bgcolor', '')" matTooltip="Clear Color" matTooltipClass="tooltip-red">invert_colors</mat-icon>
															<mat-icon *ngIf="!liveXY['bgcolor'] || liveXY['bgcolor'] === ''" (click)="selectedComponentChange('bgcolor', '#efefef')" matTooltip="Enable Color" matTooltipClass="tooltip-red">invert_colors_off</mat-icon>
													</div>
														<div fxFlex style="text-align: center;">
															<input matInput [disabled]="!liveXY['bgcolor'] || liveXY['bgcolor'] === ''" type="color" name="bgcolor" [(value)]="liveXY['bgcolor']" style="width: 25px" (change)="selectedComponentChange('bgcolor', $event.target.value)" matTooltip="Select Background" matTooltipClass="tooltip-red">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</mat-card>
							</ng-container>
							<ng-container>
								<mat-card class="rightmenuTemplatesBox" [hidden]="openedRightTemplateMenu !== 'tempalatesList'" (click)="cancelThru($event)" clickOutside (clickOutside)="openedRightTemplateMenu = ''">
									<mat-card-title>
										{{'Templates' | translate}}
										<mat-icon class="actions" (click)="openedRightTemplateMenu='';">cancel</mat-icon>
									</mat-card-title>
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
										<div fxFlex style="min-height: 50vh;height: calc(100vh - 115px);overflow-y: scroll;" >
											<mat-card *ngFor="let tmp of templateList;let indexT = index" (click)="loadTemplateSetting(tmp._id)" style="cursor:pointer;margin-bottom:10px">
												<mat-card-title>
													{{tmp.name}}
													<mat-icon class="actions" (click)="deleteTemplate($event,indexT, tmp._id)">delete</mat-icon>
												</mat-card-title>
												<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
													<div fxFlex style="min-height: 100px;
    border: 1px solid #ecebeb;overflow: hidden;border-radius: 2px;" [ngStyle]="{'background-color': tmp.bgcolor ? tmp.bgcolor : '#f2f2f2'}">
														<img style="margin: 0 -16px -3px -16px;" mat-card-image [src]="tmp.bgImage">
													</div>
												</div>
											</mat-card>
										</div>
									</div>
								</mat-card>
							</ng-container>
						</div>
						<div class="leftmenu">
							<mat-card class="leftmenuBox" [hidden]="openedLeftMenu !== 'pagesPop'" (click)="cancelThru($event)" [class.leftmenuBox-extend]="showPageSettings">
								<mat-card-title>
									Setup {{'Sessions' | translate}}
									<mat-icon class="actions" (click)="openedLeftMenu='';showPageSettings=false">cancel</mat-icon>
								</mat-card-title>
								<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
									<div fxFlex>
										<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
												<div fxFlex >
													<mat-list role="list" >
														<mat-list-item role="listitem" *ngFor="let session of sessionList">
															<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
																	<div fxFlex="90" (click)="selectSession(session._id)" class="sessionItem" [class.selectedSession]="data.data._id === session._id">
																		{{session.name}}
																	</div>
																	<!-- <div fxFlex="10" align="right">
																		<button mat-icon-button (click)="openSession(session._id)"><mat-icon title="Go to Session">open_in_browser</mat-icon></button>
																	</div> -->
																	<div fxFlex="10" align="right">
																		<button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon title="More Actions">more_vert</mat-icon></button>
													          <mat-menu #menu="matMenu">
													            <button mat-menu-item (click)="openSession(session._id)" >{{'Open' | translate}}</button>
													            <button mat-menu-item (click)="editSession(session._id)" >{{'Edit' | translate}}</button>
													            <button mat-menu-item (click)="editSessionSetting(session._id)" >{{'Type' | translate}}</button>
													            <button mat-menu-item (click)="deleteSession(session._id)" >{{'Delete' | translate}}</button>
													          </mat-menu>
																	</div>
														</div>
														</mat-list-item>
													</mat-list>
													<div style="display: flex;margin-top: 10px;justify-content: flex-end;">
														<button mat-raised-button (click)="createSession()">Add {{'Session' | translate}}</button>
													</div>
												</div>
												<div fxFlex *ngIf="showPageSettings">
													<!-- <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
														<div fxFlex="50">
															<div class="fieldInput" style="width:100%">
																	<mat-form-field style="width:100%">
																		<input matInput [required]="!metaFieldSetting['name']['nullable']" name="name" [(ngModel)]="data.data['name']" placeholder="{{metaFieldSetting['name']['displayName'] | translate}}" >
																	</mat-form-field>
															</div>
														</div>
												</div> -->
													<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
															<div fxFlex>
																<div class="fieldInput" style="width:100%" >
																		<mat-form-field style="width:100%">
																			<mat-label>{{metaFieldSetting['streamMode']['displayName'] | translate}}</mat-label>
																			<mat-select ng-model="col.name" (selectionChange)="setStreamMode($event.value)" [value]="data.data['streamMode']" style="color: rgba(0,0,0,.54);
																			margin-top: 8px;" >
																				<mat-option value="" disabled>Select {{metaFieldSetting['streamMode']['displayName'] | translate}}</mat-option>
																				<mat-option *ngFor="let itm of metaFieldSetting['streamMode'].enum" [value]="itm.value" >{{itm.displayName | translate}}</mat-option>
																				</mat-select>
																		</mat-form-field>
																		<div style="color: rgba(0,0,0,.54);font-size: 12px;">
																			<span *ngIf="data.data['streamMode'] === 'hls'"> Maximum number of cameras is {{data.data['maximumCameras']}}</span>
																			<span  *ngIf="(data.data['streamMode'] === 'rtc' || data.data['streamMode'] === 'meeting') && data.data['maximumAttendees'] > -1"> Maximum number of attendees is {{data.data['maximumAttendees']}}</span>
																			<span  *ngIf="data.data['streamMode'] === 'rtctohls' && data.data['maximumAttendees'] > -1"> First {{data.data['maximumAttendees']}} attendees will view in RTC, the rest in HLS</span>
																		</div>
																</div>
															</div>
													</div>
													<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="optionDesktopSettingsStatus['numberOfAttendees'] && data.data['streamMode'] === 'largemeeting'">
														<div fxFlex >
															<div class="fieldInput" style="width:100%" >
																	<mat-form-field style="width: 100%;">
																			<input matInput type="number" min="0" placeholder="{{ 'Expected number of attendees' | translate }}" [(value)]="settingObject['desktop']['optionSettings']['numberOfAttendees']" (change)="setNumericOptions($event, 'numberOfAttendees', $event.target.value, 1, 20)">
																	</mat-form-field>
															</div>
														</div>
													</div>
												</div>
											</div>
									</div>
							</div>
							</mat-card>
							<mat-card class="leftmenuBox leftmenuBoxComponents" [hidden]="openedLeftMenu !== 'componentPop'" (click)="cancelThru($event)" *ngIf="viewMode==='desktop' || viewMode==='mobile' || viewMode==='desktop-offline' || viewMode==='mobile-offline'">
								<mat-card-title>
									Add Components
									<mat-icon class="actions" (click)="openedLeftMenu=''">cancel</mat-icon>
								</mat-card-title>
								<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
									<div fxFlex >
										<ng-container *ngFor="let cmp of componentsListType[viewMode]">
											<div class="example-box-horizontal" *ngIf="!emptyComponents.includes(cmp.name) && !cameraComponents.includes(cmp.name) && !advancedComponents.includes(cmp.name) && !videoComponents.includes(cmp.name)" [class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"  (click)="insertComponent(cmp)">
												{{componentsName[cmp.name]['displayName'] | translate}}
											</div>
										</ng-container>
									</div>
								</div>
								<mat-divider ></mat-divider>
								<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" style="margin:20px 0px 10px 0px">
									<div fxFlex >
										Add Advanced Components
									</div>
								</div>
								<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
									<div fxFlex >
										<ng-container *ngFor="let cmp of componentsListType[viewMode]">
											<div class="example-box-horizontal" *ngIf="advancedComponents.includes(cmp.name)" [class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"  (click)="insertComponent(cmp)">
												{{componentsName[cmp.name]['displayName'] | translate}}
											</div>
										</ng-container>
									</div>
								</div>
							</mat-card>
							<mat-card class="leftmenuBox leftmenuBoxComponents" [hidden]="openedLeftMenu !== 'componentsAddedPop'" (click)="cancelThru($event)" >
								<mat-card-title>
									Inserted Components
									<mat-icon class="actions" (click)="openedLeftMenu=''">cancel</mat-icon>
								</mat-card-title>
								<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="settingObject && (viewMode==='desktop' || viewMode==='mobile' || viewMode==='desktop-offline' || viewMode==='mobile-offline')">
									<div fxFlex *ngIf="settingObject.hasOwnProperty('version') && settingObject.version == defaultSetting.version">
										<ng-container *ngFor="let cmp of settingObject[viewMode]['columns'][0]['components'];let index = index">
											<div  class="example-box-horizontal" (click)="selectThisComponent(index)">
												{{componentsName[cmp.name]['displayName'] | translate}}
											</div>
										</ng-container>
										<span *ngIf="settingObject[viewMode]['columns'][0]['components'].length === 0">
											No Components Inserted
										</span>
									</div>
								</div>
							</mat-card>
							<mat-card class="leftmenuBox leftmenuBoxComponents" [hidden]="openedLeftMenu !== 'videoPop'" (click)="cancelThru($event)" >
								<mat-card-title>
									Add Video
									<mat-icon class="actions" (click)="openedLeftMenu=''">cancel</mat-icon>
								</mat-card-title>
								<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="viewMode==='desktop' || viewMode==='mobile' || viewMode==='desktop-offline' || viewMode==='mobile-offline'">
									<div fxFlex >
										<ng-container *ngFor="let cmp of componentsListType[viewMode]">
											<div *ngIf="videoComponents.includes(cmp.name)">
												<div class="example-box-horizontal" *ngFor="let vidType of videoComponentTypes" [class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"  (click)="insertDefaultVideoComponent(cmp, vidType.value)">
													{{vidType['displayName'] | translate}}
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</mat-card>
							<mat-card class="leftmenuBox leftmenuBoxComponents" [hidden]="openedLeftMenu !== 'cameraComponentPop'" (click)="cancelThru($event)" >
								<mat-card-title>
									Add Camera
									<mat-icon class="actions" (click)="openedLeftMenu=''">cancel</mat-icon>
								</mat-card-title>
								<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="viewMode==='desktop' || viewMode==='mobile' || viewMode==='desktop-offline' || viewMode==='mobile-offline'">
									<div fxFlex >
										<ng-container *ngFor="let cmp of componentsListType[viewMode]">
											<div class="example-box-horizontal" *ngIf="cameraComponents.includes(cmp.name)" [class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"  (click)="insertComponent(cmp)">
												{{componentsName[cmp.name]['displayName'] | translate}}
											</div>
										</ng-container>
									</div>
								</div>
							</mat-card>
							<mat-card class="leftmenuBox leftmenuBoxComponents" [hidden]="openedLeftMenu !== 'peoplePop'" (click)="cancelThru($event)" >
								<mat-card-title>
									Add People
									<mat-icon class="actions" (click)="openedLeftMenu=''">cancel</mat-icon>
								</mat-card-title>
								<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
									<div fxFlex >
										<div class="example-box-horizontal" *ngFor="let ppl of peopleTypeKeys" (click)="addPeople(ppl)">
											Add {{peopleType[ppl] | translate}}
										</div>
									</div>
								</div>
							</mat-card>
							<mat-card class="leftmenuBox leftmenuBoxComponents" [hidden]="openedLeftMenu !== 'settingsPop'" (click)="cancelThru($event)" >
								<mat-card-title>
									Enable Options
									<mat-icon class="actions" (click)="openedLeftMenu=''">cancel</mat-icon>
								</mat-card-title>
								<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
									<div fxFlex *ngIf="settingObject && settingObject.hasOwnProperty('desktop') && viewMode==='desktop'" >
											<div class="settingsPop" *ngIf="optionDesktopSettingsStatus['enableFullScreen']">
												<mat-icon *ngIf="!settingObject['desktop']['optionSettings']['enableFullScreen']" (click)="setOptions($event, 'enableFullScreen', true)" title="{{'Full Screen' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
												<mat-icon *ngIf="settingObject['desktop']['optionSettings']['enableFullScreen']" (click)="setOptions($event, 'enableFullScreen', false)" title="{{'Full Screen' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
												<label>{{'Full Screen' | translate}}</label>
											</div>
											<div class="settingsPop" *ngIf="optionDesktopSettingsStatus['enableCaptureScreen']">
												<mat-icon *ngIf="!settingObject['desktop']['optionSettings']['enableCaptureScreen']" (click)="setOptions($event, 'enableCaptureScreen', true)" title="{{'Screen Capture' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
												<mat-icon *ngIf="settingObject['desktop']['optionSettings']['enableCaptureScreen']" (click)="setOptions($event, 'enableCaptureScreen', false)" title="{{'Screen Capture' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
												<label>{{'Screen Capture' | translate}}</label>
											</div>
											<div class="settingsPop" *ngIf="optionDesktopSettingsStatus['enableChat']">
												<mat-icon *ngIf="!settingObject['desktop']['optionSettings']['enableChat']" (click)="setOptions($event, 'enableChat', true)" title="{{'Chat' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
												<mat-icon *ngIf="settingObject['desktop']['optionSettings']['enableChat']" (click)="setOptions($event, 'enableChat', false)" title="{{'Chat' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
												<label>{{'Chat' | translate}}</label>
											</div>
											<div class="settingsPop" *ngIf="optionDesktopSettingsStatus['enableAskQuestions']">
												<mat-icon *ngIf="!settingObject['desktop']['optionSettings']['enableAskQuestions']" (click)="setOptions($event, 'enableAskQuestions', true)" title="{{'Ask Questions' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
												<mat-icon *ngIf="settingObject['desktop']['optionSettings']['enableAskQuestions']" (click)="setOptions($event, 'enableAskQuestions', false)" title="{{'Ask Questions' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
												<label>{{'Ask Questions' | translate}}</label>
											</div>
											<div class="settingsPop" *ngIf="optionDesktopSettingsStatus['enableBreakout'] && !meetingMode.includes(data.data['streamMode'])">
												<mat-icon *ngIf="!settingObject['desktop']['optionSettings']['enableBreakout']" (click)="setOptions($event, 'enableBreakout', true)" title="{{'Join Breakout' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
												<mat-icon *ngIf="settingObject['desktop']['optionSettings']['enableBreakout']" (click)="setOptions($event, 'enableBreakout', false)" title="{{'Join Breakout' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
												<label>{{'Join Breakout' | translate}}</label>
											</div>
											<div class="settingsPop" *ngIf="optionDesktopSettingsStatus['enableScreenShareButton']">
												<mat-icon *ngIf="!settingObject['desktop']['optionSettings']['enableScreenShareButton']" (click)="setOptions($event, 'enableScreenShareButton', true)" title="{{'Screen Share Button' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
												<mat-icon *ngIf="settingObject['desktop']['optionSettings']['enableScreenShareButton']" (click)="setOptions($event, 'enableScreenShareButton', false)" title="{{'Screen Share Button' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
												<label>{{'Screen Share Button' | translate}}</label>
											</div>
											<div class="settingsPop" *ngIf="optionDesktopSettingsStatus['enableRaiseHand'] && !meetingMode.includes(data.data['streamMode'])">
												<mat-icon *ngIf="!settingObject['desktop']['optionSettings']['enableRaiseHand']" (click)="setOptions($event, 'enableRaiseHand', true)" title="{{'Hand-Raise' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
												<mat-icon *ngIf="settingObject['desktop']['optionSettings']['enableRaiseHand']" (click)="setOptions($event, 'enableRaiseHand', false)" title="{{'Hand-Raise' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
												<label>{{'Hand-Raise' | translate}}</label>
											</div>
											<!-- <div class="settingsPop" *ngIf="optionDesktopSettingsStatus['enableAudienceView'] && data.data['streamMode'] === 'rtc'">
												<mat-icon *ngIf="!settingObject['desktop']['optionSettings']['enableAudienceView']" (click)="setOptions($event, 'enableAudienceView', true)" title="{{'Enable Audience View' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
												<mat-icon *ngIf="settingObject['desktop']['optionSettings']['enableAudienceView']" (click)="setOptions($event, 'enableAudienceView', false)" title="{{'Enable Audience View' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
												<label>{{'Enable Audience View' | translate}}</label>
											</div> -->
											<!-- <div class="settingsPop" *ngIf="optionDesktopSettingsStatus['enableLargeMeeting'] && !meetingMode.includes(data.data['streamMode'])">
												<mat-icon *ngIf="!settingObject['desktop']['optionSettings']['enableLargeMeeting']" (click)="setOptions($event, 'enableLargeMeeting', true)" title="{{'Enable Large Meeting' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
												<mat-icon *ngIf="settingObject['desktop']['optionSettings']['enableLargeMeeting']" (click)="setOptions($event, 'enableLargeMeeting', false)" title="{{'Enable Large Meeting' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
												<label>{{'Enable Large Meeting' | translate}}</label>
											</div>
											<div class="settingsPop" *ngIf="optionDesktopSettingsStatus['numberOfAttendees'] && ((data.data['streamMode'] === 'meeting' && settingObject['desktop']['optionSettings']['enableLargeMeeting']) || (data.data['streamMode'] === 'rtc' && settingObject['desktop']['optionSettings']['enableAudienceView']))">
												<mat-form-field style="width: 100%;">
														<input matInput type="number" min="0" placeholder="{{ 'Number Of Attendees' | translate }}" [(value)]="settingObject['desktop']['optionSettings']['numberOfAttendees']" (change)="setNumericOptions($event, 'numberOfAttendees', $event.target.value, 1, 20)">
												</mat-form-field>
											</div> -->
									</div>
									<div fxFlex *ngIf="settingObject && settingObject.hasOwnProperty('desktop-hls') && settingObject['desktop-hls'].hasOwnProperty('optionSettings') && viewMode==='desktop-hls'">
											<!-- <div class="settingsPop" >
													 <mat-icon *ngIf="!settingObject['desktop-hls']['optionSettings']['enableFullScreen']" (click)="setOptionsHLS($event, 'enableFullScreen', true)" title="{{'Full Screen' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
													 <mat-icon *ngIf="settingObject['desktop-hls']['optionSettings']['enableFullScreen']" (click)="setOptionsHLS($event, 'enableFullScreen', false)" title="{{'Full Screen' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
													 <label>{{'Full Screen' | translate}}</label>
											 </div> -->
 											<div class="settingsPop" >
 													 <mat-icon *ngIf="!settingObject['desktop-hls']['optionSettings']['addScreenShare']" (click)="setOptionsHLS($event, 'addScreenShare', true)" title="{{'Screen Share' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
 													 <mat-icon *ngIf="settingObject['desktop-hls']['optionSettings']['addScreenShare']" (click)="setOptionsHLS($event, 'addScreenShare', false)" title="{{'Screen Share' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
 													 <label>{{'Screen Share' | translate}}</label>
 											 </div>
											<!-- <div class="settingsPop" >
													 <mat-icon *ngIf="!settingObject['desktop-hls']['optionSettings']['enableChat']" (click)="setOptionsHLS($event, 'enableChat', true)" title="{{'Chat' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
													 <mat-icon *ngIf="settingObject['desktop-hls']['optionSettings']['enableChat']" (click)="setOptionsHLS($event, 'enableChat', false)" title="{{'Chat' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
													 <label>{{'Chat' | translate}}</label>
											 </div> -->
											<!-- <div class="settingsPop" >
													 <mat-icon *ngIf="!settingObject['desktop-hls']['optionSettings']['enableAskQuestions']" (click)="setOptionsHLS($event, 'enableAskQuestions', true)" title="{{'Ask Questions' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
													 <mat-icon *ngIf="settingObject['desktop-hls']['optionSettings']['enableAskQuestions']" (click)="setOptionsHLS($event, 'enableAskQuestions', false)" title="{{'Ask Questions' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
													 <label>{{'Ask Questions' | translate}}</label>
											 </div> -->
									</div>
								</div>
							</mat-card>
							<mat-list role="list" class="leftmenuList">
							  <mat-list-item role="listitem" class="leftmenuListItem">
									<span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep1')">help</mat-icon>
									</span>
									<button [bdcWalkTriggerFor]="sessionStep1" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="openedLeftMenu === 'pagesPop' ? openedLeftMenu='' : openedLeftMenu='pagesPop' " matTooltip="Setup {{'Sessions' | translate}}" matTooltipPosition="right" matTooltipClass="tooltip-red">
									  <mat-icon>pages</mat-icon>
									</button>

								</mat-list-item>
							  <mat-list-item role="listitem" class="leftmenuListItem">
									<span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep2')">help</mat-icon>
									</span>
									<button [bdcWalkTriggerFor]="sessionStep2" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="galleryLibrary()" matTooltip="{{'Set Background' | translate}}" matTooltipPosition="right" matTooltipClass="tooltip-red">
										<mat-icon>image</mat-icon>
									</button>
								</mat-list-item>

							  <mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingRoomObject && backLayer">
									<button  mat-icon-button color="primary" (click)="insertEmptyComponent(false)" matTooltip="{{'Switch Session Layer' | translate}}" matTooltipPosition="right" matTooltipClass="tooltip-red">
										<mat-icon >flip_to_front</mat-icon>
									</button>
								</mat-list-item>

							  <mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingRoomObject && !backLayer">
									<span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep3')">help</mat-icon>
									</span>
									<button [bdcWalkTriggerFor]="sessionStep3" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="insertEmptyComponent(true)" matTooltip="{{'Add Clickable doorways' | translate}}" matTooltipPosition="right" matTooltipClass="tooltip-red">
										<mat-icon >flip_to_back</mat-icon>
									</button>
								</mat-list-item>

							  <mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingRoomObject && backLayer">
									<button mat-icon-button color="primary" (click)="insertEmptyComponent(true)" matTooltip="{{'Add Clickable doorways' | translate}}" matTooltipPosition="right" matTooltipClass="tooltip-red">
										<mat-icon>border_outer</mat-icon>
									</button>
								</mat-list-item>

							  <mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject" [hidden]="backLayer">
									<span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep4')">help</mat-icon>
									</span>
									<button [bdcWalkTriggerFor]="sessionStep4" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="openedLeftMenu === 'peoplePop' ? openedLeftMenu='' : openedLeftMenu='peoplePop'" matTooltip="{{'Add People' | translate}}" matTooltipPosition="right" matTooltipClass="tooltip-red">
										<mat-icon>people</mat-icon>
									</button>
								</mat-list-item>

							  <mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && viewMode !=='desktop-hls' && viewMode !=='mobile-hls' && (!meetingMode.includes(data.data['streamMode']) || (meetingMode.includes(data.data['streamMode']) && (viewMode ==='desktop-offline' || viewMode ==='mobile-offline')))" [hidden]="backLayer">
									<span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep5')">help</mat-icon>
									</span>
									<button [bdcWalkTriggerFor]="sessionStep5" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="openedLeftMenu === 'cameraComponentPop' ? openedLeftMenu='' : openedLeftMenu='cameraComponentPop'" matTooltip="{{'Add Camera' | translate}}" matTooltipPosition="right" matTooltipClass="tooltip-red">
										<mat-icon>video_call</mat-icon>
									</button>
								</mat-list-item>

							  <mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && viewMode !=='desktop-hls' && viewMode !=='mobile-hls' && (!meetingMode.includes(data.data['streamMode']) || (meetingMode.includes(data.data['streamMode']) && (viewMode ==='desktop-offline' || viewMode ==='mobile-offline')))" [hidden]="backLayer">
									<span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep6')">help</mat-icon>
									</span>
									<button [bdcWalkTriggerFor]="sessionStep6" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="openedLeftMenu === 'videoPop' ? openedLeftMenu='' : openedLeftMenu='videoPop'" matTooltip="{{'Add Video' | translate}}"  matTooltipPosition="right" matTooltipClass="tooltip-red">
										<mat-icon>add_to_queue</mat-icon>
									</button>

								</mat-list-item>
							  <mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && viewMode !=='desktop-hls' && viewMode !=='mobile-hls' && (!meetingMode.includes(data.data['streamMode']) || (meetingMode.includes(data.data['streamMode']) && (viewMode ==='desktop-offline' || viewMode ==='mobile-offline')))" [hidden]="backLayer">
									<span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep7')">help</mat-icon>
									</span>
									<button [bdcWalkTriggerFor]="sessionStep7" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="openedLeftMenu === 'componentPop' ? openedLeftMenu='' : openedLeftMenu='componentPop'" matTooltip="{{'Add Components' | translate}}"  matTooltipPosition="right" matTooltipClass="tooltip-red">
										<mat-icon>add_circle</mat-icon>
									</button>

								</mat-list-item>
							  <mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && (viewMode==='desktop' || viewMode==='desktop-hls' )" [hidden]="backLayer">
									<span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep8')">help</mat-icon>
									</span>
									<button [bdcWalkTriggerFor]="sessionStep8" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="openedLeftMenu === 'settingsPop' ? openedLeftMenu='' : openedLeftMenu='settingsPop'" matTooltip="{{'Enable Options' | translate}}" matTooltipPosition="right" matTooltipClass="tooltip-red">
										<mat-icon>settings</mat-icon>
									</button>
								</mat-list-item>


								<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject" [hidden]="backLayer">
									<span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep9')">help</mat-icon>
									</span>
									<button [bdcWalkTriggerFor]="sessionStep9" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="assignContent()" matTooltip="{{'Assign Polls and Triggered Content' | translate}}" matTooltipPosition="right" matTooltipClass="tooltip-red">
										<mat-icon>list_alt</mat-icon>
									</button>
								</mat-list-item>

								<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject" [hidden]="backLayer">
									<span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep10')">help</mat-icon>
									</span>
									<button [bdcWalkTriggerFor]="sessionStep10" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="addBreakoutSession()" matTooltip="{{'Add Breakout' | translate}}" matTooltipPosition="right" matTooltipClass="tooltip-red">
										<mat-icon>call_split</mat-icon>
									</button>
								</mat-list-item>

							  <mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && viewMode !=='desktop-hls' && viewMode !=='mobile-hls' && (!meetingMode.includes(data.data['streamMode']) || (meetingMode.includes(data.data['streamMode']) && (viewMode ==='desktop-offline' || viewMode ==='mobile-offline')))" [hidden]="backLayer">
									<span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep11')">help</mat-icon>
									</span>
									<button [bdcWalkTriggerFor]="sessionStep11" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="openedLeftMenu === 'componentsAddedPop' ? openedLeftMenu='' : openedLeftMenu='componentsAddedPop'" matTooltip="{{'List Inserted Components' | translate}}" matTooltipPosition="right" matTooltipClass="tooltip-red">
										<mat-icon>view_list</mat-icon>
									</button>
								</mat-list-item>
							</mat-list>
						</div>
						<div [hidden]="!backLayer" fxLayout="row" fxLayout.lt-md="column" fxFlex *ngIf="settingRoomObject">
						     <div fxFlex *ngIf="settingRoomObject.hasOwnProperty('room-offline')">
									 <app-screen-layout #roomOffline *ngIf="settingRoomObject.hasOwnProperty('version') && settingRoomObject.version == defaultRoomSetting.version" [type]="'room-offline'" [data]="data" [settingObject]="settingRoomObject['room-offline']" (setSelectComponent)="selectComponent($event)" (setUpdateLayout)="updateRoomLayout($event)"></app-screen-layout>
								</div>
						</div>
						<!-- desktop Version -->
						<div [hidden]="backLayer" fxLayout="row" fxLayout.lt-md="column" fxFlex *ngIf="settingObject && viewMode==='desktop' && !meetingMode.includes(data.data['streamMode'])">
						     <div fxFlex *ngIf="settingObject.hasOwnProperty('desktop')">
									 <app-screen-layout #desktopOnline *ngIf="settingObject.hasOwnProperty('version') && settingObject.version == defaultSetting.version" [type]="'desktop'" [data]="data" [settingObject]="settingObject['desktop']" (setSelectComponent)="selectComponent($event)" (setUpdateLayout)="updateLayout($event)"></app-screen-layout>
								</div>
						</div>
						<!-- hls Version -->
						<div [hidden]="backLayer" fxLayout="row" fxLayout.lt-md="column" fxFlex *ngIf="settingObject && viewMode==='desktop-hls' && !meetingMode.includes(data.data['streamMode'])">
								<div fxFlex *ngIf="settingObject.hasOwnProperty('desktop-hls')">
									<app-screen-layout #desktophlsOnline *ngIf="settingObject.hasOwnProperty('version') && settingObject.version == defaultSetting.version" [type]="'desktop-hls'" [guestsTotal]="guestsTotal" [data]="data" [settingObject]="settingObject['desktop-hls']" (setSelectComponent)="selectComponent($event)" (setUpdateLayout)="updateLayout($event)"></app-screen-layout>
							 </div>
						</div>
						<!-- Offline Version -->
						<div [hidden]="backLayer" fxLayout="row" fxLayout.lt-md="column" fxFlex *ngIf="settingObject && viewMode==='desktop-offline'">
					     <div fxFlex >
									 <app-screen-layout #desktopOffline *ngIf="settingObject.hasOwnProperty('version') && settingObject.version == defaultSetting.version" [type]="'desktop-offline'" [data]="data" [settingObject]="settingObject['desktop-offline']" (setSelectComponent)="selectComponent($event)" (setUpdateLayout)="updateLayout($event)"></app-screen-layout>
							</div>
					</div>
	      </div>
	    </div>
	</div>
</form>
<bdc-walk-popup #sessionStep1 name="sessionStep1" header="Setup {{'Sessions' | translate}}" xPosition="after" [horizontal]="true" sideNoteText="1/11" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 'room'}" [onButtonCompleteTask]="{sessionNext: 1}" >
  Create, edit, delete and set the stream mode type of the session
</bdc-walk-popup>

<bdc-walk-popup #sessionStep2 name="sessionStep2" header="{{'Set Background' | translate}}" xPosition="after" [horizontal]="true" sideNoteText="2/11" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 1}" [onButtonCompleteTask]="{sessionNext: 2}">
  Set the background color or image
</bdc-walk-popup>

<bdc-walk-popup #sessionStep3 name="sessionStep3" header="{{'Add Clickable doorways' | translate}}" xPosition="after" [horizontal]="true" sideNoteText="3/11" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 2}" [onButtonCompleteTask]="{sessionNext: 3}">
  Set you clickable links on the background
</bdc-walk-popup>

<bdc-walk-popup #sessionStep4 name="sessionStep4" header="{{'Add People' | translate}}" xPosition="after" [horizontal]="true" sideNoteText="4/11" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 3}" [onButtonCompleteTask]="{sessionNext: 5}">
  Assign the varios users in the session
</bdc-walk-popup>

<bdc-walk-popup #sessionStep5 name="sessionStep5" header="{{'Add Video' | translate}}" xPosition="after" [horizontal]="true" sideNoteText="5/11" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 4}" [onButtonCompleteTask]="{sessionNext: 7}">
  Add and position a video component in the session
</bdc-walk-popup>

<bdc-walk-popup #sessionStep6 name="sessionStep6" header="{{'Add Camera' | translate}}" xPosition="after" [horizontal]="true" sideNoteText="6/11" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 5}" [onButtonCompleteTask]="{sessionNext: 8}">
  Add and position a camera in the session
</bdc-walk-popup>

<bdc-walk-popup #sessionStep7 name="sessionStep7" header="{{'Add Components' | translate}}" xPosition="after" [horizontal]="true" sideNoteText="7/11" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 6}" [onButtonCompleteTask]="{sessionNext: 9}">
  Add and position a component in the session
</bdc-walk-popup>

<bdc-walk-popup #sessionStep8 name="sessionStep8" header="{{'Enable Options' | translate}}" xPosition="after" [horizontal]="true" sideNoteText="8/11" buttonText="Next" [showButton]="true" [mustCompleted]="{sessionNext: 7}" [onButtonCompleteTask]="{sessionNext: 8}">
  Enable and disable the option for the selected mode in the session
</bdc-walk-popup>

<bdc-walk-popup #sessionStep9 name="sessionStep9" header="{{'Assign Polls and Triggered Content' | translate}}" xPosition="after" [horizontal]="true" sideNoteText="9/11" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 8}" [onButtonCompleteTask]="{sessionNext: 9}">
  Assign the polls and triggered content created in the library
</bdc-walk-popup>

<bdc-walk-popup #sessionStep10 name="sessionStep10" header="{{'Add Breakout' | translate}}" xPosition="after" [horizontal]="true" sideNoteText="10/11" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 9}" [onButtonCompleteTask]="{sessionNext: 10}">
  Create groups for the breakouts in the session
</bdc-walk-popup>

<bdc-walk-popup #sessionStep11 name="sessionStep11" header="{{'List Inserted Components' | translate}}" xPosition="after" [horizontal]="true" sideNoteText="11/11" [showButton]="true" [mustCompleted]="{sessionNext: 10}" [onButtonCompleteTask]="{sessionNext: 11}">
  View and Select the added componentsand cameras in the sesison
</bdc-walk-popup>
