import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RequestService } from '../../../shared/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface ViewDialogData {
	title: string;
	data: any;
	hasAdd: boolean;
	confirmData: any;
}

@Component({
	selector: 'app-billboard-select-dialog-modal',
	templateUrl: './billboard-select-dialog.component.html',
	styleUrls: ['./billboard-select-dialog.component.scss']
})
export class BillboardSelectDialogComponent implements OnInit {
	public filterData: any = {'$and': [
		{'organizationId._id': { '$eq': this.requestService.orgId }}
	]};
	constructor(private requestService: RequestService, public dialogRef: MatDialogRef<BillboardSelectDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		//console.log('ViewDialogData', data);
	}

	ngOnInit() {
	}
	selectItemCheck(data) {
		this.dialogRef.close(data);
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
}
