<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="settingObject" >
    <div fxFlex cdkDropListGroup>
      <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
        <div fxFlex>
          <div class="overlayLayout example-boundary" #mainBoundary>
            <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0VW" class="bgComponent">
              <div fxFlex *ngFor="let col of settingObject['columns'];let index = index"  class="example-list">
                  <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
                    <div fxFlex
                    id="rowsmenu{{index}}" name="rowsmenu" class="example-box-row" >
                      <div class="example-box" *ngFor="let cmp of col['components'];let subIndex = index" cdkDrag cdkDragBoundary=".example-boundary" [cdkDragFreeDragPosition]="{x: getX(cmp.x, mainBoundary), y: getY(cmp.y, mainBoundary)}"
                      [ngStyle]="{'z-index': getZ(cmp.z, index, subIndex), 'height' : getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize), 'width' : cmp.w+'vw', 'backgroundColor': cmp.bgcolor}"  [class.cameraComponent]="cameraComponents.includes(cmp.name)"  [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'" [cdkDragDisabled]="true">
                        <div class="example-box-handle" [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'" *ngIf="cmp.active">
                          <div class="contentRealData" [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'" >
                            <ng-container *ngIf="tileComponents.includes(cmp.name) && cmp.metadata.id !== ''">
                                <app-iframe-url [iframeLink]="getTileLink(cmp.metadata.id)" [iframeParentHeight]="getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize)" [forEdit]="false"></app-iframe-url>
                            </ng-container>
                            <ng-container *ngIf="cmp['name'] === 'billboard' && cmp.metadata.id !== ''">
                              <app-slider [forEdit]="false" [bannerId]="cmp.metadata.id">
                              </app-slider>
                            </ng-container>
                            <ng-container *ngIf="cmp['name'] === 'picture'">
                              <img *ngIf="cmp.image" src="{{cmp.image}}" style="cursor: pointer;" (click)="selectPicture($event, cmp.metadata.type, cmp.metadata.link)" title="{{cmp.title}}">
                            </ng-container>
                            <ng-container *ngIf="cmp['name'] === 'empty-click'">
                              <div class="empty-click" *ngIf="cmp.shape !== 'poly'"
                                  [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'"
                                  (click)="selectPicture($event, cmp.metadata.type, cmp.metadata.link)" title="{{cmp.title}}">
                              </div>
                              <div style="height:100%;width:100%">
                                  <svg height="100%" width="100%"
                                      *ngIf="cmp.hasOwnProperty('svg') && cmp.shape === 'poly'">
                                      <polygon
                                          [attr.points]="getShapePoint(cmp.svg, mainBoundary, index, subIndex)"
                                          style="fill: rgb(102, 102, 102);stroke: rgb(51, 51, 51);stroke-width:1;opacity: 0; cursor: pointer;"
                                          (click)="selectPicture($event, cmp.metadata.type, cmp.metadata.link)" [attr.title]="cmp.title">
                                          <title>{{cmp.title}}</title>
                                      </polygon>
                                  </svg>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="cmp['name'] === 'editor'">
                              <div [innerHTML]="cmp.data | safeHtml"></div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
