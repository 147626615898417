import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


interface ViewDialogData {
	data: any;
	imageUrl: string;
}

@Component({
	selector: 'app-view-image-dialog-modal',
	templateUrl: './custom-view-image-dialog.component.html',
	styleUrls: ['./custom-view-image-dialog.component.scss']
})
export class ModalViewImageDialogComponent implements OnInit {
	public imageUrl: string = undefined;
	constructor(public dialogRef: MatDialogRef<ModalViewImageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		//console.log('ViewDialogData', data);
		this.imageUrl = data.imageUrl;
	}

	ngOnInit() {

	}

	closeModal(): void {
		this.dialogRef.close();
	}
}
