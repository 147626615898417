<div id="image-container" (contextmenu)="onAreaCreate($event, $event.layerX, $event.layerY)">
	<img [src]="src" style="width: 100vw;">
  <div class="coordinates-container">
    <div class="area" [ngStyle]="getCoordinateStyle(coordinate)" [title]="coordinate.name" *ngFor="let coordinate of coordinates" (click)="onAreaClick(coordinate)" (contextmenu)="onAreaEdit($event, coordinate)" cdkDrag>
      <div class="example-handle" cdkDragHandle>
        <mat-icon>open_with</mat-icon>
      </div>
    </div>
  </div>
</div>
