<div fxLayout="column" fxLayoutGap="1vw">
    <h4 fxFlex>{{data.title}}</h4>
    <div fxFlex [innerHTML]="data.content | safeHtml">
    </div>
    <div fxFlex fxFlexAlign="end">
        <button style="margin-right: 5px;" mat-raised-button (click)="goToLink(data.secondButton.link)"
            *ngIf="data.secondButton && data.secondButton.link">
            {{data.secondButton.label}}
        </button>
        <button mat-raised-button (click)="openLink($event)" *ngIf="data.closeButton.showCloseButton">
            {{data.closeButton.label}}
        </button>
    </div>
</div>