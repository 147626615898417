<mat-card class="example-card" style="height: 100%;padding: 16px 10px 3px;min-height: 463px">
  <mat-card-header>
    <mat-card-title>{{UIDisplay}} List</mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content style="margin-bottom: 0px;">
    <app-custom-select-create-autocomplete [value]="selectedCategory" [dataType]="'category'" [dataTypeDisplay]="'Category'" [placeholder]="''" [useOrg]="true" [canDelete]="false" (onSelectReturn)="selectCategory($event)" [type]="'tile'" [onBlur]="false" [addAll]="true"></app-custom-select-create-autocomplete>
    <mat-form-field >
        <input matInput #searchInput placeholder="{{'Search' | translate}}" [value]="searchVal">
    </mat-form-field>
    <mat-divider></mat-divider>
    <mat-nav-list style="min-height: 240px;">
      <mat-list-item *ngFor="let data of dataList" (click)="selectItemCheck(data)">
         <!-- <img *ngIf="data.art !== '/img/tile_default.jpg'" src="{{ data.art }}" class="tileArt" mat-list-icon/>
         <img *ngIf="data.art === '/img/tile_default.jpg'" src="/assets/{{ data.art }}" class="tileArt" mat-list-icon/> -->
         <a class="tileName" matLine href="Javascript:void(0)" >{{ data.title }}
           <span *ngIf="data.hasOwnProperty('smart') && data.smart.hasOwnProperty('apps')">
              <mat-icon *ngFor="let smt of data.smart.apps" aria-hidden="false" title="{{smt.name}}">emoji_objects</mat-icon>
            </span>
            <span *ngIf="data.hasOwnProperty('Procedure')">
              <mat-icon *ngFor="let proc of data.Procedure" aria-hidden="false" title="{{proc.name}}">local_hospital</mat-icon>
            </span>
            <span *ngIf="data.hasOwnProperty('notification') && data.notification.hasOwnProperty('apps')">
              <mat-icon *ngFor="let ntf of data.notification.apps" aria-hidden="false" title="{{ntf.name}}">notification_important</mat-icon>
            </span>
            <span *ngIf="data.hasOwnProperty('hsrRuleEngine')">
              <mat-icon *ngFor="let rul of data.hsrRuleEngine" aria-hidden="false" title="{{rul.ruleName}}">favorite</mat-icon>
            </span>
            <span *ngIf="data.hasOwnProperty('isWeight') && data.isWeight">
              <mat-icon aria-hidden="false" class="rotate90">switch_video</mat-icon>
            </span>
         </a>
         <button *ngIf="canDelete" mat-icon-button (click)="delete($event, data._id)">
            <mat-icon>cancel</mat-icon>
         </button>
         <!-- <mat-icon>arrow_forward_ios</mat-icon> -->
      </mat-list-item>
    </mat-nav-list>
  </mat-card-content>
  <mat-card-actions>
    <mat-paginator [hidePageSize]="true" [pageSize]="pageSize" [length]="paginatorTotal | async" [showFirstLastButtons]="true"></mat-paginator>
  </mat-card-actions>
</mat-card>
