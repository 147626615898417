import { Component, OnInit, Input, Output, EventEmitter, NgZone, OnChanges, ChangeDetectorRef, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LayoutService, RequestService, LayoutUtilsService, PageScopeService } from '../../../shared/services';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';

import { BdcWalkService } from '../../modules/bdc-walkthrough';
import { CustomSelectCreateAutocompleteComponent } from '../custom-select-create-autocomplete/custom-select-create-autocomplete.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalIframeDialogComponent } from '../iframe-dialog/iframe-dialog.component';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { RecorderService } from '../../services/recorder.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateY(-100%)',
        display: 'none',
        opacity: 0,
      })),
      state('out', style({
        transform: 'translateY(0%)',
        display: 'initial',
        opacity: 1
      })),
      transition('in => out', animate('500ms ease-in-out')),
      transition('out => in', animate('1000ms ease-in-out'))
      // transition('void => *', [
      //   style({ transform: 'translateY(-100%)', display: 'hidden' }),
      //   animate('3000ms ease-in', style({ transform: 'translateY(0%)' }))
      // ]),
      // transition('* => void', [
      //   animate('4000ms ease-in', style({ transform: 'translateY(-100%)' }))
      // ])
    ])
  ]
})
export class TopnavComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public organization = undefined;
  public orgType = environment.orgType;
  public lockSite = environment.lockSite;
  public hasListingDirectory = environment.hasListingDirectory;
  public permission: any[] = [environment.customKeys.roleEdit, environment.customKeys.roleAdmin];
  public viewPermission: any[] = [environment.customKeys.roleView];
  public enableTranslation = environment.enableTranslation;
  public title = environment.serverName;
  public subTitle = environment.subServerName;
  public production = environment.production;
  public donateLink: string = undefined;
  public pushRightClass: string;
  public userType: string = 'default';
  public language = 'en';
  isDnD: boolean = false;
  iframeDialog: MatDialogRef<any, any>;
  highlightDirectory: boolean = undefined;
  _isOpenGlobalChat: boolean = false;
  // _isOpenAgenda: boolean = false;
  drawerState: string = 'in';
  // lastChatMessage: any = { message: '' };
  // videoChatInvite: boolean = false;
  ringtoneAudio: any = undefined;
  ringTimeOut: any = undefined;
  goodConnection: boolean = true;
  isMobile: any;
  recordingStatus: boolean = false;
  counterSubscription: Subscription;
  countDownTextDay: number = undefined;
  countDownTextHour: number = undefined;
  countDownTextMin: number = undefined;
  countDownTextSec: number = undefined;

  @Input() highlightNotifications: boolean = false;
  @Input() showProductionLabel: any = undefined;
  @Input() showExit: boolean = false;
  @Input() roomData: any = undefined;
  @Input() sessionData: any = undefined;
  @Input() pushedTileLink: string = undefined;
  @Input() showStartEndSession: boolean = false;
  @Input() viewAsAttendee: boolean = false;
  @Input() backLayer: boolean = false;
  @Input() currentSessionUserRole: any = undefined;
  @Input() breakoutData: any = undefined;
  @Input() breakoutId: any = undefined;

  @Input() showTemplateActions: boolean = false;
  public viewModeDisplay: any = { desktop: 'Desktop', 'desktop-offline': 'Desktop', 'desktop-hls': 'Desktop', mobile: 'Mobile', 'mobile-hls': 'Mobile' };
  // public streamModeDisplay: any = { desktop: 'Regular', 'desktop-offline': 'Regular', 'desktop-hls': 'Broadcast', mobile: 'Regular', 'mobile-hls': 'Broadcast' };
  public streamModeDisplay: any = { rtc: 'RTC', rtctohls: 'RTC to HLS', meeting: 'Small Meeting', largemeeting: 'Large Meeting', hls: 'HLS' };
  public streamModeDisplayToolTip: any = { rtc: 'Realtime Interaction (RTC)', rtctohls: 'Dynamic (RTC to HLS)', meeting: 'Small Meeting', largemeeting: 'Large Meeting', hls: 'Broadcast (HLS)' };
  @Input() viewMode: string = 'desktop';
  @Input() streamMode: string = 'rtctohls';
  @Input() templateObject: any = undefined;
  @Input() layoutSessionData: any = undefined;


  public roomDataType: any = 'room';
  @Input() roomId: any = undefined;
  public roomfilterData: any;
  @Output() setRoomReturn = new EventEmitter<any>();


  @Output() highlightNotificationsChange = new EventEmitter<boolean>();
  @Output() onendSession = new EventEmitter<any>();
  @Output() viewModeReturn = new EventEmitter<string>();
  @Output() templateActionReturn = new EventEmitter<string>();
  @Output() backLayerReturn = new EventEmitter<boolean>();

  @ViewChild('ringStream') set playerRef(ref: ElementRef<HTMLAudioElement>) {
    this.ringtoneAudio = ref.nativeElement;
  }
  @ViewChild('roomList') roomList: CustomSelectCreateAutocompleteComponent;
  constructor(private bdcWalkService: BdcWalkService,
    private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, public router: Router, private translate: TranslateService, private layoutService: LayoutService,
    public dialog: MatDialog, private zone: NgZone, private route: ActivatedRoute, public changeDetectorRef: ChangeDetectorRef, private deviceService: DeviceDetectorService, public pageScope: PageScopeService) {
    // this.router.events.subscribe(val => {
    //     if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
    //         this.toggleSidebar();
    //     }
    // });
    this.isMobile = this.deviceService.isMobile();
    this.userType = this.requestService.getUserType();
  }

  ngOnInit() {
    this.pushRightClass = 'push-right';
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.roomfilterData = {
            '$and': [
              { 'organizationId._id': { '$eq': this.requestService.orgId } }
            ]
          };
          this.selectedUser = data;
          if (this.selectedUser.hasOwnProperty('chat_dnd'))
            this.isDnD = this.selectedUser['chat_dnd'];
        }
        this.language = this.requestService.lang;
      })
    );
    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.donateLink = data.donate;
          this.organization = data;
        }
      })
    );

    this.subscriptions.push(
      this.layoutService.appLayoutSubject.subscribe((data) => {
        if (data) {
          this.handleActions(data)
        }
      })
    );


  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());

  }
  resetTutorial(): void {
    this.bdcWalkService.reset('session');
  }
  handleActions(action: any): void {
    if (action.name === 'updateroom'){
      this.roomList.dataText = action.value.name;
    }
  }
  setLanguage(lang) {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang)
    this.requestService.lang = lang;
    this.language = lang;
    localStorage.setItem('lang', JSON.stringify(lang));
  }
  selectRoom(data) {
    if (data !== this.roomId) {
      this.setRoomReturn.emit(data);
    }
  }
  setViewModeReturn(e, mode: string = 'desktop'): void {
    // this.viewMode = mode;
    this.viewModeReturn.emit(mode);
  }
  setBackLayer(val): void {
    this.backLayerReturn.emit(val);
  }
  setViewModeOffline(status): void {
    if (status) {
      // if(['rtc', 'hls', 'rtctohls', 'meeting', 'largemeeting'].includes(this.streamMode)){
      if (['desktop-offline', 'desktop', 'desktop-hls'].includes(this.viewMode)) {
        this.viewModeReturn.emit('desktop-offline');
      } else if (['mobile-offline', 'mobile', 'mobile-hls'].includes(this.viewMode)) {
        this.viewModeReturn.emit('mobile-offline');
      }
    } else {
      if (['rtc', 'rtctohls', 'meeting', 'largemeeting'].includes(this.streamMode) && ['desktop-offline', 'desktop', 'desktop-hls'].includes(this.viewMode)) {
        this.viewModeReturn.emit('desktop');
      } else if (['rtc', 'rtctohls', 'meeting', 'largemeeting'].includes(this.streamMode) && ['mobile-offline', 'mobile', 'mobile-hls'].includes(this.viewMode)) {
        this.viewModeReturn.emit('mobile');
      } else if (this.streamMode === 'hls' && ['desktop-offline', 'desktop', 'desktop-hls'].includes(this.viewMode)) {
        this.viewModeReturn.emit('desktop-hls');
      } else if (this.streamMode === 'hls' && ['mobile-offline', 'mobile', 'mobile-hls'].includes(this.viewMode)) {
        this.viewModeReturn.emit('mobile-hls');
      } else {
        if (['desktop-offline', 'desktop', 'desktop-hls'].includes(this.viewMode)) {
          this.viewModeReturn.emit('desktop');
        } else if (['mobile-offline', 'mobile', 'mobile-hls'].includes(this.viewMode)) {
          this.viewModeReturn.emit('mobile');
        }
      }
    }
  }
  setTemplateActionReturn(action): void {
    this.templateActionReturn.emit(action);
  }
  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  goHome() {
    // this.router.navigate(['/rooms/list']);
  }

  onLoggedout() {
    this.requestService.logOutApi();
  }

  changeLang(language: string) {
    this.translate.use(language);
  }
  // public viewProfile(){
  //   const dialogRef = this.dialog.open(ModalUserDialogComponent, {
  //       width: '1200px',
  //       data: {
  //         title: 'My Profile',
  //         data: this.selectedUser,
  //       }
  //     });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       console.log('result', result);
  //     }
  //   });
  // }
  viewProfile() {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '600px',
      data: {
        dataType: 'resource/user',
        title: this.translate.instant('My Profile'),
        profile: true,
        data: { _id: this.selectedUser._id },
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
      }
    });
  }
  endSession() {
    this.onendSession.emit(true);
  }
  startSession() {
    this.onendSession.emit(false);
  }
  showFrame(iframeLink: string) {
    if (iframeLink) {
      // this.zone.run(() => {
      if (this.iframeDialog) {
        this.iframeDialog.close();
      }
      this.iframeDialog = this.dialog.open(ModalIframeDialogComponent, {
        data: {
          data: iframeLink,
          className: 'dialog-frame',
          iframeHeight: '280px'
        }
      });
      this.iframeDialog.disableClose = true;

      // });
      this.iframeDialog.afterClosed().subscribe(() => {
        this.highlightNotifications = false;
        this.highlightNotificationsChange.emit(false);
      });
    }
  }

  gotoBook() {
    this.router.navigate(['/book/']);
  }

  openAgenda() {
    this.router.navigateByUrl('/agenda');
    // this._isOpenAgenda = !this._isOpenAgenda;
    // this.opentokService.isOpenAgenda.next(this._isOpenAgenda);
    // this.detectChanges();
  }
  openSponsors() {
    this.router.navigateByUrl('/sponsors');
    // this._isOpenAgenda = !this._isOpenAgenda;
    // this.opentokService.isOpenAgenda.next(this._isOpenAgenda);
    // this.detectChanges();
  }
  openDonate() {
    if (this.donateLink && this.donateLink !== '') {
      window.open(this.donateLink, '_blank');
    }
  }

  public detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }

  toggleDnD() {
    this.requestService.saveData('resource/user', { _id: this.selectedUser['_id'], chat_dnd: !this.isDnD }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
      }
      if (data) {
        this.isDnD = !this.isDnD;
        this.requestService.updateUserData('chat_dnd', this.isDnD);
        this.detectChanges();
        //console.log(this.selectedUser)
        this.layoutUtilsService.showNotification(this.translate.instant('Preferences Saved'), this.translate.instant('Dismiss'));
      }
      // this.loading = false;
    }, true);
  }

  closeDrawer() {
    this.drawerState = 'in';
  }


  private slideDrawer() {
    this.drawerState = 'out';
    setTimeout(() => { this.drawerState = 'in' }, 3000);
    this.detectChanges();
  }




  toggleHint() {
    this.pageScope.isHintOn = !this.pageScope.isHintOn;
    localStorage.setItem('stellarHints', this.pageScope.isHintOn + '');
  }

}
