<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
      <mat-card>
				<!-- <div class="fieldInput">
						<mat-form-field>
							<input matInput type="number" name="timer" [(ngModel)]="returnData['timer']" placeholder="{{'Time delay' | translate}}" >
						</mat-form-field>
				</div> -->
				<div class="fieldInput">
					<mat-form-field style="width: 100%;">
							<input matInput type="number" min="0" placeholder="{{ 'Number of Rows' | translate }}" [(value)]="returnData['gridSizeY']" (change)="editNumericAttribute($event, index, subIndex, 'gridSizeY', $event.target.value, 1, 20)">
					</mat-form-field>
				</div>
				<div class="fieldInput">
					<mat-form-field style="width: 100%;">
							<input matInput type="number" min="0" placeholder="{{ 'Number of Columns' | translate }}" [(value)]="returnData['gridSizeX']" (change)="editNumericAttribute($event, index, subIndex, 'gridSizeX', $event.target.value, 1, 20)">
					</mat-form-field>
				</div>
				<!-- <div class="fieldInput">
					<mat-icon *ngIf="!returnData['moderated']" (click)="returnData['moderated'] = true" title="{{'All attendees can speak at the same time' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
					<mat-icon *ngIf="returnData['moderated']" (click)="returnData['moderated'] = false" title="{{'All attendees can speak at the same time' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
					<label style="width: 13vw;">{{'Moderated' | translate}}</label>
				</div> -->
      </mat-card>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button mat-raised-button (click)="closeModal(returnData)">{{'Confirm' | translate}}</button>
	</div>
	<br/>
</div>
