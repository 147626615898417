export const environment = {
  production: true,
  lockSite: false,
  serverUrl: 'https://qa.api.stellar.online/v3/',
  authServerUrl: 'https://qa.api.stellar.online/api/',
  serverV1Url: 'https://qa.api.stellar.online/api/',
  productId: 'c0cf4ddc-dc79-11ed-afa1-0242ac120002',
  stellarProductId: '1ec4a3a3-6223-486c-a264-0a778895b295',
  aliveAppIntervalMinutes: 30, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 60, // if you keep app inactive pop a message
  repeatValidateSeconds: 300,
  hasListingDirectory: false,
  identityName: 'Reg Demo',
  serverName: 'Stellar Registration',
  orgType: 'school',
  orgSubType: 'school',
  subServerName: 'Stellar Registration',
  profileImageURL: '',
  enableTranslation: false,
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',// Admin
    roleView: '5ee76348d3123a0ef0d8cb63',// Viewer
    roleEdit: '5ee761c8d3123a0ef0d8cb61',// Editor
  },
  customKeysView: {
    Admin: 'Admin',
    Student: 'View',
    Teacher: 'Edit',
  },
  ipStack: {
    apiKey: '4e73b76fa09e6cfe386cf11e94c11e90'
  },
  ST_PUB_KEY: "pk_test_51IBPEQKKCurCNdVKUekZI9qcPRYIJ3QySZLS3I3gMHfT07QkXy0GgYy44qFH4hoOHnymulOpuzgoRw5S3XuNj8mW00KUxCtRY5",
  ST_SECRET_KEY: "sk_test_51IBPEQKKCurCNdVK97RhaU5Pfl4ZkIF33BdRKXaYegjwq77ryNiMbcURb4PRqImNqSFyg1IGEhCD63H8IQngMt4400Xg30FllR",
  STELLAR_URL: 'https://qa.stellar.conference.interactivelife.me/',
  mainUrl: 'https://qa-accounts.interactivelife.me',
  PACKAGE:{
    YEARLY: 'PKG-UEtHLTI=',
    MONTHLY: 'PKG-UEtHLTE='
  },
  STRIPE_PRICES: {
    oneTime_fee: 'price_1LA99EKKCurCNdVKaiu0YZsH',
    // full_package: 'price_1LA958KKCurCNdVK5U9IgLiK',
    full_package: 'price_1Ml7nhKKCurCNdVKwO4aFclr',
    // lite_package: 'price_1LA94lKKCurCNdVKrrpOZFzO',
    lite_package: 'price_1LNHUSKKCurCNdVKZLBqB1zG',
    // booth: 'price_1LA9AbKKCurCNdVK6iiVsPjP',
    booth: 'price_1Ml7j6KKCurCNdVK5NaG4C15',
    livestraming: 'price_1LA9F6KKCurCNdVK0khIDYxr',
    // CloudLive_A_1: 'price_1LCkxgKKCurCNdVK3sXcrP6h',
    CloudLive_A_1: 'price_1Ml7k7KKCurCNdVKwAvEnmtR',
    CloudLive_A_2: 'price_1LCkwBKKCurCNdVKTF0dKNpp',
    // CloudLive_B_1: 'price_1LCl41KKCurCNdVK8Cqm7BWX',
    CloudLive_B_1: 'price_1Ml7iXKKCurCNdVKlFEr7YL3',
    CloudLive_B_2: 'price_1LCl41KKCurCNdVKDmTPGPym',
    // CloudLive_C_1: 'price_1LCl6NKKCurCNdVKZqQ4Y3Xh',
    CloudLive_C_1: 'price_1Ml7hrKKCurCNdVKMkbxPsJ0',
    CloudLive_C_2: 'price_1LCl6NKKCurCNdVKjioGBcpL',
    support1: 'price_1Lni5dKKCurCNdVKOHOtHIMW',
    support2: 'price_1Lni76KKCurCNdVKPjafISZ2',
    support3: 'price_1Lni8VKKCurCNdVKy7NdErft',
  },
  loginRoute: '/login',
  registerRoute: '/register',
  NBCA_ORG_ID: '60fe9195569be70af0de6f2c',
  NBCA_MAIN_URL: 'https://qa.nbca.accounts.stellar.online',
  NBCA_DEFAULT_TYPE: 8,
};