<div class="loading-screen-wrapper" *ngIf="showLoader">
  <div class="loading-screen-icon">
    <!-- <svg>
      <g>
        <path d="M 50,100 A 1,1 0 0 1 50,0"/>
      </g>
      <g>
        <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
      </g>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#ff7403;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#393a3e;stop-opacity:1" />
        </linearGradient>
      </defs>
    </svg> -->
    <div class="app-loading">
      <div class="logo"></div>
      <svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
    </div>
    <!-- <mat-progress-spinner mode="indeterminate"></mat-progress-spinner> -->
  </div>
</div>
