<div class="col-xl-12">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
		<div class="userInfo select-page-body">
      <mat-card>
				<mat-card-content>
					<div fxLayout="row" fxLayout.lt-md="column" fxFlex >
				    <div fxFlex class="bodybg">
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex >
						    <div fxFlex>
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex >
								    <div fxFlex>
											<h2 style="margin:0px 0px 10px">{{data.targetDataTypeDisplay}} List</h2>
										</div>
									</div>
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex >
										<div fxFlex>
											<mat-form-field [hidden]="!(canSearch)" >
							  					<input matInput #searchInput placeholder="{{'Search' | translate}}" [value]="searchVal">
							  			</mat-form-field>
										</div>
									</div>
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex >
								    <div fxFlex class="optionData.length > 0">
											<div
										    cdkDropList
										    #todoList="cdkDropList"
										    [cdkDropListData]="optionData"
										    [cdkDropListConnectedTo]="[doneList]"
										    class="example-list"
										    (cdkDropListDropped)="drop($event)">
												<div style="min-height:200px">
										    	<div class="example-box" *ngFor="let usr of optionData" cdkDrag>{{usr.text}}<span *ngIf="usr.email"> ({{usr.email}})</span></div>
												</div>

												<mat-paginator class="paginator" [pageIndex]="pageNumber-1" [hidden]="!pagination" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25]" [length]="paginatorTotal | async" [showFirstLastButtons]="true"></mat-paginator>
										  </div>
										</div>
									</div>
								</div>
							</div>
						</div>
				    <div fxFlex class="bodybg">
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex >
						    <div fxFlex >
									<h2 style="margin:0px 0px 10px">Selected {{data.targetDataTypeDisplay}}</h2>
								</div>
							</div>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex >
						    <div fxFlex class="theDataSelected">
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex >
								    <div fxFlex >
											<div
										    cdkDropList
										    #doneList="cdkDropList"
										    [cdkDropListData]="selectedData"
										    [cdkDropListConnectedTo]="[todoList]"
										    class="example-list"
										    (cdkDropListDropped)="drop($event)">
										    	<div class="example-box" *ngFor="let usr of selectedData;let index = index" cdkDrag>
														{{usr.text}}<span *ngIf="usr.email"> ({{usr.email}})</span>
														<span *ngIf="data.showHost" class="showHost">
															<mat-icon *ngIf="usr.host === false" (click)="setHost(index, true)">toggle_off</mat-icon>
															<mat-icon *ngIf="usr.host === true" (click)="setHost(index, false)">toggle_on</mat-icon>
															<div>Host</div>
														</span>
													</div>
										  </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-card-content>
      </mat-card>
    </div>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal()">{{'Close' | translate}}</button>
			<button mat-raised-button (click)="saveData()">{{'Save' | translate}}</button>
			<button mat-raised-button (click)="goToUserPage()">{{'Manage Users' | translate}}</button>
			<!-- cdkFocusInitial -->
	</div>
	<br/>
</div>
