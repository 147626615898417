<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="settingObject" >
    <div fxFlex cdkDropListGroup>
      <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
        <div fxFlex>
          <div class="overlayLayout example-boundary" #mainBoundary (click)="selectComponent($event, undefined, undefined)">
            <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0VW" class="bgComponent">
              <div fxFlex *ngFor="let col of settingObject['columns'];let index = index"  class="example-list" [class.highZIndex] = "selectedColumn === index">
                  <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
                    <div fxFlex
                    id="rowsmenu{{index}}" name="rowsmenu" class="example-box-row" >
                      <div class="example-box" *ngFor="let cmp of col['components'];let subIndex = index" cdkDrag cdkDragBoundary=".example-boundary" [cdkDragFreeDragPosition]="{x: getX(cmp.x, mainBoundary), y: getY(cmp.y, mainBoundary)}" (cdkDragEnded)="dragEnd($event, mainBoundary, cmp, index, subIndex)" (cdkDragMoved)="dragMoved($event, mainBoundary, cmp)" (cdkDragStarted)="dragStarted($event, index, subIndex)" (cdkDragEntered)="dragEntered($event, index)"
                      mwlResizable [enableGhostResize]="true" [resizeCursorPrecision]="15" [resizeEdges]="componentsName[cmp.name].rules.resizeEdges" (resizeStart)="selectComponent(undefined, index, subIndex)" (resizing)="applyResizing($event, mainBoundary, cmp)" [resizeRatio]="componentsName[cmp.name].rules.ratioSize" (resizeEnd)="onResizeEnd($event, mainBoundary, cmp, index, subIndex)"  [ngStyle]="{'z-index': getZ(cmp.z, index, subIndex), 'height' : getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize), 'width' : cmp.w+'vw', 'backgroundColor': cmp.bgcolor}" (click)="selectComponent($event, index, subIndex)" [class.selectedComponent]="selectedColumn === index && selectedComponent === subIndex" [class.cameraComponent]="cameraComponents.includes(cmp.name)" [class.editorClass]="cmp.name === 'editor' || cmp.name === 'empty-click' || cmp.name === 'picturewall' || cmp['name'] === 'audienceView'" [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'" [cdkDragDisabled]="cmp.name === 'empty-click' && enableEditor">
                        <div class="example-box-handle" cdkDragHandle [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'" [class.pointerCursor]="cmp.name === 'empty-click' && enableEditor">
                          <div class="contentDataActions" [class.contentDataActions-top]="((cmp.y * mainBoundary.offsetWidth)/100) < 20">
                            <!-- <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'editor'" (click)="cmp['preview'] = !(cmp['preview'])" matTooltip="{{'Edit' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon *ngIf="cmp['preview']">preview</mat-icon>
                              <mat-icon *ngIf="!cmp['preview']">edit</mat-icon>
                            </button> -->

                            <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'editor'" (click)="enableEditor = !enableEditor" matTooltip="{{'Editor' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>edit</mat-icon>
                            </button>

                            <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'picturewall'" (click)="enableGrid = !enableGrid" matTooltip="Show {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>edit</mat-icon>
                            </button>

                            <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'audienceView'" (click)="enableAudienceGrid = !enableAudienceGrid" matTooltip="Show {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>edit</mat-icon>
                            </button>

                            <button mat-icon-button color="primary" *ngIf="tileComponents.includes(cmp.name)" (click)="editTile($event, index, subIndex)" matTooltip="Select {{'Tile' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>select_all</mat-icon>
                            </button>

                            <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'picture'" (click)="editPictureSetting($event, index, subIndex)" matTooltip="Edit {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>edit</mat-icon>
                            </button>

                            <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'empty-click' && cmp.shape === 'poly'" (click)="editEmptyShape($event, index, subIndex)" matTooltip="Edit {{'Shape' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon *ngIf="enableEditor">layers</mat-icon>
                              <mat-icon *ngIf="!enableEditor">layers_clear</mat-icon>
                              <!-- <mat-icon>format_shapes</mat-icon> -->
                            </button>

                            <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'empty-click'" (click)="editEmptySetting($event, index, subIndex)" matTooltip="Edit {{'Settings' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>edit</mat-icon>
                            </button>


                            <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'picture'" (click)="editImage($event, index, subIndex)" matTooltip="Select {{'image' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>image</mat-icon>
                            </button>

                            <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'picturewall'" (click)="editPictureWallImages($event)" matTooltip="Select {{'image' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>image</mat-icon>
                            </button>

                            <!-- <span *ngIf="cmp['name'] === 'guest-camera' && cmp.metadata.name !== ''">{{cmp.metadata.name}}</span> -->
                            <button mat-icon-button color="primary"  *ngIf="cmp['name'] === 'guest-camera'" (click)="editGuest($event, index, subIndex)" [class.selectedUser]="cmp.metadata.name !== ''" matTooltip="Select {{'Guest' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>supervisor_account</mat-icon>
                            </button>
                            <!-- <span *ngIf="cmp['name'] === 'billboard' && cmp.metadata.name !== ''">{{cmp.metadata.name}}</span> -->
                            <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'billboard'" (click)="editBillboard($event, index, subIndex)" matTooltip="{{'Select Billboard' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>select_all</mat-icon>
                            </button>
                            <button  mat-icon-button color="primary" (click)="editActive($event, index, subIndex, !cmp['active'])" matTooltip="{{!cmp['active'] ? 'Activate' : 'Deactivate'}}" matTooltipClass="tooltip-red">
                              <mat-icon *ngIf="!cmp['active']">visibility_off</mat-icon>
                              <mat-icon *ngIf="cmp['active']">visibility</mat-icon>
                            </button>
                            <button *ngIf="type !== 'desktop-hls'" mat-icon-button color="primary" (click)="removeCmp($event, index, subIndex)" matTooltip="{{'Delete' | translate}}" matTooltipClass="tooltip-red">
          									  <mat-icon>delete_forever</mat-icon>
          									</button>
                          </div>
                          <!--<div class="contentDataTitle">
                            <div>{{componentsName[cmp.name]['displayName']}}</div>
                             <div *ngIf="!(selectedColumn === index && selectedComponent === subIndex) ">x: {{cmp.x}}, y: {{cmp.y}}</div>
                            <div *ngIf="selectedColumn === index && selectedComponent === subIndex ">x: {{liveXY.x}}, y: {{liveXY.y}}</div>
                          </div>-->
                          <!-- <div class="contentDataBG" *ngIf="cameraComponents.includes(cmp.name)">
                            <mat-icon>videocam</mat-icon>
                          </div> -->
                          <div class="contentRealData" [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'" >
                            <div class="contentDataMain" *ngIf="!((cmp['name'] === 'editor' || cmp['name'] === 'billboard' || cmp['name'] === 'picturewall' || cmp['name'] === 'audienceView' || cmp['name'] === 'picture'  || cmp['name'] === 'empty-click' || tileComponents.includes(cmp.name))) || (cmp['name'] === 'picturewall' && enableGrid) || (cmp['name'] === 'audienceView' && enableAudienceGrid)">
                              <div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
                                <mat-form-field style="width: 100%;">
                                  <mat-label>Type</mat-label>
                                  <mat-select (selectionChange)="editAttribute(undefined, index, subIndex, 'type', $event.value)" [value]="cmp['type']" style="color: rgba(0,0,0,.54);
                                  margin-top: 8px;" >
                                    <mat-option value="" disabled>Select Type</mat-option>
                                    <mat-option *ngFor="let vidType of videoComponentTypes" [value]="vidType.value" >{{vidType.displayName | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                              </div>
                              <div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput placeholder="{{ 'Video Url' | translate }}" [value]="cmp['url']" (change)="editAttribute($event, index, subIndex, 'url', $event.target.value)">
                                </mat-form-field>
                                <!-- <label for="url{{subIndex}}">{{'Video Url' | translate}}:</label>
                                <input name="url{{subIndex}}" placeholder="" [value]="cmp['url']" (change)="cmp['url']=$event.target.value"> -->
                              </div>
                              <!-- <div class="contentData" *ngIf="cmp['name'] === 'presenter-camera'">
                                <mat-icon *ngIf="!cmp['viewPublicChat']" (click)="cmp['viewPublicChat']=true" title="{{'View public chat' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
                                <mat-icon *ngIf="cmp['viewPublicChat']" (click)="cmp['viewPublicChat']=false" title="{{'View public chat' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
                                <label style="width: 13vw" for="viewPublicChat{{subIndex}}"> {{'View public chat' | translate}}</label>
                              </div> -->
                              <div class="contentData" *ngIf="cmp['name'] === 'camera'">
                                <mat-icon *ngIf="!cmp['enableAllowAllSpeak'] && !hasComponent('audienceView')" (click)="changeRowAllowAllSpeak($event, true, index, subIndex )" title="{{'All attendees can speak at the same time' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
                                <mat-icon *ngIf="cmp['enableAllowAllSpeak'] && !hasComponent('audienceView')" (click)="changeRowAllowAllSpeak($event, false, index, subIndex )" title="{{'All attendees can speak at the same time' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
                                <mat-icon *ngIf="hasComponent('audienceView')" title="{{'This option cannot be enabled together with the audience view' | translate}}" style="height: 30px;vertical-align: middle;">browser_not_supported</mat-icon>
                                <label for="enableAllowAllSpeak{{subIndex}}" style="width: 13vw;">{{'All attendees can speak at the same time' | translate}}</label>
                              </div>
                              <div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput type="number" min="0" placeholder="{{ 'Border Thickness' | translate }}" [(value)]="cmp['frame']" (change)="editNumericAttribute($event, index, subIndex, 'frame', $event.target.value, 0, 100)">
                                </mat-form-field>
                              </div>
                              <div class="contentData" *ngIf="cmp['name'] === 'screenshare'">
                                <mat-form-field type="text" style="width: 100%;">
                                    <input matInput placeholder="{{ 'Message' | translate }}" [value]="cmp['message']" (change)="editAttribute($event, index, subIndex, 'message', $event.target.value)">
                                </mat-form-field>
                              </div>

                              <!-- <div class="contentData" *ngIf="cmp['name'] === 'audienceView'">
                                <mat-form-field type="text" style="width: 100%;">
                                    <input matInput placeholder="{{ 'Message' | translate }}" [value]="cmp['message']" (change)="editAttribute($event, index, subIndex, 'message', $event.target.value)">
                                </mat-form-field>
                              </div> -->


                              <div class="contentData" *ngIf="cmp['name'] === 'presenter-camera' && type === 'desktop-hls'">
                                <mat-form-field style="width: 100%;">
                                  <mat-label>Orientation</mat-label>
                                  <mat-select (selectionChange)="editAttribute(undefined, index, subIndex, 'layout', $event.value)" [value]="cmp['layout']" style="color: rgba(0,0,0,.54);
                                  margin-top: 8px;" >
                                    <mat-option value="" disabled>Select Orientation</mat-option>
                                    <mat-option [value]="'bestFit'" >Best fit</mat-option>
                                    <mat-option [value]="'verticalPresentation'" >Vertical presentation</mat-option>
                                    <mat-option [value]="'horizontalPresentation'" >Horizontal presentation</mat-option>
                                    <mat-option [value]="'pip'" [disabled]="!((settingObject['optionSettings']['addScreenShare'] && guestsTotal === 0) || (!settingObject['optionSettings']['addScreenShare'] && guestsTotal === 1))">Picture in picture</mat-option>
                                    </mat-select>
                                </mat-form-field>
                              </div>
                              <!-- <div class="contentData" *ngIf="cmp['name'] === 'picturewall'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput type="number" min="0" placeholder="{{ 'Time delay' | translate }}" [(value)]="cmp['timer']" (change)="editNumericAttribute($event, index, subIndex, 'timer', $event.target.value, 3, 100000)">
                                </mat-form-field>
                              </div> -->
                              <div class="contentData" *ngIf="cmp['name'] === 'audienceView'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput type="number" min="0" placeholder="{{ 'Number of Rows' | translate }}" [(value)]="cmp['gridSizeY']" (change)="editNumericAttribute($event, index, subIndex, 'gridSizeY', $event.target.value, 1, 50)">
                                </mat-form-field>
                              </div>
                              <div class="contentData" *ngIf="cmp['name'] === 'audienceView'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput type="number" min="0" placeholder="{{ 'Number of Columns' | translate }}" [(value)]="cmp['gridSizeX']" (change)="editNumericAttribute($event, index, subIndex, 'gridSizeX', $event.target.value, 1, 50)">
                                </mat-form-field>
                              </div>
                              <div class="contentData" *ngIf="cmp['name'] === 'audienceView'">
                                <label *ngIf="cmp['gridSizeX'] * cmp['gridSizeY'] > 50" style="width: 100%;color: red;"> {{'The maximum grid size is 50' | translate}}</label>
                              </div>
                              <div class="contentData" *ngIf="cmp['name'] === 'picturewall'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput type="number" min="0" placeholder="{{ 'Number of Rows' | translate }}" [(value)]="cmp['gridSizeY']" (change)="editNumericAttribute($event, index, subIndex, 'gridSizeY', $event.target.value, 1, 20)">
                                </mat-form-field>
                              </div>
                              <div class="contentData" *ngIf="cmp['name'] === 'picturewall'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput type="number" min="0" placeholder="{{ 'Number of Columns' | translate }}" [(value)]="cmp['gridSizeX']" (change)="editNumericAttribute($event, index, subIndex, 'gridSizeX', $event.target.value, 1, 20)">
                                </mat-form-field>
                              </div>
                              <!-- <div class="contentData" *ngIf="cmp['name'] === 'picturewall'">
                                <mat-icon *ngIf="!cmp['moderated']" (click)="editAttribute($event, index, subIndex, 'moderated', true)" title="{{'Moderated' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
                                <mat-icon *ngIf="cmp['moderated']" (click)="editAttribute($event, index, subIndex, 'moderated', false)" title="{{'Moderated' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
                                <label for="moderated{{subIndex}}"> {{'Moderated' | translate}}</label>
                              </div> -->
                              <div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
                                <mat-icon *ngIf="!cmp['autoStart']" (click)="editAttribute($event, index, subIndex, 'autoStart', true)" title="{{'Auto Start' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
                                <mat-icon *ngIf="cmp['autoStart']" (click)="editAttribute($event, index, subIndex, 'autoStart', false)" title="{{'Auto Start' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
                                <label for="autoStart{{subIndex}}"> {{'Auto Start' | translate}}</label>
                              </div>
                            </div>
                            <ng-container *ngIf="tileComponents.includes(cmp.name) && cmp.metadata.id !== ''">
                                <app-iframe-url [iframeLink]="getTileLink(cmp.metadata.id)" [iframeParentHeight]="getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize)" [forEdit]="true"></app-iframe-url>
                            </ng-container>
                            <ng-container *ngIf="cmp['name'] === 'billboard' && cmp.metadata.id !== ''">
                              <app-slider [forEdit]="true" [bannerId]="cmp.metadata.id">
                              </app-slider>
                            </ng-container>
                            <ng-container *ngIf="cmp['name'] === 'picture'">
                              <img *ngIf="cmp.image" src="{{cmp.image}}" style="width:100%;height:100%">
                            </ng-container>
                            <ng-container *ngIf="cmp['name'] === 'empty-click' && cmp.shape === 'poly'">
                              <div style="height:100%;width:100%" (click)="addPoint($event, mainBoundary, index, subIndex)" >
                                <svg height="100%" width="100%" *ngIf="cmp.hasOwnProperty('svg')" >
                                  <polygon *ngIf="cmp.shape === 'poly'" [attr.points]="getShapePoint(mainBoundary, index, subIndex)" style="fill: rgb(102, 102, 102);stroke: rgb(51, 51, 51);stroke-width:1;opacity: 0.6; " [class.hasPointer]="selectedColumn === index && selectedComponent === subIndex && enableEditor">
                                    <title>{{cmp.title}}</title>
                                </polygon>
                                  <ng-container *ngIf="selectedColumn === index && selectedComponent === subIndex && enableEditor">
                                  <circle *ngFor="let shp of cmp.svg;let circleindex = index" [attr.cx]="getPolyX(shp['x'], mainBoundary, index, subIndex)" [attr.cy]="getPolyY(shp['y'], mainBoundary, index, subIndex)" r="5" class="image-mapper-point" data-area-index="0" data-coord-index="0" style="fill: rgb(255, 255, 255); stroke: rgb(51, 51, 51); stroke-width: 1; opacity: 0.6; cursor: pointer;" (click)="onCircleClick($event, index, subIndex, circleindex)" cdkDrag
                                    (cdkDragEnded)="dragPolyEnd($event, mainBoundary, cmp, index, subIndex, circleindex)" (cdkDragStarted)="dragPolyStarted($event, mainBoundary, index, subIndex, circleindex)"
                                    (cdkDragMoved)="dragMoved($event, mainBoundary, cmp, index, subIndex, circleindex)"
                                    ></circle>
                                  </ng-container>
                                </svg>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="cmp['name'] === 'picturewall'  && !enableGrid && cmp.gridSizeX !== '' && cmp.gridSizeX !== undefined && cmp.gridSizeY !== '' && cmp.gridSizeY !== undefined">
                              <mat-grid-list cols="{{cmp.gridSizeX}}" rowHeight="1:1" [gutterSize]="'5px'" >
                                <!-- style="width:calc(100% - {{5*(cmp.gridSizeX-1)}}px)" style="background-color:rgb(255, 255, 255,0.3);"-->
                                <mat-grid-tile *ngFor="let row of numberReturn(cmp.gridSizeY*cmp.gridSizeX);let index = index" style="background-color:rgb(102, 102, 102,0.6);">
                                </mat-grid-tile>
                                <!-- <mat-grid-tile *ngFor="let row of numberReturn(cmp.gridSizeY*cmp.gridSizeX);let index = index"  [ngStyle]="{ 'border-right': (index+1)%cmp.gridSizeX ==0 ? '0px solid black' : '5px solid black' , 'border-bottom':(index+1) > (cmp.gridSizeX * (cmp.gridSizeY-1))  ? '0px solid black' : '5px solid black' }">
                                </mat-grid-tile> -->
                              </mat-grid-list>
                            </ng-container>
                            <ng-container *ngIf="cmp['name'] === 'audienceView' && !enableAudienceGrid && cmp.gridSizeX !== '' && cmp.gridSizeX !== undefined && cmp.gridSizeY !== '' && cmp.gridSizeY !== undefined">
                              <!-- <mat-grid-list cols="{{cmp.gridSizeX}}" rowHeight="1:1" [gutterSize]="'5px'" style="background-color:rgb(255, 255, 255,0.3);">
                                <mat-grid-tile *ngFor="let row of numberReturn(cmp.gridSizeY*cmp.gridSizeX);let index = index" style="background-color:rgb(102, 102, 102,0.6);">
                                </mat-grid-tile>
                              </mat-grid-list> -->
                              <div class="grid-container">
                                <div class="camera-container"
                                    [style.grid-template-columns]="'repeat(' + cmp.gridSizeX + ', minmax(8vw, 0.5fr))'"
                                    [style.grid-template-rows]="'repeat(' + cmp.gridSizeY + ', ' + (cmp.h / +cmp.gridSizeY) - (0.5 * (cmp.gridSizeY - 1)) + 'vw' + ')'">
                                    <ng-container
                                        *ngFor="let row of numberReturn(cmp.gridSizeY*cmp.gridSizeX); let idx = index">
                                        <div id="cam-{{idx}}" class="flex-class" >
                                          <div class="aspect-container default-back" style="background-color:rgb(102, 102, 102,0.6);">
                                          </div>
                                        </div>
                                    </ng-container>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="cmp['name'] === 'editor'">
                              <div [innerHTML]="cmp.data | safeHtml"></div>
                              <div *ngIf="enableEditor && selectedColumn === index && selectedComponent === subIndex" class="editorPop" [class.editorPop-left]="((cmp.x * mainBoundary.offsetWidth)/100) > (mainBoundary.offsetWidth/3)" [class.editorPop-bottom]="((cmp.y * mainBoundary.offsetWidth)/100 + (cmp.h * mainBoundary.offsetWidth)/100) > mainBoundary.offsetHeight/2">
                                <ckeditor-block fxFlex [value]="cmp.data" [config]="editorConfig" (textView)="editEditor(index, subIndex, 'data', $event)">
                                </ckeditor-block>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div class="resize-action"></div>
                        <div class="layout" data-aid="edit-box-layout" >
                          <span class="component-labels" [class.component-labels-top]="((cmp.y * mainBoundary.offsetWidth)/100) < 20">
                            <span data-aid="display-name-label" class="label-comp-type">{{componentsName[cmp.name]['displayName'] | translate}}</span>
                          </span>
                          <span *ngIf="!(cmp['name'] === 'empty-click' && cmp.shape === 'poly')">
                          <span data-direction-name="topLeft" class="topLeft"></span>
                          <span data-direction-name="top" class="top"></span>
                          <span data-direction-name="topRight" class="topRight"></span>
                          <span data-direction-name="right" class="right"></span>
                          <span data-direction-name="bottomRight" class="bottomRight"></span>
                          <span data-direction-name="bottom" class="bottom"></span>
                          <span data-direction-name="bottomLeft" class="bottomLeft">
                          </span>
                          <span data-direction-name="left" class="left">
                          </span>
                          </span>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
