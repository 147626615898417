<div class='ili-panel linkDescription_panel'>
  <mat-form-field>
    <input matInput [(ngModel)]="block.data.name" class="form-control input-sm" type="text" placeholder="{{'Name' | translate}}">
  </mat-form-field>
  <mat-form-field>
    <input matInput [(ngModel)]="block.data.description" class="form-control input-sm" type="text" placeholder="{{'Description' | translate}}">
  </mat-form-field>
  <mat-form-field>
    <input matInput [(ngModel)]="block.data.link" class="form-control input-sm" type="text" placeholder="{{'Link' | translate}}">
  </mat-form-field>
  <mat-checkbox value="true" [checked]="block.data.openLink" [(ngModel)]="block.data.openLink" (change)="onChange($event)" title="Dpen Link">{{'Open Link' | translate}}</mat-checkbox>
</div>
