<div class='ili-panel exclusive_url_panel'>
  <div class="row contents_input_account">
    <div class="input-group input-group-sm">
      <mat-form-field>
        <input matInput [(ngModel)]="block.data.url" class="form-control input-sm" type="text" placeholder="Enter the url">
      </mat-form-field>
    </div>
  </div>
  <!-- <div class="row contents_input_account">
    <div class="input-group input-group-sm">
      <mat-checkbox value="true" (change)="checkUrlPriority($event, 'window')" [checked]="block.data.window" [(ngModel)]="block.data.window">Open in new window for all devices</mat-checkbox>
    </div>
  </div>
  <div class="row contents_input_account">
    <div class="input-group input-group-sm">
      <mat-checkbox value="true" (change)="checkUrlPriority($event, 'iphonewindow')" [checked]="block.data.iphonewindow" [(ngModel)]="block.data.iphonewindow">Open in new window for iOS devices</mat-checkbox>
    </div>
  </div> -->
</div>
