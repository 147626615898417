import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { isValidHttpUrl } from '../../../../shared/helpers';
import { CustomSelectDialogComponent } from '../../../../shared/components/custom-select-dialog/custom-select-dialog.component';
import { TileSelectDialogComponent } from '../../../../shared/components/tile-select-dialog/tile-select-dialog.component';

interface ViewDialogData {
	title: string;
	data: any;
	confirmData: any;
	hasShape: boolean;
}

@Component({
	selector: 'app-picture-dialog-modal',
	templateUrl: './picture-dialog.component.html',
	styleUrls: ['./picture-dialog.component.scss']
})
export class PictureDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public hasShape: boolean = false;
	public returnData: any = {type: '', link: '', linkDisplay: '', title: ''};
	constructor(
		private requestService: RequestService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<PictureDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
	  //console.log('ViewDialogData', data);
		if(data){
			this.hasShape = data.hasShape || false;
			if(data.data){
				this.returnData = data.data;
			}else if (this.hasShape){
				this.returnData['shape'] = 'square';
			}
		}
	}

	ngOnInit() {
	}

  public setPictureArrayType(val) {
    this.returnData['type'] = val;
    this.returnData['link'] = '';
    this.returnData['linkDisplay'] = '';
  }
	closeModal(data): void {
		if(data){
			if(!data.link || data.link === ''){
				if(data.type === 'link' || data.type === 'page'){
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in the link to open'), this.translate.instant('Dismiss'));
				}else{
					this.layoutUtilsService.showNotification(this.translate.instant('Select the ' + data['type'] + ' to open'), this.translate.instant('Dismiss'));
				}
				return;
			}
			if(data.type === 'link'){
				if(!isValidHttpUrl(data.link)){
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in correct link format'), this.translate.instant('Dismiss'));
					return;
				}
			}
		}
		this.dialogRef.close(data);
	}
	public selectPictureLinkType($event, type) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    if (type === 'tile') {
      this.selectTile();
    } else if (type === 'room') {
      this.editCustomSelectDialog('room', 'Room');
    }
  }
  selectTile() {
    const dialogRef = this.dialog.open(TileSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Tile'),
        data: {},
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
				// console.log('selectTile', result);
        if (result.hasOwnProperty('_id')) {
          this.returnData['link'] = result['_id'];
          this.returnData['linkDisplay'] = result['title'];
        } else {
          this.returnData['link'] = '';
          this.returnData['linkDisplay'] = '';
        }
      }
    });
  }
  editCustomSelectDialog(dataType, dataTypeTitle) {
    let filterData: any = {'$and': [
      {'organizationId._id': { '$eq': this.requestService.orgId }}
    ]};
    const dialogRef = this.dialog.open(CustomSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Room'),
        dataType: dataType,
        dataTypeTitle: dataTypeTitle,
        filters: filterData,
        data: this.returnData['link'],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
				// console.log('editCustomSelectDialog', result);
        if (result.hasOwnProperty('_id')) {
          this.returnData['link'] = result['_id'];
          this.returnData['linkDisplay'] = result['text'];
        } else {
          this.returnData['link'] = '';
          this.returnData['linkDisplay'] = '';
        }
      }
    });
  }
}
