<mat-tree #tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <!-- use a disabled button to provide padding for tree leaf -->
    <button mat-icon-button disabled></button>
    <span *ngIf="node.dataType === 'string'">
      {{node.title}}
    </span>
    <span class="imageShow" *ngIf="node.dataType === 'arrayimage'">
      <img *ngFor="let im of node.title" src="{{im}}" >
    </span>
    <span class="imageShow" *ngIf="node.dataType === 'image'">
      <img [src]="node.title" >
    </span>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.title">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    {{node.title}}
  </mat-tree-node>
</mat-tree>
