<mat-card class="example-card" [class.grey-out]="darkMode" style="height: 100%;padding: 0px 10px;min-height: 463px">
  <mat-card-content style="margin-bottom: 0px;">
    <app-custom-select-create-autocomplete [value]="selectedCategory" [dataType]="'category'"
      [dataTypeDisplay]="'Category'" [placeholder]="''" [useOrg]="true" [canDelete]="false"
      (onSelectReturn)="selectCategory($event)" [type]="'tile'" [onBlur]="false" [addAll]="true">
    </app-custom-select-create-autocomplete>
    <mat-form-field>
      <input matInput #searchInput placeholder="{{'Search' | translate}}" [value]="searchVal">
    </mat-form-field>
    <!-- <mat-divider></mat-divider> -->

    <button mat-icon-button (click)="assignTiles()" class="addTileBtn" *ngIf="!disableAdd">
      <mat-icon>add_circle</mat-icon>
    </button>
    <mat-nav-list class="tileListCss">
      <mat-list-item *ngFor="let data of dataList" (click)="selectItemCheck(data)"
        [class.selectedData]="selectedData && selectedData.hasOwnProperty('_id') && selectedData._id === data._id">
        <!-- <img *ngIf="data.art !== '/img/tile_default.jpg'" src="{{ data.art }}" class="tileArt" mat-list-icon/>
         <img *ngIf="data.art === '/img/tile_default.jpg'" src="/assets/{{ data.art }}" class="tileArt" mat-list-icon/> -->
        <a class="tileName" matLine href="Javascript:void(0)">
          <mat-icon *ngIf="data.done" class="checkIcon defaultColor" (click)="setToUnDone($event, data._id)">checked
          </mat-icon>
          {{ data.title }}
        </a>
        <button *ngIf="(pushedTile && pushedTile === data._id) && !disablePush" mat-icon-button
          (click)="pushToStudent($event, data._id)">
          <mat-icon class="defaultColor" style="color: lime;">radio_button_checked</mat-icon>
        </button>
        <button
          *ngIf="(selectedData && selectedData.hasOwnProperty('_id') && selectedData._id === data._id) && !((pushedTile && pushedTile === data._id)  || data.active) && !disablePush"
          mat-icon-button (click)="pushToStudent($event, data._id)">
          <mat-icon class="defaultColor">radio_button_unchecked</mat-icon>
        </button>
        <!-- <mat-icon>arrow_forward_ios</mat-icon> -->
      </mat-list-item>
    </mat-nav-list>
  </mat-card-content>
  <mat-card-actions style="margin:0">
    <mat-paginator class="paginateIt" [hidePageSize]="true" [pageSize]="pageSize" [length]="paginatorTotal | async"
      [showFirstLastButtons]="true"></mat-paginator>
  </mat-card-actions>
</mat-card>
