import { environment } from '../environments/environment';

export class MenuConfig {
  public clientMenus: any = {
    aside: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          icon: 'assessment',
          page: '/dashboard',
          permission: [environment.customKeys.roleView],
        },
        {
          title: 'Dashboard',
          root: true,
          icon: 'assessment',
          page: '/reseller/dashboard',
          permission: [environment.customKeys.roleAdmin,environment.customKeys.roleEdit],
        },
        {
          title: 'Clients',
          root: true,
          bullet: 'dot',
          icon: 'supervisor_account',
          page: '/admin/clients'
        },
        {
          title: 'Resellers',
          root: true,
          bullet: 'dot',
          icon: 'supervisor_account',
          page: '/admin/resellers',
          permission: [environment.customKeys.roleAdmin],
        },
        {
          title: 'Subscriptions',
          root: true,
          icon: 'date_range',
          page: '/admin/subscriptions'
        },
      ]
    }
  };

  public get clientConfigs(): any{
    return this.clientMenus;
  }
}
