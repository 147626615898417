export class DefaultSettings {
  public static componentsType: any = {
    desktop: ['empty-click', 'camera', 'guest-camera', 'defaultvideo', 'screenshare', 'presenter-camera', 'billboard', 'editor', 'picture', 'tile', 'picturewall', 'audienceView'],
    'desktop-offline': ['empty-click', 'defaultvideo', 'billboard', 'editor', 'picture', 'tile', 'picturewall'],
    'mobile-offline': ['empty-click', 'defaultvideo', 'billboard', 'editor', 'picture', 'tile', 'picturewall'],
    'desktop-hls': ['presenter-camera'],
    'mobile-hls': ['presenter-camera'],
    mobile: ['camera', 'guest-camera', 'empty-click', 'defaultvideo', 'screenshare', 'presenter-camera', 'billboard', 'editor', 'picture', 'tile', 'picturewall']
  };
  public static componentsRoomType: any = {
    offline: ['empty-click']
  };
  public static peopleType: any = {
    lead: 'Lead',
    guest: 'Guest',
    attendee: 'Attendee',
    moderator: 'Moderator',
  }
  public static peopleTypeKeys: string[] = Object.keys(DefaultSettings.peopleType);
  public static meetingMode: string[] = ['meeting', 'largemeeting'];
  public static cameraComponents: string[] = ['camera', 'guest-camera', 'screenshare', 'presenter-camera'];
  public static emptyComponents: string[] = ['empty-click'];
  public static advancedComponents: string[] = ['picturewall', 'audienceView'];
  public static videoComponents: string[] = ['defaultvideo'];
  public static videoComponentTypes: any[] = [{value: 'youtube', displayName: 'Youtube'}, {value: 'vimeo', displayName: 'Vimeo'}, { value:  'rtmp', displayName: 'RTMP'}, {value: 'hls', displayName: 'HLS'}];
  public static tileComponents: string[] = ['tile','questionnaire','inquiry','linkDescription'];
  public static defaultSetting = {
    version : 3,
    desktop : {
      columns: [
        {
          components: [
            {
              active: true,
              h: 14,
              name: "presenter-camera",
              viewPublicChat: true,
              w: 25,
              x: 75,
              y: 4,
              z: 1
            },
            {
              active: true,
              enableAllowAllSpeak: true,
              h: 14,
              name: "camera",
              w: 25,
              x: 0,
              y: 4,
              z: 1
            },
            {
              active: true,
              h: 22,
              message: "",
              name: "screenshare",
              w: 40,
              x: 30,
              y: 4,
              z: 1
            }
          ]
        }
      ],
      optionSettings: {
        enableScreenShareButton: false,
        enableFullScreen: false,
        enableCaptureScreen: false,
        enableRaiseHand: false,
        enableChat: false,
        enableAskQuestions: false,
        numberOfAttendees: 0
      }
    },
    mobile: {
      columns: [
        {
          rowGap: 0,
          components: []
        }
      ],
      optionSettings: {
        enableScreenShareButton: false,
        enableFullScreen: false,
        enableCaptureScreen: false,
        enableRaiseHand: false,
        enableChat: false,
        enableAskQuestions: false
      }
    },
    'desktop-offline' : {
      columns: [
        {
          components: []
        }
      ],
    },
    'mobile-offline' : {
      columns: [
        {
          components: []
        }
      ],
    },
    'desktop-hls': {
      columns: [
        {
          components: [
            {
              active: true,
              layout: "bestFit", //bestFit, pip, verticalPresentation, horizontalPresentation
              h: 22,
              name: "presenter-camera",
              w: 40,
              x: 30,
              y: 1,
              z: 1
            }
          ]
        }
      ],
      optionSettings: {
        // enableFullScreen: false,
        // enableChat: false,
        // enableAskQuestions: false,
        addScreenShare: false
      }
    },
    'mobile-hls': {
      columns: [
        {
          components: [
            {
              active: true,
              layout: "bestFit",//bestFit, pip, verticalPresentation, horizontalPresentation
              name: "presenter-camera"
            }
          ]
        }
      ],
      optionSettings: {
        // enableFullScreen: false,
        // enableChat: false,
        // enableAskQuestions: false,
        addScreenShare: false
      }
    }
  };
  public static defaultRoomSetting = {
    version : 1,
    'room-offline' : {
      columns: [
        {
          components: [
          ]
        }
      ]
    }
  };
}
