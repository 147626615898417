<mat-toolbar color="primary" class="fix-nav" *ngIf="selectedUser">
    <ng-template [ngxPermissionsOnly]="permission">
        <button type="button" mat-icon-button class="visible-sm" (click)="toggleSidebar()" exceptclick>
            <mat-icon aria-label="Side nav toggle icon" exceptclick>menu</mat-icon>
        </button>
        <button type="button" mat-icon-button class="hidden-sm" (click)="toggleSidebar()" exceptclick>
            <mat-icon aria-label="Side nav toggle icon" exceptclick>vertical_split</mat-icon>
        </button>
    </ng-template>
    <div class="nav-brand" title="{{subTitle}}">
        <!-- {{title}} -->
        <a href="javascript:void()" (click)="goHome()">
            <img src="assets/vertical/{{orgType}}/images/top-logo-min.png"
                class="circle z-depth-2 responsive-img activator" alt="{{title | translate}}">
        </a>
        <!-- <span style="letter-spacing: 2px;" class="hidden-sm">{{title}}</span> -->
    </div>
    <!-- <form class="hidden-sm" style="margin-left: 20px; margin-top: 5px">
        <mat-form-field>
            <input matInput [placeholder]="'Search' | translate">
        </mat-form-field>
    </form> -->
    <!-- <span class="nav-spacer"></span> -->
    <!-- <a href="https://github.com/start-javascript/sb-admin-material/archive/master.zip" class="hidden-sm" mat-raised-button style="margin-right: 10px">
        <mat-icon>cloud_download</mat-icon> {{ 'Download Now' | translate }}
    </a> -->
    <!-- <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="language">
        <mat-icon>language</mat-icon>
    </button>
    <mat-menu #language="matMenu">
        <button mat-menu-item (click)="changeLang('en')">
            <span>{{ 'English' | translate }}</span>
        </button>
        <button mat-menu-item (click)="changeLang('fr')">
            <span>{{ 'French' | translate }}</span>
        </button>
        <button mat-menu-item (click)="changeLang('ur')">
            <span>{{ 'Urdu' | translate }}s</span>
        </button>
    </mat-menu> -->
    <div class="lectureTitle hidden-sm" [class.border-left]="sessionData && roomData" *ngIf="!isMobile">
        <div class="ellipsis production-label">
            <ng-container *ngIf="showTemplateActions && roomfilterData">
                <form class="hidden-sm" style="margin-left: 20px; margin-top: 10px;width:200px">
                    <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="10px">
                        <div fxFlex >
                            <app-custom-select-create-autocomplete #roomList [filters]="roomfilterData"
                                [dataType]="roomDataType" [value]="roomId" [dataTypeDisplay]="'Room'"
                                [placeholder]="'Select / Add Room'" [canDelete]="false"
                                (onSelectReturn)="selectRoom($event._id)" [pageSize]="10" [hasAddBtn]="true"
                                [hasEditBtn]="true" (onReturnSignal)="setTemplateActionReturn('room' + $event)" [bdcWalkTriggerFor]="sessionStep0">
                            </app-custom-select-create-autocomplete>
                        </div>
                        <div fxFlex *ngIf="layoutSessionData && !backLayer" style="z-index: 10;padding-left:30px;"
                            matTooltip="{{'Session' | translate}}: {{layoutSessionData.name}}"
                            matTooltipClass="tooltip-red">
                            <div class="production-label" style="margin-bottom: 6px;">{{'Session' | translate}}:</div>
                            <div id="session-data-name" style="font-size: 14pt;">{{layoutSessionData.name}}</div>
                        </div>
                        <div fxFlex *ngIf="layoutSessionData && backLayer" style="z-index: 10;"></div>
                    </div>
                </form>
            </ng-container>
            <span *ngIf="showProductionLabel">{{'Production Room' | translate}} -
            </span><span *ngIf="roomData">{{roomData.name}}</span>
        </div>
        <div *ngIf="sessionData" class="ellipsis" title="{{sessionData.name}}" id="session-data-name">
            {{sessionData.name}}<span
                *ngIf="breakoutData && breakoutData.name">&nbsp;-&nbsp;{{breakoutData.name}}</span>
            <span class="countdown"
                *ngIf="countDownTextDay >= 0 || countDownTextHour >= 0 || countDownTextMin >= 0 || countDownTextSec >= 0">
                &nbsp;-&nbsp;<span *ngIf="countDownTextDay">{{countDownTextDay}}d:</span><span
                    *ngIf="countDownTextHour">{{countDownTextHour}}h:</span><span
                    *ngIf="countDownTextMin != undefined && countDownTextMin.toString().length == 1">
                    0{{countDownTextMin}}m:</span><span
                    *ngIf="countDownTextMin != undefined && countDownTextMin.toString().length != 1">{{countDownTextMin}}m:</span><span
                    *ngIf="countDownTextSec != undefined && countDownTextSec.toString().length == 1">0{{countDownTextSec}}s</span><span
                    *ngIf="countDownTextSec != undefined && countDownTextSec.toString().length != 1">{{countDownTextSec}}s</span>
            </span>
        </div>
    </div>


    <div class="rightIcons" style="display: inline-flex;">
        <ng-container *ngIf="showTemplateActions">
            <div style="margin-right: 2vw;" >
                <div class="fieldInput actionCssTop">
                    <button *ngIf="!production" mat-icon-button (click)="resetTutorial()" matTooltip="{{'Start Tutorial' | translate}}"
                        matTooltipClass="tooltip-red">
                        <mat-icon>help_center</mat-icon>
                    </button>
                    <ng-container *ngIf="roomId">
                      <mat-divider *ngIf="!production" [vertical]="true"></mat-divider>
                      <button mat-icon-button (click)="setBackLayer(!backLayer)" matTooltip="Switch layers"
                          matTooltipClass="tooltip-red">
                          <mat-icon *ngIf="backLayer">flip_to_back</mat-icon>
                          <mat-icon *ngIf="!backLayer">flip_to_front</mat-icon>
                      </button>
                      <mat-divider [vertical]="true"></mat-divider>
                    </ng-container>
                    <ng-container *ngIf="templateObject && templateObject.data._id">
                      <button mat-icon-button [disabled]="loading" [matMenuTriggerFor]="liveoption"
                          matTooltip="{{ viewMode.indexOf('-offline') > -1 ? 'Offline Mode' : 'Live Mode' }}"
                          matTooltipClass="tooltip-red">
                          <span *ngIf="viewMode.indexOf('-offline') == -1">Live</span>
                          <span *ngIf="viewMode.indexOf('-offline') > -1">Offline</span>
                      </button>
                      <mat-menu #liveoption="matMenu">
                          <button mat-menu-item
                              (click)="setViewModeOffline(false)"><span>{{'Live Mode' | translate}}</span></button>
                          <button mat-menu-item
                              (click)="setViewModeOffline(true)"><span>{{'Offline Mode' | translate}}</span></button>
                      </mat-menu>
                      <mat-divider [vertical]="true"></mat-divider>
                      <button mat-icon-button matTooltip="{{viewModeDisplay[viewMode]}} Layout"
                          matTooltipClass="tooltip-red">
                          <mat-icon *ngIf="viewMode.indexOf('desktop') > -1">computer</mat-icon>
                          <mat-icon *ngIf="viewMode.indexOf('mobile') > -1">stay_primary_portrait</mat-icon>
                      </button>
                      <!-- [matMenuTriggerFor]="viewmode"
                        <mat-menu #viewmode="matMenu">
                          <button mat-menu-item [disabled]="viewMode=== 'desktop' || viewMode=== 'desktop-offline'"
                              (click)="setViewModeReturn($event, 'desktop')"><span>Desktop</span></button>
                          <button mat-menu-item [disabled]="true" *ngIf="streamMode=== 'rtc' || streamMode === 'rtctohls'" (click)="setViewModeReturn($event, 'mobile')" ><span >Mobile</span></button>
                      </mat-menu> -->
                      <mat-divider [vertical]="true"></mat-divider>
                      <button mat-icon-button style="width: 62px;"
                          [class.breakWord]="streamMode === 'meeting' || streamMode === 'largemeeting' || streamMode === 'rtctohls'"
                          *ngIf="streamMode !== 'rtctohls' && viewMode.indexOf('-offline') == -1"
                          matTooltip="{{streamModeDisplayToolTip[streamMode]}}"
                          matTooltipClass="tooltip-red"><span>{{streamModeDisplay[streamMode]}}</span> </button>
                      <button mat-icon-button [matMenuTriggerFor]="streammode" style="width: 62px;"
                          [class.breakWord]="streamMode === 'meeting' || streamMode === 'largemeeting' || streamMode === 'rtctohls'"
                          *ngIf="streamMode === 'rtctohls' && viewMode.indexOf('-offline') == -1"
                          matTooltip="{{streamModeDisplayToolTip[streamMode]}}" matTooltipClass="tooltip-red">
                          {{streamModeDisplay[streamMode]}}
                          <!-- <span *ngIf="viewMode=== 'desktop'">Regular</span>
                          <span *ngIf="viewMode=== 'desktop-hls'">Broadcast</span>  -->
                      </button>
                      <mat-menu #streammode="matMenu">
                          <button mat-menu-item [disabled]="viewMode=== 'desktop'"
                              *ngIf="(viewMode=== 'desktop-hls' || viewMode=== 'desktop')  &&  (streamMode=== 'rtc' || streamMode === 'rtctohls')"
                              (click)="setViewModeReturn($event, 'desktop')"><span>{{streamModeDisplay['rtc'] }}</span></button>
                          <!-- <button mat-menu-item [disabled]="viewMode=== 'mobile'" *ngIf="(viewMode=== 'mobile-hls' || viewMode=== 'mobile') && (streamMode=== 'rtc' || streamMode === 'rtctohls')" (click)="setViewModeReturn($event, 'mobile')" ><span >RTC</span></button> -->
                          <button mat-menu-item [disabled]="viewMode=== 'desktop-hls'"
                              (click)="setViewModeReturn($event, 'desktop-hls')"
                              *ngIf="(viewMode=== 'desktop-hls' || viewMode=== 'desktop') && (streamMode === 'hls' || streamMode === 'rtctohls')"><span>{{streamModeDisplay['hls'] }}</span></button>
                          <!-- <button mat-menu-item [disabled]="viewMode=== 'mobile-hls'" (click)="setViewModeReturn($event, 'mobile-hls')" *ngIf="(viewMode=== 'mobile-hls' || viewMode=== 'mobile') && (streamMode === 'hls' || streamMode === 'rtctohls')"><span >HLS</span></button> -->
                      </mat-menu>
                      <mat-divider [vertical]="true"
                          *ngIf="(streamMode !== 'meeting' && streamMode !== 'largemeeting') && viewMode.indexOf('-offline') == -1">
                      </mat-divider>
                      </ng-container>
                      <ng-container *ngIf="roomId">
                        <!-- <button *ngIf="!templateObject.data._id" mat-raised-button (click)="setTemplateActionReturn('create')" [disabled]="loading" >{{'Create' | translate}}</button> -->
                        <button mat-icon-button [disabled]="loading" [matMenuTriggerFor]="saveoption"
                            matTooltip="{{'Save' | translate}}" matTooltipClass="tooltip-red">
                            <mat-icon>save</mat-icon>
                        </button>
                        <mat-menu #saveoption="matMenu">
                            <button mat-menu-item (click)="setTemplateActionReturn('edit')"><span>Save for this
                                    {{'Setup' | translate}}</span></button>
                            <button mat-menu-item (click)="setTemplateActionReturn('savetemplate')" *ngIf="templateObject && templateObject.data._id"><span>Save as a
                                    Template</span></button>
                        </mat-menu>
                      </ng-container>
                      <ng-container *ngIf="templateObject && templateObject.data._id">
                      <mat-divider [vertical]="true"></mat-divider>
                      <button mat-icon-button (click)="setTemplateActionReturn('select')"
                          matTooltip="{{'Select Layout Template' | translate}}" matTooltipClass="tooltip-red">
                          <mat-icon>perm_media</mat-icon>
                      </button>
                      <mat-divider [vertical]="true"></mat-divider>
                      <button mat-icon-button [matMenuTriggerFor]="resetoption" matTooltip="{{'Reset' | translate}}"
                          matTooltipClass="tooltip-red">
                          <mat-icon>refresh</mat-icon>
                      </button>
                      <mat-menu #resetoption="matMenu">
                          <button mat-menu-item (click)="setTemplateActionReturn('reset')"><span>
                                  Reset {{'session' | translate}} to Saved</span></button>
                          <button mat-menu-item (click)="setTemplateActionReturn('default')"><span>Reset {{'session' | translate}} to Default</span></button>
                          <!-- <button mat-menu-item (click)="resetTutorial()"><span>Reset Tutorial</span></button> -->
                      </mat-menu>
                      <!-- <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon title="More Actions">more_vert</mat-icon></button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="setTemplateActionReturn('reset')" >{{'Reset' | translate}}</button>
                <button mat-menu-item (click)="setTemplateActionReturn('select')" >{{'Select Template' | translate}}</button>
              </mat-menu> -->
                  </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="sessionData && showStartEndSession && !sessionData.isKeepActive && !viewAsAttendee">
            <button mat-icon-button style="width: min-content; padding: 5px 15px 0;" [disableRipple]="true" [routerLink]
                (click)="endSession()" *ngIf="sessionData.active">
                <span class="lectureendSession">
                    {{ 'End' | translate }} {{ 'Session' | translate }}</span>
            </button>
            <button mat-icon-button style="width: min-content; padding: 5px 15px 0;" [disableRipple]="true" [routerLink]
                (click)="startSession()" *ngIf="!sessionData.active">
                <span class="lectureendSession">
                    {{ 'Start' | translate }} {{ 'Session' | translate }}</span>
            </button>
        </ng-container>
        <div class="icon-container" *ngIf="!goodConnection && sessionData">
            <button mat-icon-button style="color: orange;cursor: default;">
                <mat-icon>warning</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{ 'Poor Connection' | translate }}
            </div>
        </div>
        <!-- <div class="icon-container" *ngIf="sessionData && highlightNotifications && !lockSite">
            <button mat-icon-button style="color: lime;" (click)="showFrame(pushedTileLink)">
                <mat-icon>notifications_active</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Notification' | translate}}
            </div>
        </div> -->
        <!-- <div class="icon-container" *ngIf="viewAsAttendee && !recordingStatus">
            <button mat-icon-button (click)="startRecording()">
                <mat-icon>fiber_manual_record</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Start Recording' | translate}}
            </div>
        </div>
        <div class="icon-container" *ngIf="viewAsAttendee && recordingStatus">
            <button mat-icon-button (click)="stopRecording()">
                <mat-icon style="color:red">fiber_manual_record</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Stop Recording' | translate}}
            </div>
        </div> -->
        <ng-container *ngIf="!showTemplateActions">
            <div class="icon-container"
                *ngIf="!viewAsAttendee && (currentSessionUserRole === 'moderator' || currentSessionUserRole === 'admin')">
                <button mat-icon-button (click)="goToAttendeeView()">
                    <mat-icon>launch</mat-icon>
                </button>
                <div class="icon-tooltip">
                    {{'View as Attendee' | translate}}
                </div>
            </div>
            <div class="icon-container" *ngIf="donateLink">
                <button mat-icon-button [routerLink] (click)="openDonate()">
                    <!-- <mat-icon>group_work
                </mat-icon> -->
                    <img src="assets/vertical/{{orgType}}/images/donate.png"
                        class="circle z-depth-2 responsive-img activator" alt="Donate" style="height: 25px;">
                </button>
                <div class="icon-tooltip">
                    {{'Donate' | translate}}
                </div>
            </div>
            <div class="icon-container" *ngIf="organization && organization._id !== '5fc50248015914704358cf79'">
                <button mat-icon-button [routerLink] (click)="openSponsors()">
                    <mat-icon>group_work
                    </mat-icon>
                </button>
                <div class="icon-tooltip">
                    {{'Sponsors' | translate}}
                </div>
            </div>
            <div class="icon-container">
                <button mat-icon-button [routerLink] (click)="openAgenda()" [hint-tooltip]="'hints.agenda' | translate"
                    [class.hint]="pageScope.isHintOn" [disable]="!pageScope.isHintOn">
                    <mat-icon>menu_book
                    </mat-icon>
                </button>
                <div class="icon-tooltip">
                    {{'Agenda' | translate}}
                </div>
            </div>
            <div class="icon-container" *ngIf="hasListingDirectory && !lockSite">
                <button mat-icon-button [routerLink] (click)="gotoBook()"
                    title="{{'Listing and Networking' | translate}}" [hint-tooltip]="'hints.stellarConnect' | translate"
                    [class.hint]="pageScope.isHintOn" [disable]="!pageScope.isHintOn">
                    <mat-icon>recent_actors</mat-icon>
                </button>
                <div class="icon-tooltip">
                    {{'StellarConnect' | translate}}
                </div>
            </div>
            <div class="icon-container"
                *ngIf="hasListingDirectory && !lockSite && (selectedUser['isTextChat'] || selectedUser['isVideoChat'])">
                <button mat-icon-button (click)="toggleDnD()" title="{{'Do Not Disturb' | translate}}"
                    [hint-tooltip]="'hints.dnd' | translate" [class.hint]="pageScope.isHintOn"
                    [disable]="!pageScope.isHintOn">
                    <mat-icon [ngStyle]="{'color': selectedUser['chat_dnd'] ? 'red' : ''}">block</mat-icon>
                </button>
                <div class="icon-tooltip" *ngIf="selectedUser['chat_dnd']">
                    {{'Chat Off' | translate}}
                </div>
                <div class=" icon-tooltip" *ngIf="!selectedUser['chat_dnd']">
                    {{'DND' | translate}}
                </div>
            </div>
            <div class="icon-container"
                *ngIf="!lockSite && (selectedUser['isTextChat'] || selectedUser['isVideoChat']) && opentokService.globalSession && opentokService.globalSession['isConnected']()">
                <div>
                    <button mat-icon-button (click)="openGlobalChat()"
                        title="{{'Chat and Video with Attendees' | translate}}"
                        [class.clamp]="selectedUser['isTextChat'] && selectedUser['isVideoChat']"
                        [hint-tooltip]="'hints.stellarComm' | translate" [class.hint]="pageScope.isHintOn"
                        [disable]="!pageScope.isHintOn">
                        <mat-icon *ngIf="selectedUser['isTextChat']"
                            [ngStyle]="{'color': highlightDirectory ? 'orange':''}">
                            chat</mat-icon>
                        <mat-icon *ngIf="selectedUser['isVideoChat']"
                            [ngStyle]="{'color': highlightDirectory ? 'orange':''}">
                            voice_chat
                        </mat-icon>
                    </button>
                </div>
                <div class="icon-tooltip">
                    {{'StellarComm' | translate}}
                </div>
            </div>
            <div class="icon-container" *ngIf="!lockSite">
                <button mat-icon-button [routerLink] (click)="toggleHint()" title="{{'Show Hints' | translate}}">
                    <mat-icon *ngIf="!pageScope.isHintOn">help_outline</mat-icon>
                    <mat-icon *ngIf="pageScope.isHintOn" style="color: lime;">help</mat-icon>
                </button>
                <div class="icon-tooltip" *ngIf="pageScope.isHintOn">
                    {{'Hints On' | translate}}
                </div>
                <div class="icon-tooltip" *ngIf="!pageScope.isHintOn">
                    {{'Hints Off' | translate}}
                </div>
            </div>
        </ng-container>
        <!-- <div class="icon-container" *ngIf="sessionData && !highlightNotifications">
        <button mat-icon-button (click)="showFrame(pushedTileLink)">
            <mat-icon>notifications_none</mat-icon>
        </button>
        <div class="icon-tooltip">
            {{'No Notifications' | translate}}
        </div>
    </div> -->
        <div class="icon-container" *ngIf="enableTranslation">
            <button class="languageSelection" mat-icon-button [matMenuTriggerFor]="languageselect"
                [disableRipple]="true" title="{{'Language' | translate}}">
                <mat-icon>language</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Language' | translate}}
            </div>
            <mat-menu #languageselect="matMenu">
                <button mat-menu-item (click)="setLanguage('en')" [disabled]="language === 'en'"><span
                        class="primaryColor">
                        {{'English' | translate}}</span></button>
                <button mat-menu-item (click)="setLanguage('fr')" [disabled]="language === 'fr'"><span
                        class="primaryColor">{{'French' | translate}}</span></button>
            </mat-menu>
        </div>
        <div class="icon-container">
            <button mat-icon-button [routerLink] (click)="viewProfile()"
                title="{{selectedUser.firstName}} {{selectedUser.lastName}}"
                [hint-tooltip]="'hints.profile' | translate" [class.hint]="pageScope.isHintOn"
                [disable]="!pageScope.isHintOn">
                <!-- <mat-icon>account_circle</mat-icon> -->
                <span style="font-weight: bold">{{selectedUser.firstName | myfirstLetterPipe}}
                    {{selectedUser.lastName | myfirstLetterPipe}}</span>
            </button>
            <div class="icon-tooltip">
                {{'Profile' | translate}}
            </div>
        </div>
        <div class="icon-container" *ngIf="sessionData && !(roomData && roomData.main)" style="padding-right: 15px;">
            <button mat-icon-button [disableRipple]="true" [routerLink]
                title="{{'Go to' | translate}} {{'Home' | translate}}" (click)="goHome()">
                <mat-icon style="color: #3972f6; font-size: 26pt;">home</mat-icon>
            </button>
            <div class="icon-tooltip home-icon">
                {{'Home' | translate}}
            </div>
        </div>
        <!-- <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="profile" >
        <mat-icon>account_circle</mat-icon>
    </button> -->
        <!-- <mat-menu #profile="matMenu">
        <button mat-menu-item [routerLinkActive]="'active'" [routerLink]="['/profile']">
            <mat-icon>person</mat-icon>
            <span>{{ 'Profile' | translate }}</span>
        </button>
        <button mat-menu-item [routerLinkActive]="'active'" [routerLink]="['/settings']">
            <mat-icon>settings</mat-icon>
            <span>{{ 'Settings' | translate }}</span>
        </button>
    </mat-menu> -->
        <div class="icon-container" *ngIf="!sessionData && !showExit && !(roomData && roomData.main)"
            style="padding-right: 15px;">
            <button mat-icon-button [disableRipple]="true" [routerLink] (click)="goHome()"
                title="{{'Go to' | translate}} {{'Home' | translate}}">
                <mat-icon style="color: #3972f6; font-size: 26pt;">home</mat-icon>
            </button>
            <div class="icon-tooltip home-icon">
                {{'Home' | translate}}
            </div>
        </div>
        <!-- showExit || (roomData && roomData.main) -->
        <div class="icon-container">
            <button mat-icon-button (click)="onLoggedout()">
                <mat-icon>exit_to_app</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Log Out' | translate}}
            </div>
        </div>
    </div>
    <div [@slideInOut]="drawerState" class="notification-drawer">
        <div fxFlex fxLayout="row" fxLayoutGap="5px">
            <div fxFlex="90">
                <a (click)="chatWithAttendee()" style="cursor: pointer;">
                    <div>{{ opentokService.lastChatMessage.message | truncate : ['50','...']}}</div>
                </a>
                <div *ngIf="opentokService.videoChatInvite">
                    <button mat-icon-button title="{{'Answer' | translate}}">
                        <mat-icon style="color: limegreen;" (click)="answerCall()">videocam</mat-icon>
                    </button>
                    <button mat-icon-button title="{{'Decline' | translate}}" style="margin-left:20px">
                        <mat-icon style="color: red;" (click)="declineCall()">videocam</mat-icon>
                    </button>
                </div>
            </div>
            <div fxFlex="10" fxLayoutAlign="end">
                <a (click)="closeDrawer()">
                    <mat-icon>close</mat-icon>
                </a>
            </div>
        </div>
    </div>
    <audio #ringStream src="assets/files/phone_ringing.mpeg" preload="auto" loop="1" autostart="0"></audio>
</mat-toolbar>
<bdc-walk-popup #sessionStep0 name="sessionStep0" header="Setup {{'Rooms' | translate}}" xPosition="after" [showButton]="true" buttonText="Ok" [mustCompleted]="{dialogWelcome: true}" [onButtonCompleteTask]="{sessionNext: 'room'}">
  Create, edit and select Rooms
</bdc-walk-popup>
