<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
		<div>
      <mat-card>
				<app-tile-data #tileData [isDialog]="true" (onSaveReturn)="saveReturn($event)"></app-tile-data>
      </mat-card>
    </div>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button mat-raised-button (click)="save()">{{'Save' | translate}}</button>
	</div>
	<br/>
</div>
