<div class="col-xl-12">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
		<div class="fieldItem" [style.height]="iframeHeight">
			<span *ngIf="loading">{{'Loading...' | translate}}</span>
			<iframe id="iframeMain" #iframeMain width="100%" frameBorder="0"  (load)="onload($event)" [height]="iframeHeight"></iframe>
		</div>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
	</div>
	<br/>
</div>
