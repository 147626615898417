import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { urlSafeBase64Encoding } from '../../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';

interface ViewDialogData {
	title: string;
	confirmData: any;
}

@Component({
	selector: 'app-picture-wall-dialog-modal',
	templateUrl: './picture-wall-dialog.component.html',
	styleUrls: ['./picture-wall-dialog.component.scss']
})
export class PictureWallDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public returnData: any = {timer: '', moderated: true, gridSizeX: 3, gridSizeY: 3, total: 0};
	constructor(
		private requestService: RequestService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<PictureWallDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
	  // console.log('ViewDialogData', data);
	}

	ngOnInit() {
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
}
