<span class="marginView">
  <label>{{'Gap' | translate}}: </label>
  <span style="margin-left:60px;display: inline-block;">
    <label> H </label>
    <input name="hMargin" placeholder="" type="number" [value]="hValue" (change)="setDataH($event.target.value)">
  </span>
  <span style="margin-left:5px;display: inline-block;">
    <label> V </label>
    <input name="vMargin" placeholder="" type="number" [value]="vValue" (change)="setDataV($event.target.value)">
  </span>
</span>
