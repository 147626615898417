import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexTitleSubtitle,
    ApexDataLabels,
    ApexFill,
    ApexMarkers,
    ApexYAxis,
    ApexXAxis,
    ApexTooltip, ChartComponent, ApexPlotOptions
} from "ng-apexcharts";
import { Subscription } from 'rxjs';
import { ChartService } from '../../services/chart.service';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    fill: ApexFill;
    title: ApexTitleSubtitle;
    tooltip: ApexTooltip;
};

@Component({
    selector: 'app-timeseries-chart',
    templateUrl: './timeseries-chart.component.html',
    styleUrls: ['./timeseries-chart.component.scss'],
})

export class TimeSeriesChartComponent implements OnInit {

    // @Input()
    // set initTimeSeries(initTimeSeries: boolean) {
    //     if (initTimeSeries) {
    //         this.initChartData();
    //     }
    // }
    @Input()
    set data(data: any) {
        if (data && data.length) {
            this.dataList = data;
            this.initChartData();
        }
    }
    @Input() yAxisTitle: string = undefined;
    @Input() chartTitle: string = undefined;
    @Input() seriesTitle: string = undefined;

    @ViewChild("chart") chart: ChartComponent;

    public chartOptions: Partial<ChartOptions>;
    // private subscriptions: Subscription[] = [];
    private dataList: any = [];

    constructor() { }

    ngOnInit() {
        // this.subscriptions.push(this.chartService.plotThisPoint.subscribe((value) => {
        //     console.log('this.dataList', this.dataList)
        //     if (value) {
        //         let now = moment().toDate();
        //         now.setSeconds(0);
        //         now.setMilliseconds(0);
        //         let timeStamp = now.getTime();
        //         if (value.joined) {
        //             if (this.dataList.hasOwnProperty(timeStamp)) {
        //                 this.dataList[timeStamp].population += 1;
        //             }
        //             else {
        //                 this.dataList[timeStamp] = { population: 1 };
        //             }
        //         }
        //         else {
        //             if (this.dataList.hasOwnProperty(timeStamp)) {
        //                 this.dataList[timeStamp].population -= 1;
        //             }
        //         }
        //         this.initChartData();
        //     }
        // }));

        // this.drawChart();
    }

    ngOnDestroy() {
        // this.subscriptions.forEach(el => el.unsubscribe());
    }

    public initChartData(): void {
        // let dates = [];
        // for (let [key, value] of Object.entries(this.dataList)) {
        //     dates.push([parseInt(key), value['population']]);
        // }

        // dates.push([1600275488033 + 1 * 60000, 2]);
        // dates.push([1600275488033 + 2 * 60000, 10]);
        // dates.push([1600275488033 + 4 * 60000, 15]);
        // dates.push([1600275488033 + 5 * 60000, 5]);
        // dates.push([1600275488033 + 6 * 60000, 8]);
        // dates.push([1600275488033 + 7 * 60000, 10]);
        // dates.push([1600275488033 + 9 * 60000, 9]);
        // dates.push([1600275488033 + 10 * 60000, 10]);
        // dates.push([1600275488033 + 11 * 60000, 0]);

        // console.log('dates', dates, this.yAxisTitle)

        this.chartOptions = {
            series: [
                {
                    name: this.seriesTitle,
                    data: this.dataList
                }
            ],
            chart: {
                type: "area",
                stacked: false,
                foreColor: '#151a3f',
                width: 500,
                height: 350,
                zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: "zoom"
                }
            },
            dataLabels: {
                enabled: false
            },
            title: {
                text: this.chartTitle,
                align: "center",
                style: {
                    color: "#151a3f"
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                }
            },
            yaxis: {
                min: 0,
                labels: {
                    formatter: (val) => {
                        return val.toFixed(0);
                    }
                },
                title: {
                    text: this.yAxisTitle
                },
                // tickAmount: 1
            },
            xaxis: {
                type: "category",
                labels: {
                    formatter: (val) => {
                        let date = new Date(val);
                        let hours = date.getHours().toString();
                        let minutes = date.getMinutes().toString();
                        if (hours.length == 1)
                            hours = '0' + hours;
                        if (minutes.length == 1)
                            minutes = '0' + minutes;
                        return hours + ':' + minutes;
                    },
                    style: {
                        colors: '#151a3f'
                    }
                },
            },
            tooltip: {
                shared: false,
                y: {
                    formatter: (val) => {
                        return val.toString();
                    }
                }
            }
        }
    }
}