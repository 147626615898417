<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
      <mat-card *ngIf="data.data">
				<div class="fieldInput">
						<mat-form-field>
							<input matInput type="number" name="width" [(ngModel)]="data.data['width']" placeholder="{{'Width' | translate}} %" >
						</mat-form-field>
				</div>
      </mat-card>
	</div>
	<div mat-dialog-actions align="end">
			<button mat-raised-button (click)="closeModal(data.data)">{{'Confirm' | translate}}</button>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
	</div>
	<br/>
</div>
