import { Component, OnInit, Input, ViewChild } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

interface ExampleFlatNode {
  expandable: boolean;
  title: string;
  dataType: string;
  level: number;
}

@Component({
    selector: 'app-tile-widget',
    templateUrl: './tile-widget.component.html',
    styleUrls: ['./tile-widget.component.scss']
})
export class TileWidgetComponent implements OnInit {
    private subscriptions: Subscription[] = [];
    private _transformer = (node: any, level: number) => {
      return {
        expandable: !!node.data && node.data.length > 0,
        title: node.title,
        dataType: node.dataType,
        level: level,
      };
    }

    treeControl = new FlatTreeControl<ExampleFlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.data);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    @ViewChild('tree') tree;
    @Input() data: any[] = [];
    @Input() dataType: string = '';
    constructor() {
    }
    ngOnInit() {
      let preparedData = this.prepareData(this.data, this.dataType);
      // console.log('preparedData', preparedData);
      this.dataSource.data = JSON.parse(JSON.stringify(preparedData));
    }
    ngAfterViewInit() {
      if(this.dataType === 'array')
        this.tree.treeControl.expandAll();
    }
    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
    /**
  	 * On destroy
  	 */
  	ngOnDestroy(): void {
  		this.subscriptions.forEach(sb => sb.unsubscribe());
  	}
    prepareData(data: any[], dataType: string): any[]{
      let preparedData = [];
      if(dataType === 'array'){
        for(let dt of data){
          preparedData.push({title: dt.title, data: this.prepareData(dt.data, dt.dataType), dataType: 'array'});
        }
      }else if (dataType === 'string'){
        preparedData.push({title: data, dataType: 'string'});
      }else if (dataType === 'arrayimage'){
        preparedData.push({title: data, dataType: 'arrayimage'});
      }
      return preparedData;
    }
}
