import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { BdcWalkModule } from 'bdc-walkthrough';

import { BdcWalkModule } from './modules/bdc-walkthrough';
// import { GlobalService } from './services/global.service';

import { AlertActionEntityDialogComponent, SidebarComponent, TopnavComponent, NavComponent, PageHeaderComponent, SnackBarComponent, ActionNotificationComponent, ConfirmCkeditorEntityDialogComponent, ConfirmInputEntityDialogComponent, AlertComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent, AlertEntityDialogComponent, LoadingScreenComponent, ModalViewDialogComponent, ModalViewImageDialogComponent, EnumViewComponent, ErrorEntityDialogComponent, ConfirmEntityDialogComponent, ModalIframeDialogComponent, ModalUserDialogComponent, ModalDialogComponent, ModalPasswordDialogComponent, ModalUserViewDialogComponent, CustomSelectComponent, CustomTagsComponent, TileWidgetComponent, ModalIframeHeaderDialogComponent, WidgetComponent, ConfirmSelectEntityDialogComponent, NumberInputComponent } from './components';

import { CkeditorBlockComponent, WidgetDummyComponent, TextBlockComponent, LinkDescriptionBlockComponent, VideoBlockComponent, PictureBlockComponent, DisqusBlockComponent, SocialFeedBlockComponent, CalendarBlockComponent, ShareBlockComponent, PatientsBlockComponent, InquiryBlockComponent, SurveyBlockComponent, QuestionnaireBlockComponent, QuestionnaireSubOptionComponent, DescriptionSubOptionComponent, StartWrapperBlockComponent, AddSpaceComponent, FormTitleBlockComponent, QuestionsBlockComponent, AttendanceBlockComponent, ConfirmationBlockComponent, PasswordBlockComponent, NextBlockComponent, FormPhotoComponent, FormDocumentComponent, PainLevelComponent, DrawToolBlockComponent, PhysicianBlockComponent, EndWrapperBlockComponent, FillBlockComponent, NotesBlockComponent, ButtonsBlockComponent, ContactUsBlockComponent, PlacefullBlockComponent, AddToCartBlockComponent, CartBlockComponent, BlanksFormBlockComponent, ExclusiveUrlBlockComponent, FileUploadBlockComponent, PushpayBlockComponent, ThreedCartBlockComponent, BlogsBlockComponent, ChatBlockComponent, AccountBlockComponent, ProfileBlockComponent, ModalGalleryDialogComponent, ModalRegistrationDialogComponent } from './components';

import { CustomSelectAutocompleteComponent, CustomSelectCreateAutocompleteComponent, CustomMetadataTableComponent, CustomMultipleSelectComponent, CustomSelectCreateAutocompleteDialogComponent, ModalPreviewDialogComponent, TilesListComponent, IframeUrlComponent, TileAnswersComponent, ModalTileCreateDialogComponent, TileDataComponent, LectureTilesListComponent, ModalLayoutDialogComponent, TileSelectDialogComponent, SliderComponent, BillboardSelectDialogComponent, PictureSelectDialogComponent, CustomSelectDialogComponent, ModalCropDialogComponent, MarginViewComponent, CustomDateTimeComponent, SessionAssignDialogComponent, ImageMapComponent } from './components';
import { LayoutDynamicDataComponent, ColumnSettingsDialogComponent, ScreenLayoutComponent, ScreenLayoutViewComponent, ModalAdvancedGalleryDialogComponent, PictureWallDialogComponent, PictureDialogComponent, LayoutComponent } from './components';
import { ClickOutSideDirective } from './directives';

import { Utils } from './helpers/utils';

import { CKEditorModule } from 'ngx-ckeditor';
import { ResizableModule } from './directives/angular-resizable-element';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FileModalDialogComponent } from './components/custom-file-upload-dialog/custom-file-upload-dialog.component';
import { SimpleAlertEntityDialogComponent } from './components/modals/simple-entity-dialog/simple-entity-dialog.component';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { ConfirmRTMPEntityDialogComponent } from './components/modals/confirm-rtmp-entity-dialog/confirm-rtmp-entity-dialog.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TimeSeriesChartComponent } from './components/timeseries-chart/timeseries-chart.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PanoramaComponent } from './components/panorama/panorama.component';
// import { NgChartsModule } from 'ng2-charts';

@NgModule({
        imports: [
                CommonModule,
                RouterModule,
                SharedModule,
                TranslateModule,
                FormsModule,
                ReactiveFormsModule,
                CKEditorModule,
                BdcWalkModule,
                ResizableModule,
                ImageCropperModule,
                FlexLayoutModule.withConfig({ addFlexToParent: false }),
                // DeviceDetectorModule,
                NgApexchartsModule
                // NgChartsModule,
        ],
        providers: [
                // GlobalService
                Utils
        ],
        entryComponents: [
                SnackBarComponent,
                AlertActionEntityDialogComponent,
                DeleteEntityDialogComponent,
                ErrorEntityDialogComponent,
                ConfirmEntityDialogComponent,
                SimpleAlertEntityDialogComponent,
                ConfirmCkeditorEntityDialogComponent,
                ConfirmInputEntityDialogComponent,
                ConfirmSelectEntityDialogComponent,
                ConfirmRTMPEntityDialogComponent,
                ModalIframeDialogComponent,
                ModalUserDialogComponent,
                FetchEntityDialogComponent,
                UpdateStatusDialogComponent,
                AlertEntityDialogComponent,
                LoadingScreenComponent,
                ModalViewDialogComponent,
                ModalViewImageDialogComponent,
                EnumViewComponent,
                ModalDialogComponent,
                FileModalDialogComponent,
                ModalPasswordDialogComponent,
                ModalUserViewDialogComponent,
                CustomSelectComponent,
                CustomTagsComponent,
                TileWidgetComponent,
                ModalIframeHeaderDialogComponent,
                WidgetComponent,
                WidgetDummyComponent,
                VideoBlockComponent,
                PictureBlockComponent,
                DisqusBlockComponent,
                SocialFeedBlockComponent,
                CalendarBlockComponent,
                ShareBlockComponent,
                PatientsBlockComponent,
                InquiryBlockComponent,
                SurveyBlockComponent,
                QuestionnaireBlockComponent,
                QuestionnaireSubOptionComponent,
                DescriptionSubOptionComponent,
                StartWrapperBlockComponent,
                AddSpaceComponent,
                FormTitleBlockComponent,
                QuestionsBlockComponent,
                AttendanceBlockComponent,
                ConfirmationBlockComponent,
                PasswordBlockComponent,
                NextBlockComponent,
                FormPhotoComponent,
                FormDocumentComponent,
                PainLevelComponent,
                DrawToolBlockComponent,
                PhysicianBlockComponent,
                EndWrapperBlockComponent,
                FillBlockComponent,
                NotesBlockComponent,
                ButtonsBlockComponent,
                ContactUsBlockComponent,
                PlacefullBlockComponent,
                AddToCartBlockComponent,
                CartBlockComponent,
                BlanksFormBlockComponent,
                ExclusiveUrlBlockComponent,
                FileUploadBlockComponent,
                PushpayBlockComponent,
                ThreedCartBlockComponent,
                BlogsBlockComponent,
                ChatBlockComponent,
                AccountBlockComponent,
                ProfileBlockComponent,
                TextBlockComponent,
                LinkDescriptionBlockComponent,
                CkeditorBlockComponent,
                ModalGalleryDialogComponent,
                ModalRegistrationDialogComponent,
                ModalAdvancedGalleryDialogComponent,
                CustomSelectAutocompleteComponent,
                CustomSelectCreateAutocompleteComponent,
                CustomMetadataTableComponent,
                CustomMultipleSelectComponent,
                CustomSelectCreateAutocompleteDialogComponent,
                ModalPreviewDialogComponent,
                TilesListComponent,
                IframeUrlComponent,
                TileAnswersComponent,
                ModalTileCreateDialogComponent,
                TileDataComponent,
                LectureTilesListComponent,
                ModalLayoutDialogComponent,
                TileSelectDialogComponent,
                BillboardSelectDialogComponent,
                PictureSelectDialogComponent,
                CustomSelectDialogComponent,
                SliderComponent,
                PanoramaComponent,
                MarginViewComponent,
                LayoutDynamicDataComponent,
                ColumnSettingsDialogComponent,
                PictureWallDialogComponent,
                PictureDialogComponent,
                LayoutComponent,
                ScreenLayoutComponent,
                ScreenLayoutViewComponent,
                CustomDateTimeComponent,
                ModalCropDialogComponent,
                BarChartComponent,
                TimeSeriesChartComponent,
                NotificationComponent,
                SessionAssignDialogComponent,
                ImageMapComponent,
                NumberInputComponent,
        ],
        declarations: [
                SidebarComponent,
                AlertActionEntityDialogComponent,
                TopnavComponent,
                NavComponent,
                PageHeaderComponent,
                SnackBarComponent,
                EnumViewComponent,
                ActionNotificationComponent,
                ConfirmCkeditorEntityDialogComponent,
                ConfirmInputEntityDialogComponent,
                ConfirmSelectEntityDialogComponent,
                ConfirmRTMPEntityDialogComponent,
                ModalViewDialogComponent,
                ModalViewImageDialogComponent,
                AlertComponent,
                DeleteEntityDialogComponent,
                ErrorEntityDialogComponent,
                ConfirmEntityDialogComponent,
                SimpleAlertEntityDialogComponent,
                ModalIframeDialogComponent,
                ModalUserDialogComponent,
                FetchEntityDialogComponent,
                UpdateStatusDialogComponent,
                AlertEntityDialogComponent,
                LoadingScreenComponent,
                ModalDialogComponent,
                FileModalDialogComponent,
                ModalPasswordDialogComponent,
                ModalUserViewDialogComponent,
                CustomSelectComponent,
                CustomTagsComponent,
                TileWidgetComponent,
                ModalIframeHeaderDialogComponent,
                WidgetComponent,
                WidgetDummyComponent,
                VideoBlockComponent,
                PictureBlockComponent,
                DisqusBlockComponent,
                SocialFeedBlockComponent,
                CalendarBlockComponent,
                ShareBlockComponent,
                PatientsBlockComponent,
                InquiryBlockComponent,
                SurveyBlockComponent,
                QuestionnaireBlockComponent,
                QuestionnaireSubOptionComponent,
                DescriptionSubOptionComponent,
                StartWrapperBlockComponent,
                AddSpaceComponent,
                FormTitleBlockComponent,
                QuestionsBlockComponent,
                AttendanceBlockComponent,
                ConfirmationBlockComponent,
                PasswordBlockComponent,
                NextBlockComponent,
                FormPhotoComponent,
                FormDocumentComponent,
                PainLevelComponent,
                DrawToolBlockComponent,
                PhysicianBlockComponent,
                EndWrapperBlockComponent,
                FillBlockComponent,
                NotesBlockComponent,
                ButtonsBlockComponent,
                ContactUsBlockComponent,
                PlacefullBlockComponent,
                AddToCartBlockComponent,
                CartBlockComponent,
                BlanksFormBlockComponent,
                ExclusiveUrlBlockComponent,
                FileUploadBlockComponent,
                PushpayBlockComponent,
                ThreedCartBlockComponent,
                BlogsBlockComponent,
                ChatBlockComponent,
                AccountBlockComponent,
                ProfileBlockComponent,
                TextBlockComponent,
                LinkDescriptionBlockComponent,
                CkeditorBlockComponent,
                ModalGalleryDialogComponent,
                ModalRegistrationDialogComponent,
                ModalAdvancedGalleryDialogComponent,
                CustomSelectAutocompleteComponent,
                CustomSelectCreateAutocompleteComponent,
                CustomMetadataTableComponent,
                CustomMultipleSelectComponent,
                CustomSelectCreateAutocompleteDialogComponent,
                ModalPreviewDialogComponent,
                TilesListComponent,
                IframeUrlComponent,
                TileAnswersComponent,
                ModalTileCreateDialogComponent,
                TileDataComponent,
                LectureTilesListComponent,
                ModalLayoutDialogComponent,
                TileSelectDialogComponent,
                BillboardSelectDialogComponent,
                PictureSelectDialogComponent,
                CustomSelectDialogComponent,
                ClickOutSideDirective,
                SliderComponent,
                PanoramaComponent,
                MarginViewComponent,
                LayoutDynamicDataComponent,
                ColumnSettingsDialogComponent,
                PictureWallDialogComponent,
                PictureDialogComponent,
                LayoutComponent,
                ScreenLayoutComponent,
                ScreenLayoutViewComponent,
                CustomDateTimeComponent,
                ModalCropDialogComponent,
                BarChartComponent,
                TimeSeriesChartComponent,
                NotificationComponent,
                SessionAssignDialogComponent,
                ImageMapComponent,
                NumberInputComponent
        ],
        exports: [
                SidebarComponent,
                AlertActionEntityDialogComponent,
                TopnavComponent,
                NavComponent,
                PageHeaderComponent,
                ActionNotificationComponent,
                ConfirmCkeditorEntityDialogComponent,
                ConfirmInputEntityDialogComponent,
                ConfirmSelectEntityDialogComponent,
                ConfirmRTMPEntityDialogComponent,
                ModalViewDialogComponent,
                ModalViewImageDialogComponent,
                AlertComponent,
                DeleteEntityDialogComponent,
                ErrorEntityDialogComponent,
                ConfirmEntityDialogComponent,
                SimpleAlertEntityDialogComponent,
                ModalIframeDialogComponent,
                ModalUserDialogComponent,
                FetchEntityDialogComponent,
                UpdateStatusDialogComponent,
                AlertEntityDialogComponent,
                EnumViewComponent,
                LoadingScreenComponent,
                ModalDialogComponent,
                FileModalDialogComponent,
                ModalPasswordDialogComponent,
                ModalUserViewDialogComponent,
                CustomSelectComponent,
                CustomTagsComponent,
                TileWidgetComponent,
                ModalIframeHeaderDialogComponent,
                WidgetComponent,
                WidgetDummyComponent,
                VideoBlockComponent,
                PictureBlockComponent,
                DisqusBlockComponent,
                SocialFeedBlockComponent,
                CalendarBlockComponent,
                ShareBlockComponent,
                PatientsBlockComponent,
                InquiryBlockComponent,
                SurveyBlockComponent,
                QuestionnaireBlockComponent,
                QuestionnaireSubOptionComponent,
                DescriptionSubOptionComponent,
                StartWrapperBlockComponent,
                AddSpaceComponent,
                FormTitleBlockComponent,
                QuestionsBlockComponent,
                AttendanceBlockComponent,
                ConfirmationBlockComponent,
                PasswordBlockComponent,
                NextBlockComponent,
                FormPhotoComponent,
                FormDocumentComponent,
                PainLevelComponent,
                DrawToolBlockComponent,
                PhysicianBlockComponent,
                EndWrapperBlockComponent,
                FillBlockComponent,
                NotesBlockComponent,
                ButtonsBlockComponent,
                ContactUsBlockComponent,
                PlacefullBlockComponent,
                AddToCartBlockComponent,
                CartBlockComponent,
                BlanksFormBlockComponent,
                ExclusiveUrlBlockComponent,
                FileUploadBlockComponent,
                PushpayBlockComponent,
                ThreedCartBlockComponent,
                BlogsBlockComponent,
                ChatBlockComponent,
                AccountBlockComponent,
                ProfileBlockComponent,
                TextBlockComponent,
                LinkDescriptionBlockComponent,
                CkeditorBlockComponent,
                ModalGalleryDialogComponent,
                ModalRegistrationDialogComponent,
                ModalAdvancedGalleryDialogComponent,
                CustomSelectAutocompleteComponent,
                CustomSelectCreateAutocompleteComponent,
                CustomMetadataTableComponent,
                CustomMultipleSelectComponent,
                CustomSelectCreateAutocompleteDialogComponent,
                ModalPreviewDialogComponent,
                TilesListComponent,
                IframeUrlComponent,
                TileAnswersComponent,
                ModalTileCreateDialogComponent,
                TileDataComponent,
                LectureTilesListComponent,
                ModalLayoutDialogComponent,
                TileSelectDialogComponent,
                BillboardSelectDialogComponent,
                PictureSelectDialogComponent,
                CustomSelectDialogComponent,
                ClickOutSideDirective,
                SliderComponent,
                PanoramaComponent,
                MarginViewComponent,
                LayoutDynamicDataComponent,
                ColumnSettingsDialogComponent,
                PictureWallDialogComponent,
                PictureDialogComponent,
                LayoutComponent,
                ScreenLayoutComponent,
                ScreenLayoutViewComponent,
                CustomDateTimeComponent,
                ModalCropDialogComponent,
                BarChartComponent,
                TimeSeriesChartComponent,
                NotificationComponent,
                SessionAssignDialogComponent,
                ImageMapComponent,
                NumberInputComponent
        ]
})
export class LayoutComponentModule { }
