import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit {

  @Input() public maxWidth = 0;
  @Input() public val: number  = 0;
  @Input() private positive:boolean = false;
  @Input() private steps: number = 1;
  @Output() valueChanged = new EventEmitter<number>()

  @ViewChild('wrapper') divWrapper: ElementRef;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    if(this.maxWidth > 0){
      this.divWrapper.nativeElement.style.width = this.maxWidth+'px';
    }
  }



  public handleMinus(){

    if(this.val > 0 && this.positive){
      this.decrement()
      this.val = this.normalize(this.val)
      this.emitEvent()
      return;
    }

    if(!this.positive){
      this.decrement()
      this.val = this.normalize(this.val)
      this.emitEvent()
    }
    
  }

  public handlePlus(){
    this.increment()
    this.val = this.normalize(this.val)
    this.emitEvent()
  }

  public onInputChange(e){
    if(e.target.value < 0 && this.positive){
      this.val = 0;
      this.emitEvent()
      return;
    }

    this.val = this.normalize(+e.target.value);
    this.emitEvent()
  }

  public checkNan(e){
    let value = e.target.value;

    if(value >= 0 && this.positive){
      this.val = +value;
      this.emitEvent()
      return;
    }

    this.val = +value;
    this.emitEvent()
  }

  private emitEvent(){
    this.valueChanged.emit(this.normalize(this.val));
  }

  private normalize(inp: number): number{
    let v = inp;

    if(this.positive && v < 0){
      v = 0 ;
    }

    if(this.steps > 1){
      v = Math.round((inp / this.steps)) * this.steps;
    }
    
    return v;
  }

  private increment(){
    this.val += this.steps;
  }

  private decrement(){
    this.val -= this.steps;
    
    if(this.val < 0 && this.positive){
      this.val = 0;
    }
  }
}
