import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-custom-tags',
  templateUrl: './custom-tags.component.html',
  styleUrls: ['./custom-tags.component.scss']
})
export class CustomTagsComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public errorMessage: string = '';
  public loading: boolean = false;
  /* pagination Info */
  maxSelect = 10;
  maxLengthSelect = 50;
  pageSize = 5;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];


  private _parentFormSubmitted = false;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  tagCtrl = new FormControl();
  filteredTags: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  allTags: any[] = [];
  @Input() canDelete: boolean = false;
  @Input() canAdd: boolean = false;
  @Input() required: boolean = false;
  @Input() itemName: string = 'Tags';
  @Input() placeholder: string = 'New Tag...';
  @Input() value: any[] = [];
  @Input() dataType: string = 'tag';
  @Input() dataTypeDisplay: string = 'Tag';
  @Input() dataTypeParent: string = '';
  @Input() dataTypeParentDisplay: string = '';
  @Input()
  set parentFormSubmitted(parentFormSubmitted: boolean){
      this._parentFormSubmitted = parentFormSubmitted;
      if(parentFormSubmitted){
        if(this.form)
          this.form.onSubmit(undefined);
      }
  }
  get parentFormSubmitted(): boolean{
    return this._parentFormSubmitted;
  }
  @Output() onSelectReturn = new EventEmitter<any>();
  @ViewChild('dataForm') form: NgForm;
  constructor(private translate: TranslateService,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService
  ) {
  }

  ngOnInit() {
    this.filterData('');
    this.subscriptions.push(
        this.tagCtrl.valueChanges.subscribe((data) => {
          if(data){
            if(data.hasOwnProperty('_id') ){
              if(!this.getTagItem(data._id)){
                this.filterData(data.name);
              }else{
                this.filterData('');
              }
            }else{
              this.filterData(data);
            }
          }else{
            this.filterData('');
          }
        })
    );
  }
  onSubmit() {
    // do nothing
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  private getSelectedItem(val){
    for(let itm of this.value){
      if(val === itm._id){
        return itm;
      }
    }
    return '';
  }
  private getSelectedItemByName(val){
    for(let itm of this.allTags){
      if(val === itm.name){
        return itm;
      }
    }
    return '';
  }
  private getTagItem(val){
    for(let itm of this.allTags){
      if(val === itm._id){
        return itm;
      }
    }
    return '';
  }
  public setAttribute(val) {
      this.onSelectReturn.emit(val.value);
  }
  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    // console.log('Try pass', event.value);
    if (!this.getSelectedItemByName(event.value)) {
      if(event.value.length <= this.maxLengthSelect){
        // console.log('pass', event.value);
        const input = event.input;
        const value = event.value;
        // Add our tag
        if (this.canAdd && (value || '').trim()) {
          // this.value.push(value.trim());
          this.addData(value.trim());
        }

        // Reset the input value
        if (input) {
          input.value = '';
        }

        // this.tagCtrl.setValue('');
      }else{
        this.layoutUtilsService.showNotification(this.translate.instant('Sorry you can only add a tag of 50 characters'), this.translate.instant('Dismiss'));
      }
    }
  }

  remove(tag: any): void {
    const index = this.value.indexOf(tag);
    if (index >= 0) {
      this.value.splice(index, 1);
      this.onSelectReturn.emit(this.value);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log('selected', event);
    if(this.value.length <= this.maxSelect){
      if(!this.getSelectedItem(event.option.value._id)){
        if(!this.value){
          this.value = [];
        }
        this.value.push({_id: event.option.value._id, name: event.option.value.name});
        this.onSelectReturn.emit(this.value);
      }
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue('');
    }else{
      this.layoutUtilsService.showNotification(this.translate.instant('Sorry you can only select 10 tags'), this.translate.instant('Dismiss'));
    }
  }
  public addData(term) {
    let newTag = { name: term, organizationId: this.requestService.orgId };
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, newTag, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          let returnResult = data.results;
          let dataTag = {_id: returnResult._id, name: returnResult.name};
          this.allTags = [dataTag];
          this.value.push(dataTag);
        }else {
          this.allTags = [];
        }
        this.filteredTags.next(this.allTags);
        this.loading = false;
      });
    }
  }
  public filterData(term) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filters = {'$and': []};
      filters['$and'].push(  { 'organizationId': { '$eq': this.requestService.orgId } });
      this.requestService.getDataList(this.dataType, {page: this.pageNumber , term: term, perpage: this.pageSize, orderbydir: this.orderDir, orderby: this.orderBy, fields: ['name'], filter: filters}, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.allTags = data.results;
        }else {
          this.allTags = [];
        }
        this.filteredTags.next(this.allTags);
        this.loading = false;
      });
    }
  }
  public checkDeleteItem(e, val) {
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filters = {'$and': []};
      filters['$and'].push({"tags._id": {"$in": [val._id]}});
      this.requestService.getDataListSummary(this.dataTypeParent, {page: this.pageNumber, perpage: 1, orderbydir: this.orderDir, orderby: this.orderBy, filter: filters}, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
          this.loading = false;
          return;
        }
        this.loading = false;
        if (data) {
          if(data.results.length > 0){
            this.deleteItem(val, true);
          }else{
            this.deleteItem(val);
          }
        }else {
          this.deleteItem(val);
        }
      });
    }
  }
  public deleteItem(val, isUsed = false) {
    if (!this.loading) {
  		const _title: string = 'Delete ' + this.dataTypeDisplay;
  		let _description: string = 'Are you sure you want to permanently delete this ' + this.dataTypeDisplay + ' "' + val.name + '"?';
      if(isUsed){
        _description = _description + ' This ' + this.dataTypeDisplay + ' is being used by other ' + this.dataTypeParentDisplay;
      }
  		const _waitDesciption: string = 'Deleting...';

  		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  		dialogRef.afterClosed().subscribe(res => {
  			if (!res) {
  				return;
  			}
        this.realDelete(val._id);
  		});
    }
	}
  public realDelete(id: any) {
    const _deleteMessage = this.dataTypeDisplay + ` Deleted Successfully.`;
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
    			this.layoutUtilsService.showNotification(_deleteMessage, 'Dismiss');
          this.clearFromSelection(id);
          this.filterData('');
        }
      });
    }
  }
  public clearFromSelection(_id){
    let newValue = JSON.parse(JSON.stringify(this.value));
    let idx = 0;
    for(let vl of this.value){
      if(vl._id === _id){
        newValue.splice(idx, 1);
        this.onSelectReturn.emit(newValue);
        break;
      }
      idx++;
    }
  }
}
