<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <span *ngIf="loading">{{ 'Loading' | translate }}...</span>
      <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
          <div fxFlex>
            <!-- <mat-card class="example-card" >
              <mat-card-header class="topBtns">
                <mat-card-title >{{dataTypeDisplay}}: {{selectedData.title}}
                  <button *ngIf="!isDialog" mat-raised-button (click)="toggleClick('delete')" [disabled]="!selectedData.hasOwnProperty('_id')" color="info">Delete</button>
                  <button *ngIf="!isDialog" mat-raised-button (click)="toggleClick('preview')" color="info" [disabled]="!selectedData.hasOwnProperty('_id')">Preview</button>
                  <button *ngIf="!isDialog" mat-raised-button (click)="toggleClick('duplicate')" color="info">Duplicate</button>
                  <button *ngIf="!isDialog" mat-raised-button (click)="toggleClick('save')" color="info">Save</button>
                  <button *ngIf="!isDialog" mat-raised-button (click)="toggleClick('new')" color="info">New</button>
                </mat-card-title>
              </mat-card-header>
              <mat-divider *ngIf="!isDialog"></mat-divider>
              <mat-card-content> -->
                <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
                    <div fxFlex="25">
                      <mat-form-field>
                        <input matInput name="title" [(ngModel)]="selectedData.title" [required]="true" placeholder="{{ 'Title' | translate }}" [errorStateMatcher]="esMatcher">
                        <mat-error *ngIf="selectedData.title === undefined || selectedData.title === ''">
                          {{ 'Title' | translate }} is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxFlex="25">
                      <app-custom-select-create-autocomplete [value]="selectedData.category" [dataType]="'category'" [dataTypeDisplay]="'Category'" [placeholder]="'Select / Add Category'"  [useOrg]="true" [canDelete]="false" (onSelectReturn)="selectedData.category = $event._id" [type]="'tile'" [onBlur]="true"></app-custom-select-create-autocomplete>
                    </div>
                    <div fxFlex="50" class="topBtns">
                      <button *ngIf="!isDialog" color="secondary" mat-raised-button (click)="toggleClick('designer')" >{{ 'Designer' | translate }}</button>
                      <button *ngIf="!isDialog" color="danger" mat-raised-button (click)="toggleClick('delete')" [disabled]="!selectedData.hasOwnProperty('_id')">{{ 'Delete' | translate }}</button>
                      <button *ngIf="!isDialog" color="primary" mat-raised-button (click)="toggleClick('preview')" [disabled]="!selectedData.hasOwnProperty('_id')">{{ 'Preview' | translate }}</button>
                      <button *ngIf="!isDialog" color="primary" mat-raised-button (click)="toggleClick('duplicate')">{{ 'Duplicate' | translate }}</button>
                      <button *ngIf="!isDialog" color="primary" mat-raised-button (click)="toggleClick('save')">{{ 'Save' | translate }}</button>
                      <button *ngIf="!isDialog" color="primary" mat-raised-button (click)="toggleClick('new')">{{ 'New' | translate }}</button>
                    </div>
                    <!-- <div fxFlex="25">
                      <mat-form-field *ngIf="selectedLanguage">
                        <input [style.display]="'none'" matInput name="language" [(ngModel)]="selectedLanguage" [required]="true">
                        <mat-label>Language</mat-label>
                        <mat-select ng-model="language" [required]="true" (selectionChange)="languageChange($event.value)" [value]="selectedLanguage" [disabled]="true">
                          <mat-option *ngFor="let itm of languageList" [value]="itm.code" >{{itm.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div> -->
                </div>
              <!-- </mat-card-content>
            </mat-card> -->
          </div>
      </div>
      <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
          <div fxFlex="15">
            <mat-card class="example-card widgetList">
              <mat-card-header>
                <mat-card-title>{{ 'Widgets' | translate }}</mat-card-title>
              </mat-card-header>
              <mat-divider></mat-divider>
              <mat-card-content>
                <div class='widget-basic-collapse'>
                  <div id='widgetEditor' block='Text' data-placement='top' title="{{'Add any description in multiple paragraphs.' | translate}}"
                    class='widget_btn' (click)='loadWidgets($event, "text", {}, "Editor")'>
                    <!-- <div>
                      <img src='/assets/img/text_32.png' />
                    </div> -->
                    <div>
                      <label>{{ 'Editor' | translate }}</label>
                    </div>
                  </div>
                  <div id='widgetQuestionnaire' block='questionnaire' data-toggle='tooltip' data-toggle='tooltip'
                    title="{{'Multiple questions where users can select only One or Multiple answers.  Percentages can be shown in-app.' | translate}}"
                    class='widget_btn' (click)='loadWidgets($event, "questionnaire", {}, "Questionnaire")'>
                    <!-- <div>
                      <img
                        src='/assets/img/survey.png' />
                    </div> -->
                    <div>
                      <label>{{ 'Questionnaire' | translate }}</label>
                    </div>
                  </div>
                  <div id='widgetLinkdescription' block='linkDescription' data-toggle='tooltip' title="{{ 'Link with Description' | translate }}"
                    class='widget_btn' (click)='loadWidgets($event, "linkdescription", {}, "Link with Description")'>
                    <div>
                      <label>{{ 'Link with Description' | translate }}</label>
                    </div>
                  </div>
                  <div id='widgetInquiry' block='inquiry' data-toggle='tooltip'
                    title='Add Inquiry at the bottom of a Tile to allow users to send inquiries about the subject discussed in that Tile. Inquiries are directed to the email entered in the Tile.'
                    class='widget_btn' (click)='loadWidgets($event, "inquiry", {}, "Inquiry")'>
                    <!-- <div>
                      <img  src='/assets/img/inquery.png' />
                    </div> -->
                    <div>
                      <label>{{ 'Inquiry' | translate }}</label>
                    </div>
                  </div>
                </div>
              </mat-card-content>
              <mat-card-actions>
                <!-- <button mat-raised-button>LIKE</button>
                <button mat-raised-button>SHARE</button> -->
              </mat-card-actions>
            </mat-card>
          </div>
          <div fxFlex="85">
            <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
              <div fxFlex="100" *ngIf="isDialog">
                <mat-card class="tileData">
                  <mat-card-header>
                    <mat-card-title>{{UIDisplay | translate}} {{'Data' | translate}} </mat-card-title>
                  </mat-card-header>
                  <mat-divider></mat-divider>
                  <mat-card-content>
                    <div *ngFor="let subItem of blocks;let index = index">
                      <mat-card class="example-card" *ngIf="subItem" >
                        <mat-card-header>
                          <!-- <div mat-card-avatar class="example-header-image"></div> -->
                          <mat-card-title>{{subItem.blockName | translate}}</mat-card-title>
                          <div fxFlex></div>
                          <div fxLayoutAlign="center center">
                            <button [disabled]="index === 0" mat-icon-button (click)="moveSubData(true, index)">
                               <mat-icon>keyboard_arrow_up</mat-icon>
                            </button>
                            <button [disabled]="index === blocks.length-1" mat-icon-button (click)="moveSubData(false, index)">
                               <mat-icon>keyboard_arrow_down</mat-icon>
                            </button>
                            <button mat-icon-button (click)="removeWidget(index)">
                               <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button (click)="deleteWidget(index)" *ngIf="subItem.activate">
                               <mat-icon>close</mat-icon>
                            </button>
                            <button mat-icon-button (click)="unDeleteWidget(index)" *ngIf="!subItem.activate">
                               <mat-icon>undo</mat-icon>
                            </button>
                          </div>
                        </mat-card-header>
                        <mat-divider></mat-divider>
                        <mat-card-content [hidden]="!subItem.activate">
                          <app-widget [title]="subItem.blockName" [type]="subItem.type" [data]="subItem" (onActionData)="actionData(index, $event)"></app-widget>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div fxFlex="75" *ngIf="!isDialog">
                <mat-card class="tileData">
                  <mat-card-header>
                    <mat-card-title>{{UIDisplay | translate}} {{'Data' | translate}} </mat-card-title>
                  </mat-card-header>
                  <mat-divider></mat-divider>
                  <mat-card-content>
                    <div *ngFor="let subItem of blocks;let index = index">
                      <mat-card class="example-card" *ngIf="subItem" >
                        <mat-card-header>
                          <!-- <div mat-card-avatar class="example-header-image"></div> -->
                          <mat-card-title>{{subItem.blockName | translate}}</mat-card-title>
                          <div fxFlex></div>
                          <div fxLayoutAlign="center center">
                            <button [disabled]="index === 0" mat-icon-button (click)="moveSubData(true, index)">
                               <mat-icon>keyboard_arrow_up</mat-icon>
                            </button>
                            <button [disabled]="index === blocks.length-1" mat-icon-button (click)="moveSubData(false, index)">
                               <mat-icon>keyboard_arrow_down</mat-icon>
                            </button>
                            <button mat-icon-button (click)="removeWidget(index)">
                               <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button (click)="deleteWidget(index)" *ngIf="subItem.activate">
                               <mat-icon>visibility</mat-icon>
                            </button>
                            <button mat-icon-button (click)="unDeleteWidget(index)" *ngIf="!subItem.activate">
                               <mat-icon>visibility_off</mat-icon>
                            </button>
                          </div>
                        </mat-card-header>
                        <mat-divider></mat-divider>
                        <mat-card-content [hidden]="!subItem.activate">
                          <app-widget [title]="subItem.blockName" [type]="subItem.type" [data]="subItem" (onActionData)="actionData(index, $event)"></app-widget>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div fxFlex="25" *ngIf="!isDialog">
                <app-tiles-list #tileList [selectedData]="selectedData" (tileAction)="tileActionReturn($event)" (selectItem)="selectItemCheck($event)" [hasCategories]="true" [canDelete]="true"></app-tiles-list>
              </div>
            </div>
          </div>
      </div>
    </div>
</div>
