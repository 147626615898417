<div [ngClass]="className">
	<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
	<div mat-dialog-content [style.height]="iframeHeight">
		<div class="fieldItem">
			<span *ngIf="loading">{{'Loading...' | translate}}</span>
			<iframe id="iframeMain" #iframeMain width="100%" frameBorder="0" [src]="iframe_html" (load)="onload($event)"
				[height]="iframeHeight"></iframe>
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
	</div>
</div>
