<!-- <div>
    <div>
        <div style="display: block">
            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
            </canvas>
        </div>
        <button mat-button mat-raised-button color="primary" (click)="randomize()">Update</button>
    </div>
</div> -->
<div class="chart">
    <!-- <apx-chart [series]="series" [chart]="chart" [dataLabels]="dataLabels" [markers]="markers" [title]="title"
        [fill]="fill" [yaxis]="yaxis" [xaxis]="xaxis" [tooltip]="tooltip" [autoUpdateSeries]="true"></apx-chart> -->

    <apx-chart *ngIf="chartOptions" [series]="chartOptions.series" [chart]="chartOptions.chart"
        [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions" [yaxis]="chartOptions.yaxis"
        [xaxis]="chartOptions.xaxis" [fill]="chartOptions.fill" [title]="chartOptions.title"
        [tooltip]="chartOptions.tooltip"></apx-chart>
</div>