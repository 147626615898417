import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    ApexAxisChartSeries,
    ApexChart,
    ChartComponent,
    ApexDataLabels,
    ApexPlotOptions,
    ApexYAxis,
    ApexTitleSubtitle,
    ApexXAxis,
    ApexTooltip,
    ApexFill, ApexTheme, ApexLegend
} from "ng-apexcharts";
import { Subscription } from 'rxjs';
import { ChartService } from '../../services/chart.service';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    fill: ApexFill;
    title: ApexTitleSubtitle;
    tooltip: ApexTooltip;
    theme: ApexTheme;
    legend: ApexLegend;
};

@Component({
    selector: 'app-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss'],
})

export class BarChartComponent implements OnInit {

    // @Input()
    // set initChart(initChart: boolean) {
    //     if (initChart) {
    //         this.drawChart();
    //     }
    // }
    @Input() chartTitle: string = undefined;
    // @Input()
    // set selectedItem(selectedItem: string) {
    //     this._selectedItem = selectedItem;
    //     // this.drawHorizontalChart();
    // }
    // get selectedItem() {
    //     return this._selectedItem;
    // }
    @Input() labels: any = undefined;
    @Input()
    set values(values: any) {
        if (values && values.length) {
            this._values = values;
            this.drawChart();
        }
    }
    get values() {
        return this._values;
    }
    @Input() isHorizontal: boolean = false;

    @Output() selectedBar = new EventEmitter<any>();

    @ViewChild("chart") chart: ChartComponent;

    public chartOptions: Partial<ChartOptions>;
    // private subscriptions: Subscription[] = [];
    // private pointsOnChart: any = {};
    // private _selectedItem: any;
    private _values: any = undefined;

    constructor() { }

    ngOnInit() {
        // this.subscriptions.push(this.chartService.plotThisPoint.subscribe((value) => {
        //     console.log('this.dataList', this.dataList)
        //     if (value) {
        //         let countryName = value.location.country.long_name;
        //         if (value.joined) {
        //             if (this.dataList.hasOwnProperty(countryName)) {
        //                 this.dataList[countryName].population += 1;
        //             }
        //             else {
        //                 this.dataList[countryName] = { population: 1 };
        //             }
        //         }
        //         else {
        //             if (this.dataList.hasOwnProperty(countryName)) {
        //                 this.dataList[countryName].population -= 1;
        //             }
        //             if (this.dataList.hasOwnProperty(countryName) && this.dataList[countryName].population <= 0) {
        //                 delete this.dataList[countryName];
        //             }
        //         }
        //         if (!this.selectedItem)
        //             this.drawChart();
        //     }

        //     if (value && value.location.long_name) {
        //         if (value.joined) {
        //             if (this.pointsOnChart.hasOwnProperty(value.location.long_name)) {
        //                 this.pointsOnChart[value.location.long_name].population += 1;
        //             }
        //             else {
        //                 this.pointsOnChart[value.location.long_name] = { population: 1, country: value.location.country.long_name };
        //             }
        //         }
        //         else {
        //             if (this.pointsOnChart.hasOwnProperty(value.location.long_name)) {
        //                 this.pointsOnChart[value.location.long_name].population -= 1;
        //             }
        //             if (this.pointsOnChart.hasOwnProperty(value.location.long_name) && this.pointsOnChart[value.location.long_name].population <= 0) {
        //                 delete this.pointsOnChart[value.location.long_name];
        //             }
        //         }
        //         if (this.selectedItem)
        //             this.drawHorizontalChart();
        //     }
        // }));
    }

    ngOnDestroy() {
        // this.subscriptions.forEach(el => el.unsubscribe());
    }

    drawChart() {
        let obj = this;
        // debugger;
        this.chartOptions = {
            series: [
                {
                    name: "#",
                    data: this.values
                }
            ],
            chart: {
                width: 500,
                height: 350,
                type: 'bar',
                foreColor: '#151a3f',
                toolbar: {
                    show: true
                },
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        console.log(chartContext, config);
                        obj.selectedBar.emit(this.labels[config.dataPointIndex]);
                    }
                }
            },
            plotOptions: {
                bar: {
                    // endingShape: "flat",
                    dataLabels: {
                        position: "center" // top, center, bottom
                    },
                    horizontal: this.isHorizontal,
                    distributed: true,
                    columnWidth: '20%'
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (val) => {
                    return val.toString();
                },
                // offsetY: -20,
                style: {
                    fontSize: "12px",
                    colors: ["#304758"]
                }
            },

            xaxis: {
                categories: this.labels,
                position: "top",
                // labels: {
                //     offsetY: -18
                // },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: "gradient",
                        gradient: {
                            colorFrom: "#D8E3F0",
                            colorTo: "#BED1E6",
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    offsetY: -35
                },
                labels: {
                    style: {
                        colors: '#151a3f'
                    }
                }
            },

            // fill: {
            //     type: "gradient",
            //     gradient: {
            //         shade: "light",
            //         type: "horizontal",
            //         shadeIntensity: 0.15,
            //         gradientToColors: undefined,
            //         inverseColors: true,
            //         opacityFrom: 1,
            //         opacityTo: 1,
            //         stops: [50, 0, 100, 100]
            //     }
            // },
            yaxis: {
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: true,
                    formatter: (val) => {
                        return val.toString();
                    },
                    style: {
                        colors: '#151a3f'
                    }
                }
            },
            title: {
                text: this.chartTitle,
                floating: false,
                // offsetY: 320,
                align: "center",
                style: {
                    color: "#151a3f"
                }
            },
            theme: {
                palette: 'palette1'
            }
        };
    }

    // drawHorizontalChart() {
    //     if (Object.keys(this.pointsOnChart).length) {
    //         let values = [];
    //         let labels = Object.keys(this.pointsOnChart);

    //         labels.forEach(key => {
    //             if (this.pointsOnChart[key].country == this.selectedItem) {
    //                 values.push(this.pointsOnChart[key].population);
    //             }
    //         });

    //         // let labels = Object.keys(this.dataList[this.selectedItem]);
    //         // labels.forEach(key => {
    //         //     values.push(this.dataList[key].population);
    //         // });
    //         // console.log(labels, values)

    //         this.chartOptions = {
    //             series: [
    //                 {
    //                     name: "#",
    //                     data: values
    //                 }
    //             ],
    //             chart: {
    //                 type: "bar",
    //                 height: 350
    //             },
    //             plotOptions: {
    //                 bar: {
    //                     horizontal: true
    //                 }
    //             },
    //             dataLabels: {
    //                 enabled: false
    //             },
    //             xaxis: {
    //                 categories: labels
    //             }
    //         };
    //     }
    // }
}