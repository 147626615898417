<div class="row cc-options-sub-row">
  <div class="cc-options-sub-questionaire">
    <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
        <div fxFlex>
          <mat-form-field class="example-full-width" >
            <span matPrefix>{{parentIndex + 1}}.{{getAlphaLetter(currentIndex)}}</span>
            <input  matInput [(ngModel)]="subOption.questionText" placeholder="{{'Type question or text here' | translate}}" (change)="onChange($event)">
            <button mat-icon-button matSuffix (click)="addOption($event)" [attr.aria-label]="'Add Option'" >
              <mat-icon>playlist_add</mat-icon>
            </button>
            <button mat-icon-button matSuffix (click)="deleteSubLevel($event)" [attr.aria-label]="'Remove option'" >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
    </div>
    <div class="decription-survey">{{'User can select' | translate}}</div>
    <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
        <div fxFlex>
          <mat-radio-group aria-label="Select an option" [(ngModel)]="subOption.questionType" (change)="controlChange($event.value)">
            <mat-radio-button value="single">{{'One Answer' | translate}}</mat-radio-button>
            <mat-radio-button value="multiple">{{'Multiple Answers' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxFlex *ngIf="subOption.questionType === 'single'">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="subOption.inputControlType" (change)="controlChange($event.value)">
              <mat-radio-button value="radio">{{'Radio Button' | translate}}</mat-radio-button>
              <mat-radio-button value="dropdown">{{'Dropdown' | translate}}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="cc-options-cc-options-sub-row">
      <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngFor="let subMain of subOption.options; let opI = index; trackBy:trackByIndex">
          <div fxFlex>
            <mat-form-field class="example-full-width">
              <span matPrefix>{{getLevelIndex(opI)}}.</span>
              <input matInput [(ngModel)]="subOption.options[opI]!.option" placeholder="{{'Type option here' | translate}}" (change)="onChange($event)">
              <button mat-icon-button matSuffix [class.containsData]="subOption.options[opI]!.confirmation && subOption.options[opI]!.confirmation != ''" (click)="editConfirm($event, opI)" [attr.aria-label]="'Add Confirmation'" title="{{'Add Confirmation' | translate}}">
                <mat-icon>check_circle</mat-icon>
              </button>
              <button mat-icon-button matSuffix [class.containsData]="subOption.options[opI]!.popup && subOption.options[opI]!.popup != ''" (click)="editPopup($event, opI)" [attr.aria-label]="'Pop Up'" title="Pop Up">
                <mat-icon>web_asset</mat-icon>
              </button>
              <button *ngIf="levelIndex < 2" mat-icon-button matSuffix (click)="addSubOption($event, opI, 'questions')" [attr.aria-label]="'Add Question'" title="{{'Add Question' | translate}}">
                <img title="{{'Add Question' | translate}}" class="cc-delete-quest-option" src="/assets/img/add_sub_questionnaire.png">
              </button>
              <button *ngIf="levelIndex < 2" mat-icon-button matSuffix (click)="addSubOption($event, opI, 'description')" [attr.aria-label]="'Add Textbox'" title="{{'Add Question' | translate}}">
                <img title="{{'Add Textbox' | translate}}" class="cc-delete-quest-option" src="/assets/img/add_sub_entry.png">
              </button>
              <button mat-icon-button matSuffix (click)="removeOption($event, opI)" [attr.aria-label]="'Remove option'"  title="{{'Remove option' | translate}}">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngFor="let subOpt of subOption.options[opI]?.subQuestions; let subIndex = index; trackBy:trackByIndex">
                <div fxFlex>
                  <div >
                    <questionnaire-sub-option *ngIf="!utils.isEmptyObject(subOpt) && subOpt.hasOwnProperty('type') && subOpt['type'] === 'questions'" [questionWidth]="461" [optionWidth]="460" [isLevel]="false" (removeSubLevel)="deleteLevel($event)" (changeSubLevel)="updateLevel($event)"  [levelIndex]="2" [currentIndex]="subIndex" [parentIndex]="opI" [subOption]="subOpt">
                    </questionnaire-sub-option>
                    <description-sub-option *ngIf="!utils.isEmptyObject(subOpt) && subOpt.hasOwnProperty('type') && subOpt['type'] === 'description'" [selectWidth]="393" [descriptionWidth]="461" (removeSubLevel)="deleteLevel($event)" (changeSubLevel)="updateLevel($event)"  [levelIndex]="2" [currentIndex]="subIndex" [parentIndex]="opI" [subOption]="subOpt">
                    </description-sub-option>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
