<div class='ili-panel start_wrapper_panel'>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        <mat-form-field>
          <input matInput placeholder="Title" [(ngModel)]="block.data.title" type="text" (change)="onChange($event)">
        </mat-form-field>
      </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        <mat-checkbox value="true" [checked]="block.data.refresh" [(ngModel)]="block.data.refresh" (change)="onChange($event)" title="Check this box if this form should be cleared every time the end-user enters this tile.">Clear Fields after each submit</mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox value="true" [checked]="block.data.close" [(ngModel)]="block.data.close" (change)="onChange($event)" title="Check this box if this form should be cleared every time the end-user enters this tile and it will close the form.">Clear and Close the Form</mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox value="true" [checked]="block.data.formCollapse" [(ngModel)]="block.data.formCollapse" (change)="onChange($event)">Collapse</mat-checkbox>
      </div>
  </div>
</div>
