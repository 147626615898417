<div class="ili-panel attendance_panel">
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        <mat-form-field class="example-full-width">
          <input matInput [(ngModel)]="block.data.title" placeholder="Today i will be Attending" (change)="onChange($event)">
        </mat-form-field>
      </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        In app possible replies:
      </div>
      <div fxFlex>
        <button mat-raised-button  mat-raised-button color="primary" (click)="addOption($event)" class="btn-info btn-xs btn-option-box">+ Add Option</button>
      </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        <mat-checkbox value="true" [checked]="block.data.person" [(ngModel)]="block.data.person" (change)="onChange($event)">In-person</mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox value="true" [checked]="block.data.online" [(ngModel)]="block.data.online" (change)="onChange($event)">Online</mat-checkbox>
      </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        <mat-form-field class="example-full-width" *ngFor="let opt of block.data.options; let i = index;trackBy:trackByIndex">
          <input matInput [(ngModel)]="block.data.options[i]" placeholder="{{'Type option here' | translate}}" (change)="onChange($event)">
          <button mat-icon-button matSuffix (click)="removeOption($event, i)" [attr.aria-label]="'Remove option'" >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        <mat-form-field class="inputInside">
          <mat-checkbox value="true" [checked]="block.data.addMember" [(ngModel)]="block.data.addMember" (change)="onChange($event)">
            <input matInput type="text" [(ngModel)]="block.data.addQuestion" class="form-control" (change)="onChange($event)">
          </mat-checkbox>
        </mat-form-field>
      </div>
  </div>
</div>
