<div class="col-xl-12 pictureDialog" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
      <mat-card >
				<div class="example-full-width">
					<mat-form-field type="text" style="width: 100%;">
							<input matInput placeholder="{{ 'Title' | translate }}" [(ngModel)]="returnData['title']">
					</mat-form-field>
				</div>
				<mat-form-field class="example-full-width">
					<mat-label>Link To</mat-label>
					<mat-select (selectionChange)="setPictureArrayType($event.value)" [value]="returnData['type']">
						<mat-option value="link" >{{'Url' | translate}}</mat-option>
						<mat-option value="page" >{{'Page' | translate}}</mat-option>
						<mat-option value="room" >{{'Room' | translate}}</mat-option>
						<mat-option value="tile" >{{'Tile' | translate}}</mat-option>
					</mat-select>
				</mat-form-field>
				<div class="example-full-width" *ngIf="returnData['type'] !== 'none'">
					<mat-form-field type="url" style="width: 100%;" *ngIf="returnData['type'] === 'link'">
							<input matInput placeholder="{{ 'Link' | translate }}*" [(ngModel)]="returnData['link']">
					</mat-form-field>
					<mat-form-field type="text" style="width: 100%;" *ngIf="returnData['type'] === 'page'">
							<input matInput placeholder="{{ 'Link' | translate }}*" [(ngModel)]="returnData['link']">
					</mat-form-field>
					<mat-form-field type="text" style="width: 100%;" *ngIf="returnData['type'] === 'room' || returnData['type'] === 'tile'">
							<input matInput [readonly]="true" [(ngModel)]="returnData['linkDisplay']" placeholder="{{ 'Select' | translate }} {{returnData['type']}}*">
							<mat-icon matSuffix  style="cursor:pointer" (click)="selectPictureLinkType($event, returnData['type'])">mode_edit</mat-icon>
					</mat-form-field>
					<!-- <mat-form-field class="example-full-width" *ngIf="returnData['type'] === 'page'">
						<mat-label>Page</mat-label>
						<mat-select (selectionChange)="setPictureArrayType($event.value)" [value]="returnData['link']">
							<mat-option value="link" >{{'Url' | translate}}</mat-option>
							<mat-option value="tile" >{{'Tile' | translate}}</mat-option>
							<mat-option value="room" >{{'Room' | translate}}</mat-option>
							<mat-option value="page" >{{'Page' | translate}}</mat-option>
						</mat-select>
					</mat-form-field> -->
				</div>
				<!-- <mat-form-field class="example-full-width" *ngIf="returnData['type'] !== 'none'">
					<input name="link" matInput
					 [readonly]="returnData['type'] !== 'link' && returnData['type'] !== 'page'" [(ngModel)]="returnData['link']">
					<mat-icon matSuffix *ngIf="returnData['type'] !== 'link' && returnData['type'] !== 'page'" style="cursor:pointer" (click)="selectPictureLinkType($event, returnData['type'])">mode_edit</mat-icon>
				</mat-form-field> -->
				<ng-container *ngIf="hasShape">
					<mat-form-field class="example-full-width">
						<mat-label>Shape</mat-label>
						<mat-select (selectionChange)="returnData['shape'] = $event.value" [value]="returnData['shape']">
							<mat-option value="square" >{{'Rectangle' | translate}}</mat-option>
							<mat-option value="circle" >{{'Oval' | translate}}</mat-option>
							<mat-option value="poly" >{{'Poly' | translate}}</mat-option>
						</mat-select>
					</mat-form-field>
				</ng-container>
      </mat-card>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button mat-raised-button (click)="closeModal(returnData)">{{'Confirm' | translate}}</button>
	</div>
	<br/>
</div>
