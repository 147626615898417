import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Moment } from 'moment';
import * as moment from 'moment';

class My2ErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
    selector: 'app-custom-datetime',
    templateUrl: './custom-datetime.component.html',
    styleUrls: ['./custom-datetime.component.scss']
})
export class CustomDateTimeComponent implements OnInit {
    public esMatcher = new My2ErrorStateMatcher();
    public dateValue: string = moment(new Date().toISOString()).format('YYYY-MM-DD');
    public timeValue: string = moment(new Date().toISOString()).format('HH:mm');
    @Input() metadata: any = undefined;
    public _value: string = '';
    @Input()
    set value(value: string) {
      this._value = value;
      if(value){
        let momentValue = moment(value);
        this.dateValue = momentValue.format('YYYY-MM-DD');
        this.timeValue = momentValue.format('HH:mm');
      }
    }
    get value(): string {
      return this._value;
    }

    @Output() dateChange = new EventEmitter<string>();
    @ViewChild('dataForm') form: NgForm;
    constructor() {
      // if(this.form)
      //  this.form.onSubmit(undefined);
    }
    ngOnInit() {
    }
    setupData(){

    }
    public setDateAttribute(val) {
      this.dateChange.emit(moment(val + 'T' + this.timeValue).format('YYYY-MM-DDTHH:mm'));
    }
    public setTimeAttribute(val) {
      this.dateChange.emit(moment( this.dateValue + 'T' + val).format('YYYY-MM-DDTHH:mm'));
    }
    // this.returnMarginValue.emit(this.marginValue);
    public onSubmit() {
      // do nothing
    }
}
